import * as React from "react";
import { useEffect, useState, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FiRefreshCw } from "react-icons/fi";
import axios from "axios";
import SweetAlertHelper from "./SweetAlertHelper";
import { ApiHelper } from "../_helpers/api.helper";
import { environment } from "src/environments/environment";
import { openaiEnv } from "./chatbot.env";
import { faMicrophone, faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons';
import { faMessage } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
  faClipboard,
} from "@fortawesome/free-solid-svg-icons";
import { useSpeechSynthesis } from "react-speech-kit";
import { faVolumeUp, faPause } from "@fortawesome/free-solid-svg-icons";
import "audio-recorder-polyfill";
import { format } from "date-fns";
import { ConstantHelper } from "../_helpers/constant.helper";
// import DatePicker from 'react-datepicker';

let demo = "";
let file;
let specialistName = "";
let sub_specialistName = "";
let subSpecialistNames = ([] = []);
let practice_name = "";
let physician_name = "";
let FilejsonObject;
let AppointmentjsonObject;
let Emailjson;
let qcount;
let Filejson;
let Filejson1;
let Appointmentjson;
let ScheduleAppointmentjsonObject;
let scheduledAppointmentjson;
let registeredPhysiciansData;
let checkboxtable;
let limit;
let fileUrl;
let UploadFilejson1;
let fileJsonObject;
let parsedObject;
let fileNames;
let physicianData;
let thread_id = 0;
let html_table;

interface Message {
  text: string;
  user: boolean;
}

declare global {
  interface Window {
    printDocumentUrl?: (url) => void;
    demoTest?: (url) => void;
  }
}

// let DOCjsonObject;
const AHSChatBot = () => {
  let empName = localStorage.getItem("loggedInUserName");
  let loggedInUserEmail = localStorage.getItem("loggedInUserEmail");
  let loggedInUserMobile = localStorage.getItem("loggedInUserMobile");
  const [response, setResponse] = useState("");
  const [assistant, SetAssistant] = useState("");
  const [textBeforeButtons, SettextBeforeButtons] = useState("");
  const [buttonNames, SetbuttonNames] = useState<string[]>([]);
  const [buttonClicked, setButtonClicked] = useState<string | null>(null);
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [SpecialistList, setSpecialistList] = useState(null);
  const [PhysicianDetailsList, setPhysicianDetailsList] = useState(null);
  const [subSpecialistList, setSubSetSpecialistList] = useState(null);
  const [coeList, setcoeList] = useState(null);
  const [documentLists, setdocumentLists] = useState(null);
  const [HippadocumentLists, setHippadocumentLists] = useState(null);
  const [physicianList, setPhysicianList] = useState(null);
  const [registeredPhysicianList, setregisteredPhysicianList] = useState(null);
  const [registeredPhysicianNames, setregisteredPhysicianNames] =
    useState(null);
  const [dropdownNames, SetDropdownNames] = useState<string[]>([]);
  const [dropdownClicked, setDropdownClicked] = useState(null);
  const [helpdocument, setHelpDocument] = useState("");
  const [indexPage, setIndexPage] = useState("");
  const [isfaqbutton, Setisfaqbutton] = useState(false);
  const [isappobutton, Setisappobutton] = useState(false);
  const [isexpobutton, Setisexpobutton] = useState(false);
  const [isDirectexpobutton, SetisDirectexpobutton] = useState(false);  
  const [isGeneralSearch, setIsGeneralSearch] = useState(true);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [filename, setFilename] = useState<string | null>(null);
  const [showFileUpload, SetShowFileUpload] = useState(false);
  const [showDateTimePicker, setShowDateTimePicker] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState<string | null>(null);
  const loggedInUser = sessionStorage.getItem("currentUser");
  const [pageName, setPageName] = useState("");
  const [patientDocuments, setpatientDocuments] = useState("");
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const lastMessageRef = useRef<HTMLDivElement | null>(null);
  const [documentURLRes, setDocumentURLRes] = useState(null);
  const [clickedButton, setClickedButton] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [count, setCount] = useState(0);
  const [isotherQue, SetisOtherQue] = useState(false);
  const [isvideoQue, SetisVideoQue] = useState(false);
  const [pdfUrl, SetPdfUrl] = useState<string[]>([]);
  const [imageUrl, SetImageUrl] = useState<string[]>([]);
  const [videoUrl, setVideoUrl] = useState<string[]>([]);
  const [uploadedUrl, setUploadedUrl] = useState([]);
  const [messagesa, setmessagesa] = useState<Message[]>([
    {
      text: `Hi ${empName}, How can I help you today?`,
      user: false,
    },
  ]);

  const { speak, cancel, voices } = useSpeechSynthesis();
  const [isSpeechPaused, setIsSpeechPaused] = useState(false);
  const [threads, setThreads] = useState([]);
  const [last7DaysThreads, setLast7DaysThread] = useState([]);
  const [threadApiCalled, setThreadApiCalled] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [activeclicked, setActiveClicked] = useState(false);
  const [threadButtonClicked, setThreadButtonClicked] = useState(false);
  const [activeThread, setActiveThread] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [olderThread, setOlderThread] = useState([]);
  const [todayThread, setTodayThread] = useState([]);
  const [dateSet, setDateSet] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [audioChunks, setAudioChunks] = useState([]);
  const [copiedIndex, setCopiedIndex] = useState(null);
  const [volumeStates, setVolumeStates] = useState({});
  const currentDateTime = format(new Date(), "yyyyMMdd_HHmmss");
  const fileName = `${empName}_${currentDateTime}.mp3`;
  const [isMicrophoneOn, setIsMicrophoneOn] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [audioIndex, setAudioIndex] = useState(null); // Tracks which message is currently controlled
  const [speaking, setSpeaking] = useState(false);
  const [htmlFormat, setHtmlFormat] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //speech to text conversion
  const startSpeechRecognition = () => {
    const recognition = new (window as any).webkitSpeechRecognition();

    recognition.continuous = true;

    recognition.onstart = () => {
      
      setIsMicrophoneOn(true);
    };

    recognition.onresult = (event) => {
      const result = event.results[event.results.length - 1][0].transcript;
      setTranscript(result);
      setResponse((prevResponse) => prevResponse + ' ' + result);

    };

    recognition.onend = () => {
      
      setIsMicrophoneOn(false);
    };
    setTimeout(() => {
      recognition.stop();
    }, 20000);

    recognition.start();
    
  };

  // New state to track if date is sett
  const [text, setText] = useState("");

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const hasRendered = useRef(false);

  const handleVolumeIconClick = (text, index) => () => {
    window.speechSynthesis.cancel();
    if (audioIndex === index && speaking) {
      if (window.speechSynthesis.speaking) {
        if (window.speechSynthesis.paused) {
          window.speechSynthesis.resume();
          setIsPaused(false);
        } else {
          window.speechSynthesis.pause();
          setIsPaused(true);
        }
      }
    } else {
      if (audioIndex === index && window.speechSynthesis.paused) {
        // Resume from the paused position
        window.speechSynthesis.resume();
        setIsPaused(false);
      } else {
        // Stop any current speech
        window.speechSynthesis.cancel();
        // Start new speech
        const utterance = new SpeechSynthesisUtterance(text);
        utterance.voice = voices[4]
        utterance.onend = () => {
          setSpeaking(false);
          setAudioIndex(null);
          setIsPaused(false);
        };
        utterance.onerror = (error) => {
          console.error('Speech synthesis error:', error);
          setSpeaking(false);
          setAudioIndex(null);
          setIsPaused(false);
        };
  
        window.speechSynthesis.speak(utterance);
        
        setAudioIndex(index);
        setSpeaking(true);
        setIsPaused(false);
      }
    }
  };  
  
  useEffect(() => {
    console.log("Sidebar State Updated:", isSidebarOpen);
  }, [isSidebarOpen]);

  let pageNumberGpt = sessionStorage.getItem("pageNumberGpt");
  let currentLinkGpt = sessionStorage.getItem("currentLinkGpt");
  let loggedInUserId = localStorage.getItem("loggedInUserId");
  let loggedInUserRoleId = localStorage.getItem("loggedInUserRoleId");
  let currentURL = localStorage.getItem("currentURL");
  let maxlimit = localStorage.getItem("maxLimit");
  let baseURL = localStorage.getItem("baseURL");
  const initialContext = [
    {
      role: "system",
      content: `
         You are a friendly Employee Assistance ChatBot,
        first greet the user with their name ${empName} (note : take only firstname and greet).
        `,
    },
    {
      role: "user",
      content: "Hi",
    },
  ];

  const [context, setContext] = useState(initialContext);
  const hasMessages = (threads) => {
    return true;
  };

  const handleCopyToClipboard = (text, index) => {
    navigator.clipboard
      .writeText(text)
      .then(() => setCopiedIndex(index))
      .catch((err) => console.error("Unable to copy to clipboard", err));
  };

  //Function to save the chat messages into database
  const send_msg_func = async (req, respo, id) =>{
    const obj2 = {
      request: req,
      response: respo,
      threadId: id,
    };
    const add_msg = await axios.post(
      `${ACS_API}send_msg`,
      obj2
    );
  };

  const categorizeThreads = (threads_list) => {
    try {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const todayThreads = [];
      const last7DaysThreads = [];
      const olderThreads = [];
      threads_list.forEach((thread) => {
        const threadDate = new Date(thread.date);
        const last7DaysDate = new Date(today);
        last7DaysDate.setDate(today.getDate() - 7);
        if (threadDate >= today) {
          todayThreads.push(thread);
        } else if (threadDate >= last7DaysDate) {
          last7DaysThreads.push(thread);
        } else {
          olderThreads.push(thread);
        }
      });

      // Sort threads within each category by datetime
      todayThreads.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      );
      last7DaysThreads.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      );
      olderThreads.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      );

      // Resulting arrays
      setTodayThread(todayThreads);
      setLast7DaysThread(last7DaysThreads);
      setOlderThread(olderThreads);
    } catch (error) {
      console.log("Error:-", error);
    }
  };

  const get_all_threads = async () => {
    try {
      const response = await axios.get(
        `${ACS_API}get_all_threads/${loggedInUserEmail}`
      );
      const names = response.data;
      const id = names.id;
      categorizeThreads(names);
      const transformedMessages = names.map((item) => [item.name, item.id]);
      setThreads(transformedMessages);
      let g = `Hi ${empName}, How can I help you today?`;
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const loadThread = async (thread, index) => {
    handleButtonClick();
    setButtonClicked("Start new Conversation");
    setIsButtonDisabled(false);
    setThreadButtonClicked(true);
    setActiveThread(index);

    try {
      const t_id = thread.id;
      const name = thread.name;
      const response = await axios.get(
        `${ACS_API}get_full_thread/${t_id}`
      );
      const chat = response.data;
      setButtonClicked("Start new Conversation");
      setIsButtonDisabled(false);
      const id = chat.row;
      const transformedMessages = chat.map((item) => {
        const userMsg = { text: item.question, user: true };
        const aiResponse = { text: item.answer, user: false };
        return [userMsg, aiResponse];
      });
      // Flatten the array of arrays into a single array
      const flattenedMessages = [].concat(...transformedMessages);
      try {
        setmessagesa(flattenedMessages);
      } catch (err) {
        console.log("error: ", err);
      }
    } catch (error) {
      console.error("Error: ", error);
    } finally {
      setIsButtonDisabled(false);
    }
  };

  const handleButtonClick = () => {
    setClicked(!clicked);
  };
  let sls05baseurl = environment.lambdaApiUrl_05;
  let sls06baseurl = environment.lambdaApiUrl_06;
  let sls03baseurl = environment.lambdaApiUrl_03;
  let phpbaseurl = environment.phpApiUrl;
  let OPENAI_API = openaiEnv.OPENAI_API;
  let ACS_API = openaiEnv.ACS_API;
  let VERTEX_API = openaiEnv.VERTEX_API;
  let OPENAI_API_NEW = openaiEnv.OPENAI_API_NEW;
  useEffect(() => {
    console.log("Loading state:", isLoading); // Log state whenever it changes
  }, [isLoading]); 
  const resetAllData = () => {
    try {
      SweetAlertHelper.showConfirm(
        "Are you sure you want to reset all the data",
        () => {
          setResponse("");
          setmessagesa([]);
          SetbuttonNames([]);
          SetDropdownNames([]);
          setShowDateTimePicker(false);
          SetShowFileUpload(false);
          setContext(initialContext);
          window.location.reload();
        }
      );
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const extractButtonNames = (text: string) => {
    const startIndex = text.indexOf("Buttons: ['") + 11;
    const endIndex = text.indexOf("']", startIndex);
    if (startIndex !== -1 && endIndex !== -1) {
      const buttonString = text.substring(startIndex, endIndex);
      return buttonString.split("', '");
    }
    return [];
  };

  const extractDropdownopt = (text: string) => {
    const startIndex = text.indexOf("Dropdown: ['") + 11;
    const endIndex = text.indexOf("']", startIndex);
    if (startIndex !== -1 && endIndex !== -1) {
      const buttonString = text.substring(startIndex, endIndex);
      const options = buttonString
        .split("', '")
        .map((option) => option.replace(/'/g, ""));
      return options;
    }
    return [];
  };

  useEffect(() => {
    getPatientDocuments();
    registeredPhysicians_listapi();
    initialquestionCount();
    specialist_listapi();
    physicianDetails_listapi();
    // greet();
    getAllDocumentsAPI();
    getAllhipaaDocAPI();
    // speak({ text: `Hi ${empName}, How can I help you today?`, voice: voices[4]});
    get_all_threads();
  }, []);

  const specialist_listapi = async () => {
    let apiurl = sls05baseurl + ApiHelper.getSpecialties;
    try {
      const body = {
        loggedInUserId: loggedInUserId,
      };
      const res = await axios.post(
        `${apiurl}`,
        body
      );
      const { data } = res;
      // const specialistData = res.data.commandResult.data.specialities.map(
      //   (item) => ({
      //     sid: item.id,
      //     speciality: item.speciality,
      //   })
      // );
      const specialistData = res.data.commandResult.data.specialities.filter(item => item.is_active === 1).map(
        (item) => ({
          id: item.id,
          speciality: item.speciality,
        })
      );  
      setSpecialistList(specialistData);
      return specialistData;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  const physicianDetails_listapi = async () => {
    // let apiurl = sls05baseurl + ApiHelper.FindUserRegisteredDoctorDetails;
    let apiurl = sls05baseurl + ApiHelper.findUserDoctorDetails;
    try {
      const body = {
        id: loggedInUserId,
      };
      const res = await axios.post(
        `${apiurl}`,
        // body
      );
      physicianData = res.data.commandResult.data.userInfo;
    
      setPhysicianDetailsList(physicianData);
      return physicianData;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  const subSpecialist_listapi = async (speciality: string) => {
    let apiurl = sls05baseurl + ApiHelper.getSubSpecialties;
    try {
      const body = {
        speciality: speciality,
      };
      const res = await axios.post(
        `${apiurl}`,
        body
      );

      subSpecialistNames = res.data.commandResult.data.sub_specialities.map(
        (item) => ({
          id: item.id,
          sub_speciality: item.sub_speciality,
        })
      );
      setSubSetSpecialistList(subSpecialistNames);
      return subSpecialistNames;
    } catch (error) {
      console.error("Error:", error);
      // Rethrow the error to propagate it
      throw error;
    }
  };

  const getfaqfirst = async () => {
    let updatedurl = currentURL.replace(/-/g, " ");
    let parts = currentURL.split("/");
    if (parts[1] == "ahsgpt") {
      setPageName(parts[1]);
    } else {
      setPageName(parts[1]);
    }
    let pagenameFirst = parts[1].replace("-", " ");
    try {
      const body = {
        question: pagenameFirst,
        loggedInUserRoleId: loggedInUserRoleId
      };
      const res = await axios.post(
        // "http://127.0.0.1:5000/faq_search",
        `${OPENAI_API_NEW}faq_search`,
        body
      );
      let ans = res.data.answer;
      if (res.data && res.data.answer) {
        setDocumentURLRes(res.data.answer);
      } else {
        console.error("API response is missing 'answer'.", res);
      }
    } catch (err) {
      console.error("Error:", err);
      return [];
    }
  };

  useEffect(() => {
    getfaqfirst();
  }, []);

  const coe_listapi = async (speciality: string, subSpecialty: string) => {
    let apiurl = sls05baseurl + ApiHelper.getCoeBySubSpeciality;
    try {
      const body = {
        speciality: speciality,
        subSpecialty: subSpecialty,
      };

      const res = await axios.post(
        `${apiurl}`,
        body
      );

      const coeList = res.data.commandResult.data.practices.map((item) => ({
        id: item.id,
        company: item.company,
      }));
      setcoeList(coeList);
      return coeList;
    } catch (error) {
      console.error("Error:", error);
      // Rethrow the error to propagate it
      throw error;
    }
  };

  const physician_listapi = async (
    speciality: string,
    subSpeciality: string,
    companySlug: string
  ) => {
    let apiurl = sls05baseurl + ApiHelper.getCoePhysicians;
    try {
      const body = {
        speciality: speciality,
        subSpeciality: subSpeciality,
        companySlug: companySlug,
      };

      const res = await axios.post(
        `${apiurl}`,
        body
      );
      const physicianList = res.data.commandResult.data.physicians.map(
        (item) => ({
          registered_physician_id: item.registered_physician_id,
          full_name: item.full_name,
        })
      );
      setPhysicianList(physicianList);
      // const physicianList = JSON.stringify(data);
      return physicianList;
    } catch (error) {
      console.error("Error:", error);
      // Rethrow the error to propagate it
      throw error;
    }
  };

  const registeredPhysicians_listapi = async () => {
    let apiurl = sls06baseurl + ApiHelper.findUserDoctors;
    try {
      const body = {
        loggedInUserId: loggedInUserId,
      };
      const res = await axios.post(
        `${apiurl}`,
        body
      );
      registeredPhysiciansData = res.data.commandResult.data.companiesList.map(
        (item) => ({
          physician_id: item.physician_id,
          physician_name: item.physician_name,
          company_url: item.company_url,
        })
      );
      // return registeredPhysiciansData;
      let registeredPhysiciansName =
        res.data.commandResult.data.companiesList.map((item) => ({
          physician_name: item.physician_name,
        }));

      setregisteredPhysicianList(registeredPhysiciansData);
      setregisteredPhysicianNames(registeredPhysiciansName);
      return registeredPhysiciansData;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }

  const extractEmailjson = async (text) => {
    const jsonStartIndex = text.lastIndexOf("{");
    const jsonEndIndex = text.indexOf("}", jsonStartIndex) + 1;
    const jsonString = text.substring(jsonStartIndex, jsonEndIndex);

    // Parse the JSON string
    const emailjson = jsonString;
    const jsonObject = JSON.parse(jsonString);
    let apiurl = phpbaseurl + ApiHelper.php_emailDoctor_Chat;
    try {
      const res = await axios.post(
        `${apiurl}`,
        jsonObject
      );
    } catch (error) {
      console.log("error:-", error);
    }
    return emailjson;
  };

  const extractFilejson = async (text) => {
    // Find the index of the start and end of the JSON object
    const jsonStartIndex = text.indexOf("{");
    const jsonEndIndex = text.lastIndexOf("}") + 1;
    // Extract the JSON string
    const jsonString = text.substring(jsonStartIndex, jsonEndIndex);
    // Parse the JSON string
    fileJsonObject = JSON.parse(jsonString);
    // Log the extracted JSON object
};

  
  const extractAppointmentjson = async (text) => {
    const jsonStartIndex = text.lastIndexOf("{");
    const jsonEndIndex = text.indexOf("}", jsonStartIndex) + 1;
    const jsonString = text.substring(jsonStartIndex, jsonEndIndex);
    AppointmentjsonObject = JSON.parse(jsonString);
  };

  const extractScheduledAppointmentjson = async (text) => {
    const jsonStartIndex = text.lastIndexOf("{");
    const jsonEndIndex = text.indexOf("}", jsonStartIndex) + 1;
    const jsonString = text.substring(jsonStartIndex, jsonEndIndex);
    ScheduleAppointmentjsonObject = JSON.parse(jsonString);
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    file = event.target.files && event.target.files[0];
    if (file) {
      fileNames = file.name;
      setFilename(fileNames);
      if(demo === "Report"){
        demo = `file_name ${fileNames}`
      }
      if(demo === "X-ray"){
        demo = `image_name ${fileNames}`
      }
      if(demo === "Video" ){
        demo = `video_name ${fileNames}`
      }
      if(demo === "HIPAA" ){
        demo = `hippa_name ${fileNames}`
      }
      let nContext=[
        ...context,
        {role:"system", content:`You have selected file name ${fileNames}`} ,
        {role:"user", content: demo} 
      ]
      setContext(nContext);
      setSelectedFile(file);
       const userMessage = { text: demo, user: true };
      let aiResponse = { text: "Please wait...", user: false };
      setmessagesa((prevmessagesa) => [
        ...prevmessagesa,
        userMessage,
        aiResponse,
      ]);
      SetShowFileUpload(false);
      SetbuttonNames([])
      fileUrl = URL.createObjectURL(file);
      getCompletionFrommessagesa(nContext)
    }
  };

  const uploadFile = async(hipaa_value)=>{
    const body = new FormData();
    body.append("loggedInUserId", loggedInUserId);
    body.append("fileKey", file);
    let upload = phpbaseurl + ApiHelper.php_uploadAzureFile;
    try{
      const api_call = await axios.post(`${upload}`,body);
      let fileurl = []
      let temp = api_call.data.commandResult.data.fileUrl; 
      fileurl = [...fileurl, temp]
      let obj = {
        isDelegating: null,
        delegateId: null,
        parentId: null,
        isUploadFile: true,
        userId: loggedInUserId,
        data: [
            {
                RecordId: 0,
                UploadedPracticesListSelected: [],
                UploadedPhysicanListSelected: [],
                // practiceInfo: "jamiewisser",
                practiceInfo: ConstantHelper.COMPANY_SLUG,
                isHippa: hipaa_value,
                file: temp,
                fileName: file.name
            }
        ],
        docType: 4
    };
    let apiurlphp = phpbaseurl + ApiHelper.php_uploadEsignMultipleFilesApi;
      let result = await axios.post(
        `${apiurlphp}`,
        obj
      );
      setUploadedUrl(fileurl);
      return fileurl;
    } catch(error){
      console.error("Error:", error);
    }
  };

  const uploadAndAnalyzeImage = async (url) => {
    console.log("url", url[0])
    // let fileurl = []
    try{
      // let temp = url; 
      // fileurl = [...fileurl, temp]
      console.log("api called")
      const imageBody = {
        img_data: url[0],
        question: "For the the given image if it is MRI/CT scan or  X-ray, provide an analysis from radiologist perspective and give a analysis if any diagnosis is required or not, Display the information as HTML paragraph only using <b>, only <h6> and <ul> for bullet points"
      };
      let response = await axios.post(
        `${VERTEX_API}analyze_image`,
        imageBody
      );
      console.log("response", response)
      // let repor_ans = await report("Summarize", url);
      // console.log("repor_ans", repor_ans)
      // return repor_ans;
      return response.data.answer
    } catch(error){
      console.error("Error:", error);
    }
  }

  const handleFileSelectAPI = async () => {
    const body = new FormData();
    body.append("loggedInUserId", loggedInUserId);
    body.append("fileKey", selectedFile);
    let apiurl = phpbaseurl + ApiHelper.php_uploadAzureFile;
    try {
      const res = await axios.post(
        `${apiurl}`,
        body
      );
      let filenameup = res.data.commandResult.data.fileName;
      let fileurl = res.data.commandResult.data.fileUrl;
      let obj = {
        loggedInUserId: fileJsonObject.loggedInUserId,
        companySlug: fileJsonObject.companySlug,
        physicianId: fileJsonObject.selectedPhysicianId,
        specialtyId: fileJsonObject.specialtyId,
        subSpecialtyId: fileJsonObject.subSpecialtyId,
        emailLogTxt: fileJsonObject.emailLogTxt,
        selectedDocsId: fileJsonObject.selectedDocsId,
        hipaaFileName: filenameup,
        hipaaFileUrl: fileurl,
        docType: "11",
      };
      let apiurlphp = phpbaseurl + ApiHelper.php_docotr2doctorEmail_Chat; //docotr2doctorEmail_Chat.php
      let result = await axios.post(
        `${apiurlphp}`,
        obj
      );
    } catch (error) {
      console.error("Error:", error);
    }
  };
  
  const handleUploadFileAPI = async () => {
    console.log("File upload called")
    const body = new FormData();
    body.append("loggedInUserId", loggedInUserId);
    body.append("fileKey1", selectedFile);
    let apiurl = phpbaseurl + ApiHelper.php_fileUploadServiceApi;
    try {
      const res = await axios.post(
        `${apiurl}`,
        body
      );
      // let filenameup = res.data.commandResult.data.fileName;
      let file = res.data.commandResult.data.fileUrl;
      let obj = {
        isDelegating: null,
        delegateId: null,
        parentId: null,
        isUploadFile: true,
        userId: loggedInUserId,
        data: [
            {
                RecordId: 0,
                UploadedPracticesListSelected: [],
                UploadedPhysicanListSelected: [],
                // practiceInfo: "jamiewisser",
                practiceInfo:ConstantHelper.COMPANY_SLUG,
                isHippa: fileJsonObject.data[0].isHippa,
                file: file,
                fileName: selectedFile.name
            }
        ],
        docType: 4
    };
      let apiurlphp = phpbaseurl + ApiHelper.php_uploadEsignMultipleFilesApi;
      let result = await axios.post(
        `${apiurlphp}`,
        obj
      );
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUploadAPI = async () => {
    let apiurl = phpbaseurl + ApiHelper.php_docotr2doctorEmail_PatientApi; //docotr2doctorEmail_Patient.php
    try {
      const body = new FormData();
      body.append("loggedInUserId", loggedInUserId);
      // body.append("companySlug", "jamiewisser");
      body.append("companySlug",ConstantHelper.COMPANY_SLUG);
      body.append("physicianType", "1");
      body.append("physicianId", fileJsonObject.selectedPhysicianId);
      body.append("specialtyId", fileJsonObject.specialtyId);
      body.append("subSpecialtyId", fileJsonObject.subSpecialtyId);
      body.append("fileDesTxt", fileJsonObject.emailLogTxt);
      body.append("docType", "11");
      body.append("userOptionId", "6");
      body.append("selectedDocsId", fileJsonObject.selectedDocsId);
      body.append("hippaFileDesTxt", null);
      body.append("showNewFile", "2");
      body.append(
        "attachmentDocObj",
        JSON.stringify(fileJsonObject.attachmentDocObj)
      );
      body.append("requestingPhyEmail", loggedInUserEmail);
      body.append("requestingPhyMobile", loggedInUserMobile);
      body.append("reuestTime", "1");


      let result = await axios.post(
        // "https://api.medrecordexchange.com/dev/docotr2doctorEmail_Chat.php",
        `${apiurl}`,
        body
      );
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleAppointmentAPI = async () => {
    let apiurl = phpbaseurl + ApiHelper.php_schedule_appointment; //schedule_appointment.php
    const body = {
      company_slug: AppointmentjsonObject.companySlug,
      patient_id: AppointmentjsonObject.loggedInUserId,
      physician_id: AppointmentjsonObject.selectedPhysicianId,
      speciality_id: AppointmentjsonObject.specialtyId,
      sub_speciality_id: AppointmentjsonObject.subSpecialtyId,
      reason: AppointmentjsonObject.emailLogTxt,
      created_by: AppointmentjsonObject.loggedInUserId,
      scheduled_at: AppointmentjsonObject.scheduled_at,
    };

    try {
      const res = await axios.post(
        // "https://api.medrecordexchange.com/dev/schedule_appointment.php",
        `${apiurl}`,
        body
      );
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleScheduleAppointmentAPI = async () => {
    let apiurl = phpbaseurl + ApiHelper.php_schedule_appointment;
    const body = {
      company_slug: ScheduleAppointmentjsonObject.company_slug,
      patient_id: ScheduleAppointmentjsonObject.patient_id,
      physician_id: ScheduleAppointmentjsonObject.physician_id,
      speciality_id: "43",
      sub_speciality_id: "276",
      reason: ScheduleAppointmentjsonObject.reason,
      created_by: ScheduleAppointmentjsonObject.created_by,
      scheduled_at: ScheduleAppointmentjsonObject.scheduled_at,
    };

    try {
      const res = await axios.post(
        // "https://api.medrecordexchange.com/dev/schedule_appointment.php",
        `${apiurl}`,
        body
      );
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDropdownChange = async (event: any) => {
    setDropdownClicked(event.target.value);
    demo = event.target.value;
    let nContext;
    if (specialistName === "") {
      specialistName = event.target.value;
      const foundObject = SpecialistList.find(item => item.speciality === specialistName);
      let specialistid = "";
      if (foundObject) {
        specialistid = foundObject.id;
      } else {
        return null; // Or handle the case where the name is not found
      }
      // demo = specialistName;
      let subspelist = await subSpecialist_listapi(specialistid);
      nContext = [
        ...context,
        { role: "user", content: event.target.value },
        {
          role: "system",
          content: `here is subSpecialistNames  ${JSON.stringify(subspelist)}`,
        },
      ];
      // setContext(nContext);
    } else if (sub_specialistName === "") {
      sub_specialistName = event.target.value;
      let coenamelist = await coe_listapi(specialistName, sub_specialistName);
      nContext = [
        ...context,

        { role: "user", content: event.target.value },
        {
          role: "system",
          content: `here is coe names  ${JSON.stringify(coenamelist)}`,
        },
      ];
    } else if (practice_name === "") {
      practice_name = event.target.value;
      if (practice_name.includes("Princeton")) {
        // practice_name = "jamiewisser";
        practice_name = ConstantHelper.COMPANY_SLUG;
      } else if (practice_name.includes("HITLAB Surgical")) {
        practice_name = "hitlab";
      }
      // demo = specialistName;
      let physicianlistname = await physician_listapi(
        specialistName,
        sub_specialistName,
        practice_name
      );
      nContext = [
        ...context,
        { role: "user", content: event.target.value },
        {
          role: "system",
          content: `here is coe names  ${JSON.stringify(physicianlistname)}`,
        },
      ];
    } else if (physician_name === "") {
      physician_name = event.target.value;
      nContext = [...context, { role: "user", content: event.target.value }];
    } else {
      nContext = [...context, { role: "user", content: event.target.value }];
    }
    setContext(nContext);
    const userMessage = {
      text: event.target.value,
      user: true,
    };
    const aiResponse = {
      text: "please wait...",
      user: false,
    };
    // speak({ text: aiResponse.text, voice: voices[4] });
    setmessagesa((prevmessagesa) => [
      ...prevmessagesa,
      userMessage,
      aiResponse,
    ]);
    getCompletionFrommessagesa(nContext);
    SetDropdownNames([]); // Clear dropdown options after selecting one
  };

  const updateUI = (dateTime) => {
    setSelectedDateTime(dateTime);

    if (dateTime) {
      const year = dateTime.getFullYear();
      const month = String(dateTime.getMonth() + 1).padStart(2, "0");
      const day = String(dateTime.getDate()).padStart(2, "0");
      const hours = String(dateTime.getHours()).padStart(2, "0");
      const minutes = String(dateTime.getMinutes()).padStart(2, "0");
      const seconds = String(dateTime.getSeconds()).padStart(2, "0");

      if (!dateSet) {
        setDateSet(true);
        return;
      }

      const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

      demo = formattedDateTime;
      let nContext = [...context, { role: "user", content: formattedDateTime }];

      setContext(nContext);
      const userMessage = {
        text: formattedDateTime,
        user: true,
      };
      const aiResponse = {
        text: "please wait...",
        user: false,
      };
      setmessagesa((prevmessagesa) => [
        ...prevmessagesa,
        userMessage,
        aiResponse,
      ]);
      getCompletionFrommessagesa(nContext);
      setShowDateTimePicker(false);

      setDateSet(false); // Reset for the next selection
    }
  };

  const report = async (user_question, url) => {
    let response;
  
    const isPdf = url.every(
      (url) =>
        url.toLowerCase().endsWith(".pdf") ||
        url.toLowerCase().endsWith(".docx")
    );
    const isImage = url.every(
      (url) =>
        url.toLowerCase().endsWith(".jpg") ||
        url.toLowerCase().endsWith(".jpeg") ||
        url.toLowerCase().endsWith(".dcm") ||
        url.toLowerCase().endsWith(".dicom")       
    );
    const isVideo = url.every(
      (url) => 
      url.toLowerCase().endsWith(".webm") ||
      url.toLowerCase().endsWith(".mp4")
    );
    try {
      if (isPdf) {
        // Create a separate body object for the PDF API call
      
        const pdfBody = {
          question: user_question,
          pdf_blob_urls: url
        };

        response = await axios.post(
          `${OPENAI_API_NEW}reports`,
          pdfBody
        );
      }
      else if (isImage) {
        let urlone = url[0]
        // Create a separate body object for the image API call
        const imageBody = {
          img_data: urlone,
          question: user_question,
        };

        response = await axios.post(
          `${VERTEX_API}analyze_image`,
          imageBody
        );
      } else if(isVideo){
        let urlone = url[0];
      
        const videoBody = {
          video_data: urlone,
          video_question: user_question
        };
        response = await axios.post(
          `${VERTEX_API}analyze_video`,
          // `http://127.0.0.1:5000/generate_uri`,
          videoBody,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
      

      } else if(!isPdf && !isImage && !videoUrl){
        let urlone = url[0]
        const imageBody = {
          img_data: urlone,
          question: user_question,
        };
        response = await axios.post(
          `${VERTEX_API}analyze_image`,
          imageBody
        );
      }
      if (response) {
        return response.data.answer;
      } else {
        console.log("No valid API call was made.");
      }
    } catch (error) {
      console.error("Error calling Python API:", error.message);
    }
  };

  window.printDocumentUrl = (url) => {
    if (
      url.toLowerCase().endsWith(".pdf") ||
      url.toLowerCase().endsWith(".docx")
    ) {
      SetPdfUrl([...pdfUrl, url]);
    } else if (
      url.toLowerCase().endsWith(".jpg") ||
      url.toLowerCase().endsWith(".jpeg") ||
      url.toLowerCase().endsWith(".png") ||
      url.toLowerCase().endsWith(".")
    ) {
      SetImageUrl([...imageUrl, url]);
    } else if(
      url.toLowerCase().endsWith(".webm") ||
      url.toLowerCase().endsWith(".mp4")
    ) {
      setVideoUrl([...videoUrl, url]);
    }
    else {
      console.error("Invalid PDF URL:", url);
    }
  };

  window.demoTest = (url) => {
    SweetAlertHelper.showConfirm(
      "Are you sure you want to open this documents in new tab?",
      () => {
        window.open(url, "_blank");
      }
    );
  };

  const getAllhipaaDocAPI = async () => {
    let apiurl = sls03baseurl + ApiHelper.name_findDocsBySubGroupApi;
    const body = {
      loggedInUserId: loggedInUserId,
      company_slug: null,
      doc_group_id: "HD",
      default_group_id: 17,
    };

    try {
      const res = await axios.post(
        // "https://sls-eus-dev-elemrex-esign-05.azurewebsites.net/api/getAllDocumentsList",
        `${apiurl}`,
        body
      );
      const { data } = res; // Use res.data directly

      const allHipaaDocumentsData = res.data.commandResult.data.documentList;
      const allHipaaDocumentData = allHipaaDocumentsData.slice(0, 10);

      setHippadocumentLists(allHipaaDocumentData);
      return allHipaaDocumentData;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getAllDocumentsAPI = async () => {
    let apiurl = sls05baseurl + ApiHelper.getPatientAllDocumentsList;
    const body = {
      userId: loggedInUserId,
      // company_slug: "jamiewisser",
      company_slug:ConstantHelper.COMPANY_SLUG,
      physicianId: "E",
    };

    try {
      const res = await axios.post(
        // "https://sls-eus-dev-elemrex-esign-05.azurewebsites.net/api/getAllDocumentsList",
        `${apiurl}`,
        body
      );
      const { data } = res; // Use res.data directly

      const allDocumentsData = res.data.commandResult.data.documentList.map(
        (item) => ({
          RecordId: item.RecordId,
          DocumentName: item.DocumentName,
          DocumentUrl: item.DocumentUrl,
        })
      );
      const allDocumentData = allDocumentsData.slice(0, 10);

      setdocumentLists(allDocumentData);
      return allDocumentData;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getPatientDocuments = async () => {
    let apiurl = sls05baseurl + ApiHelper.getTopDocumentsList;

    try {
      const body = {
        userId: loggedInUserId,
        limit: "5",
      };
      const res = await axios.post(
        // "https://sls-eus-dev-elemrex-esign-05.azurewebsites.net/api/getTopDocumentsList",
        `${apiurl}`,
        body
      );

      const { data } = res;

      const extractedDocuments = res.data.commandResult.data.documentList.map(
        (item) => ({
          DocName: item.DocumentName,
          DocURL: item.DocumentUrl,
          DocDescription: item.DocumentDescription,
        })
      );

      const obj={
        'docs': extractedDocuments
      }
      const table_response = await axios.post(
        // `http://127.0.0.1:5000/get_doc_table`,
        `${OPENAI_API_NEW}get_doc_table`,
        obj);
      html_table = table_response.data.assres
      html_table = html_table 
      checkboxtable = html_table
      // if (html_table.includes("Buttons")) {
      //   var buttonN = extractButtonNames(html_table);
      //   console.log("buttonN", buttonN)
      //   SetbuttonNames(buttonN);
      
      // }
      // SetbuttonNames(['Compare', 'Summarize', 'Anatomical Abnormalities', 'Other Question', 'Reset'])
      console.log("html_table", html_table)
      setpatientDocuments(extractedDocuments);
      setHtmlFormat(html_table);
      return html_table;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  const FAQ_Document = ` You are the help document chatbot, here to assist with help document questions.
 When the user clicks on FAQ button or asks any questions related to faq then :
  for current ${currentLinkGpt} response steps are given here: ${documentURLRes}, Do not show deatiled steps only show last step which is like "For more details, go to <a href="https://elemrexproductionstorage.blob.core.windows.net/elemrex-esign-01/help_docs/AHS_Patient_Site_Instructions.pdf#page=${pageNumberGpt}" target="_blank">${currentLinkGpt}</a> show anchor tag with the given url and ${pageNumberGpt} (note: change page number in url to this ${pageNumberGpt}" .
  first say to user that "You are on ${currentLinkGpt} page for more details click on this link (note : use html anchor tag only from above steps and If ${currentLinkGpt} is "ahsgpt" or "Dashboard" do not provide a html anchor tag and instead just say, "You are on ${currentLinkGpt} page. Do you have any questions on FAQ?" )" .\
 `;

  const ExpertOpinionCOntext = `
  You are the expert opinion chatbot, here to assist with expert opinion related questions.
  When the user clicks on expert opinion button then :
    You can ask following :
          first say user to select from respective dropdown name and create Dropdown array with speciality names from Specialist provided below (note: Dropdown D should be capital), like this, ex: Dropdown: ['Multiple Sub-Specialty', 'sub-speciality2']
            - Say user to "please select specialist name:"
              Specialist Names:  ${JSON.stringify(SpecialistList)}
            - Say user to please select sub-specialist"
              Sub-Specialist Names: ${JSON.stringify(subSpecialistList)}
            - Say user to "please select Center Of Excellence(COE) name" (note : consider subSpecialty and subSpeciality are same words)(note: If Center Of Excellence(COE) name is not available the provide "Princeton Surgical Specialities" option)
              Center Of Excellence(COE) Names: ${JSON.stringify(coeList)}
            - Say user to "please select Physician name" (note : consider subSpecialty and subSpeciality are same words)
              Physician Names: ${JSON.stringify(physicianList)}\
              remember every user input and add to respective json key given in format below (note : instead of taking name take the id's respective responses).
              consider loggedInUserId as ${loggedInUserId} and instead of taking name take the id's prvoide repective id for specialtyId, subSpecialtyId ,selectedPhysicianId  and if companySlug contains ${ConstantHelper.COMPANY_NAME} then take companySlug as ${ConstantHelper.COMPANY_SLUG} ).\

    Check user selected physician name is present in registered physician names list : ${JSON.stringify(
            registeredPhysicianNames
          )}, if physician name is present in the registered physician list then strictly follow Step A given below, else if the physician is not present in the registered physician list then strictly follow Step B given below (note : strictly follow Step A and Step B according to condition)
              Step A) Ask user "Do you want to ask expert opinion or send email or Appointment request?" note: also provide Buttons array(note: Buttons B should be capital)  like this, Buttons: ['Ask Expert Opinion', 'Send Email', 'Appointment'] .\
              Step B) Ask user "Do you want to ask expert opinion or send email" note: also provide Buttons array(note: Buttons B should be capital)  like this, Buttons: ['Ask Expert Opinion', 'Send Email'] .\

            If user selects "Expert opinion" follow  all steps 2,3,4 and 5 given below. or if user selects "send email" then follow step 5 only given below or if user selects "Appointment" then follow step 1 and step 5 only.\
            1) ask user to enter appointment date in YYYY-MM-DD format and store in json as scheduled_at(note: if user chooses date and time in YYY-MM-DD format then don;t ask again ti enter date and time).\
            2) ask user to "Choose document which you want to share", create Dropdown array with document names from documents list provided above (note: Dropdown D should be capital), like this, ex: Dropdown: ['Doc1.pdf', 'Doc2.pdf'] (note : take respective RecordId of selected document from the list and store in "selectedDocsId" variable)
              - Select document name : ${JSON.stringify(
                documentLists)},
                a) ask user "Do you want to share another document?" (note: allow user to select maximum 5 documents only so more than 5 times do not ask and take RecordId of selected selectedDocsId)note: also provide Buttons array(note: Buttons B should be capital)  like this, Buttons: ['Yes', 'No'] .\
                b) If user says "Yes" then goto step 2 above.
                c) If user says "No" then goto step 3 below.
            3) ask user to "Do you want to select existing hipaa document or upload new hipaa". note: also provide Buttons array(note: Buttons B should be capital)  like this, Buttons: ['Choose existing document', 'Upload new document']
            4) if user selects choose existing document then follow Rule 1 or if user selects upload new document then follow Rule 2.
              Rule 1) create Dropdown array with document names from documents list provided above (note: Dropdown D should be capital), like this, ex: Dropdown: ['Doc1.pdf', 'Doc2.pdf'] (note : take user selected existing document object data from documents list provided above and store in "attachmentDocObj" variable)
                        - Select document name : ${JSON.stringify(HippadocumentLists)},
              Rule 2) ask user to upload Hipaa document, like "Choose Hipaa file to upload". note: also provide Buttons array(note: Buttons B should be capital) like this, Buttons: ['Continue', 'Cancel'] and store selected existing document name or uploaded document name in "hipaaFileName".
                      if user say "Continue" set "hipaaFileName"  in json as filename ${filename} or ${selectedFile} and show json to user in below json format.
            5) ask user to enter message and store user message in "emailLogTxt" variable in json .\
    Ask each question one by one  and store in respective json key.\
    remember every user input and add to respective json key given in format below (note: take document name as DocumentName and document url as DocumentUrl document list above and add them in json).\
    Json format should look like this and store in variable called "existingfile-data" if user selects "choose existing document" in "expert Opinion" and skip hippaFileName and hipaaFileUrl from "existingfile-data" json and store "showNewFile" as "2" and consider RecordId of selected respective selectedDocsId(ex: "selectedDocsId":"7448") in "existingfile-data" json or store in variable called "uploadfile-data" if user selects "upload new document" in "expert opinion" and skip hippaFileName,attachmentDocObj  and hipaaFileUrl from "uploadfile-data" json and store "showNewFile" as "1" in "uploadfile-data" json or if user selects "send email" store in "email-data" and skip selectedDocsId, hippaFileName and hipaaFileUrl from "email-data" json or if user selects "Appointment request"  store in "appointment-data" and skip selectedDocsId, hippaFileName and hipaaFileUrl from "appointment-data" json and say "Here is your Json:" (note : show json along with its respective variable name).
      "existingfile-data" or "uploadfile-data" or "email-data" or "appointment-data":
        {
          "loggedInUserId": "530",
          "specialtyId": "speciality id",
          "subSpecialtyId": "subSpecialty id",
          "companySlug": "hospital name",
          "selectedPhysicianId":"physician id",
          "showNewFile":"1",
          "emailLogTxt":"abcd",
          "selectedDocsId": "7448" , "6554", "5644"
          "attachmentDocObj:"{"RecordId":6084,"DocumentUrl":"https://elemrexproductionstorage.blob.core.windows.net/elemrex-esign-01/a1984-66_0_1688182806.pdf","CreatedDate":"30 Jun 2023 23:40 PM","DocumentName":"A1984-66_0","DocumentDescription":"A1984-66_0","ShowConfirmation":0,"IsActive":1,"DocTyep":8,"documentMessage":"","isUploadAllowed":0}"
        }
      and show user entered or selected information only (do not show id) in list format for example: specialty - Cardiology, subspeciality: None etc below json and ask please check all details and confirm the provided details are correct? note: also provide Buttons array(note: Buttons B should be capital)  like this, Buttons: ['I confirm', 'Cancel']
    if user say "I confirm" then say based on user selected option like for "ask expert opinion" as "Expert opinion request sent successfully, Do you need any other help?", and for "send email" as "email sent Successfully, Do you need any other help?", and for "Appointment request" as "Thank you for your appointment request. The doctor's office is being notified and will be in touch with you by telephone or email regarding the exact scheduled date and time of your upcoming appontment. Do you need any other help?". if cancel then ask "Do you need any other help?".`;

    const ExpertOpinionDirectQuestionCOntext = `
    You are the expert opinion direct question chatbot, here to assist with expert opinion related questions.
    When the user say or ask expert opinion or send email directly by giving physician name or specialty name or sub-specialty name (for ex: "I want to ask expert opinion with John Don" or "I want to ask expert opinion for cardiology") then :
      Search the user given physician name or specialty name or sub-specialty name is present or not in physician details list provided above:
        - Physician Details: ${JSON.stringify(PhysicianDetailsList)}
        - If the physician name is present then take only company_url name,specialty and sun-specialty names or if user given specialty name present then take only company_url name,sub-specialty and physician names or if user given sub-specialty name is present in the physician details list then take the physician_name, company_url name, speciality_name and sub_speciality_name of that physician and add to respective json key given in format below (note: if multiple specialty names or sub-specialty names are present for given physician name or specialty name or sub-specialty name then by showing that specialty names or sub-specialty names in html tabular format with column names Physician Name, Specialty Name , Sub-Specialty. Provide proper HTML table tag only (note: do not add '''html tag)with all borders for columns and rows code  do not add <html>, <title> or '''html etc tags and ask user to enter physician name or specialty name and show sub-specialty names according to user entered specialty or physician name and ask necessary questions until you get one physician name, one specialty name and one sub-specialty name).
        - Display physician name, specialty and sub-specialty in below given format :
          Physician Name : 
          Specialty Name :
          Sub-Specialty Name :
          and ask to user "Please check the physician, specialty and sub-specialty names are correct?". note: also provide Buttons array(note: Buttons B should be capital) like this, Buttons: ['Yes, Correct', 'No']
          If user says "Yes, Correct" then follow below given steps.(note: Don't show the steps to user)
            If user says or asks "expert opinion" follow  all steps 1,2,3 and 4 given below. or if user selects "send email" then follow step 4 only given below.\
            1) ask user to "Choose document which you want to share", create Dropdown array with document names from documents list provided above (note: Dropdown D should be capital), like this, ex: Dropdown: ['Doc1.pdf', 'Doc2.pdf'] (note : take respective RecordId of selected document from the list and store in "selectedDocsId" variable)
              - Select document name : ${JSON.stringify(
                documentLists)},
                a) ask user "Do you want to share another document?" (note: user can upload maximum 5 documents so more that 5 times do not ask and take RecordId of selected selectedDocsId)note: also provide Buttons array(note: Buttons B should be capital)  like this, Buttons: ['Yes', 'No'] .\
                b) If user says "Yes" then goto step 2 above.
                c) If user says "No" then goto step 3 below.
            2) ask user to "Do you want to select existing hipaa document or upload new hipaa". note: also provide Buttons array(note: Buttons B should be capital)  like this, Buttons: ['Choose existing document', 'Upload new document']
            3) if user selects choose existing document then follow Rule 1 or if user selects upload new document then follow Rule 2.
              Rule 1) create Dropdown array with document names from documents list provided above (note: Dropdown D should be capital), like this, ex: Dropdown: ['Doc1.pdf', 'Doc2.pdf'] (note : take user selected existing document object data from documents list provided above and store in "attachmentDocObj" variable)
                        - Select document name : ${JSON.stringify(HippadocumentLists)},
              Rule 2) ask user to upload Hipaa document, like "Choose Hipaa file to upload". note: also provide Buttons array(note: Buttons B should be capital) like this, Buttons: ['Continue', 'Cancel'] and store selected existing document name or uploaded document name in "hipaaFileName".
                      if user say "Continue" set "hipaaFileName"  in json as filename ${filename} or ${selectedFile} and show json to user in below json format.
            4) ask user to enter message and store user message in "emailLogTxt" variable in json .\
            remember every user input and add to respective json key given in format below (note : instead of taking name take the id's respective responses).
            consider loggedInUserId as ${loggedInUserId} and instead of taking name take the id's prvoide repective id for specialtyId, subSpecialtyId ,selectedPhysicianId(note: take user physician_id of user given physician name with matching selected specialty and sub-specialty and store in "selectedPhysicianId" variable) and if companySlug contains ${ConstantHelper.COMPANY_NAME} then take companySlug as ${ConstantHelper.COMPANY_SLUG} ).\
    Ask each question one by one  and store in respective json key.\
    remember every user input and add to respective json key given in format below (note: take document name as DocumentName and document url as DocumentUrl document list above and add them in json).\
    Json format should look like this and store in variable called "existingfile-data" if user selects "choose existing document" in "expert Opinion" and skip scheduled_at, hippaFileName and hipaaFileUrl from "existingfile-data" json and store "showNewFile" as "2" and consider RecordId of selected respective selectedDocsId(ex: "selectedDocsId":"7448") in "existingfile-data" json or store in variable called "uploadfile-data" if user selects "upload new document" in "expert opinion" and skip scheduled_at, hippaFileName,attachmentDocObj  and hipaaFileUrl from "uploadfile-data" json and store "showNewFile" as "1" in "uploadfile-data" json or if user selects "send email" store in "email-data" and skip scheduled_at, selectedDocsId, hippaFileName and hipaaFileUrl from "email-data" json or if user selects "Appointment request"  store in "appointment-data" and skip selectedDocsId, hippaFileName and hipaaFileUrl from "appointment-data" json and say "Here is your Json:" (note : show json along with its respective variable name).
      "existingfile-data" or "uploadfile-data" or "email-data" or "appointment-data":
        {
          "loggedInUserId": "530",
          "specialtyId": "speciality id",
          "subSpecialtyId": "subSpecialty id",
          "companySlug": "company url",
          "selectedPhysicianId":"physician_id",
          "showNewFile":"1",
          "scheduled_at": "2023-11-21 10:30:00"
          "emailLogTxt":"abcd",
          "selectedDocsId": "7448" , "6554", "5644"
          "attachmentDocObj:"{"RecordId":6084,"DocumentUrl":"https://elemrexproductionstorage.blob.core.windows.net/elemrex-esign-01/a1984-66_0_1688182806.pdf","CreatedDate":"30 Jun 2023 23:40 PM","DocumentName":"A1984-66_0","DocumentDescription":"A1984-66_0","ShowConfirmation":0,"IsActive":1,"DocTyep":8,"documentMessage":"","isUploadAllowed":0}"
        }
    and show user entered information only (do not show id) in list for example: specialty - Cardiology, subspeciality: None etc below json and ask please check all details and confirm the provided details are correct? note: also provide Buttons array(note: Buttons B should be capital)  like this, Buttons: ['I confirm', 'Cancel']
    if user say "I confirm" then say based on user selected option like for "ask expert opinion" as "Expert opinion request sent successfully, Do you need any other help?", and for "send email" as "email sent Successfully, Do you need any other help?", and for "book appointment" as "Thank you for your appointment request.  The doctor's office is being notified and will be in touch with you by telephone or email regarding the exact scheduled date and time of your upcoming appontment. Do you need any other help?". if cancel then ask "Do you need any other help?".`;

  const AppointmentContext = `
    You are the Appointment request chatbot, here to assist with Appointment request related questions.
    When the user says or clicks on Appointment request button then:(note: if user provides physician then check in registeredPhysicianList if present follow step b and c so on)
    You can ask following : (note : if user has provided the physician name which is in the registered physician list then don't show dropdown directly ask user to choose the appointment data and time, take the respective physician id and add physician id in json as required)
    - create Dropdown array with registered physician names from list of registered physicians provided above (note: Dropdown D should be capital), like this, ex: Dropdown: ['Amit Sinha', 'Amit Sinha2']
      a. first say user to "please Select registered physician name:" then show dropdownS
        Registered Physician Names: ${JSON.stringify(registeredPhysicianList)}
      b. ask user to enter message and store user message in "reason" variable in json .\
      c. ask user to enter appointment date in YYYY-MM-DD format and store in json as scheduled_at.\
    remember every user input and add to respective json key given in format below  and store in variable "scheduleAppointment-data": .
    consider patient_id and created_by as ${loggedInUserId} and say "Here is your Json:".\
      "scheduleAppointment-data":
      {
        "company_slug": "jamiewisser",
        "patient_id": "530",
        "physician_id": "534",
        "reason": "test",
        "created_by": "530",
        "scheduled_at": "2023-11-21 10:30:00"
      }
    and show user entered information only (do not show id) in list for example: scheduled_at - 2023-11-21 10:30:00, reason: test etc below json and ask please check all details and confirm the provided details are correct? note: also provide Buttons array(note: Buttons B should be capital)  like this, Buttons: ['I confirm', 'Cancel']
    if user say "I confirm" then "Thank you for your appointment request. The doctor's office is being notified and will be in touch with you by telephone or email regarding the exact scheduled date and time of your upcoming appontment.Do you need any other help?". if cancel then ask "Do you need any other help?"`;
    const UploadMyDocumentsContext = `
    You are the upload documents chatbot, here to assist with upload documents.
    If user says or clicks on  "Upload Documents" then ask "Do you want to upload report, hipaa, video or x-ray" and also provide Buttons array(note: Buttons B should be capital)  like this, Buttons: ['Report', 'HIPAA', 'X-ray', 'Video'] .\
    If user clicks on "X-ray" button or "Report" button or "Video" button then:
    - ask user to upload document, like "Choose file to upload". note: also provide Buttons array(note: Buttons B should be capital) like this, Buttons: ['Cancel'].
    - ask user "file_Name uploaded successfully. Do you want to analyze the uploaded document?" note: also provide Button array (note: Buttons B should be capital) like this, Buttons: ['Yes, analyze', 'Cancel'] 
    - if cancel then ask "Do you need any other help?"
    If user clicks on "HIPAA" button then:
    - ask user to upload HIPAA, like "Choose file to upload". note: also provide Buttons array(note: Buttons B should be capital) like this, Buttons: ['Cancel'].
    - ask user "please confirm do you want to upload hippa_name?" note: also provide Buttons array(note: Buttons B should be capital)  like this, Buttons: ['Yes, upload', 'Cancel']
    - if user says "Yes, upload" say "fileName uploaded successfully. Do you need any other help?"
    `;
  
  const ViewMyDocumentsContext = `you are are html code displayer chatbot. user has provided html code in variable HTMLCODE: '''${htmlFormat}''', you need to return code without any change as output(note: Do not include  HTMLCODE: and quotes). for ex: HTMLCODE: '''<html><b> Hello</b>''' then return output as <html><b> Hello</b>
   and provide Buttons array(note: Buttons B should be capital)  like this,  Buttons: ['Compare', 'Summarize', 'Other Question', 'Reset']`; 
 
  const newChat = `
  You are a friendly Employee Assistance ChatBot,
  first greet the user with their name ${empName} (note : take only firstname and greet)
   `;

  // const [context, setContext] = useState(initialContext);

  const getCompletionFrommessagesa = async (ncontext: any[]) => {
    if (
      !demo.includes("Start New Conversation") &&
      !demo.toLowerCase().includes("reset") &&
      !demo.toLowerCase().includes("exit chat")
    ) {
      questionCount();
    }
    if (count < limit) {
      try {
        // const res = await client.getChatCompletions(deploymentId, ncontext);
        const body = {
          ncontext: ncontext,
          user_id: loggedInUserId
        };
        const res = await axios.post(
          `${OPENAI_API_NEW}get_ai_res`,
          body
        );
        let assres = res.data.assres;
        SetAssistant(assres);
        
        if (assres !== null) {
          if (assres.includes("checkbox")) {
            checkboxtable = assres.split("Buttons:")[0];
          }
          SetAssistant(assres);

          if (demo === "Upload new document" || demo === "Report" || demo === "HIPAA" || demo === "X-ray"  || demo === "Video") {
            SetShowFileUpload(true);
          }
          
          if (assres.includes("YYYY-MM-DD")) {
            // setShowDatePicker(true);
            setShowDateTimePicker(true);
          }
         
          if (demo === "I confirm") {
            if (Filejson != null) {
              handleUploadAPI();
            }
            if (Filejson1 != null) {
              handleFileSelectAPI();
            }
            if (UploadFilejson1 != null) {
              handleUploadFileAPI();
            }
            if (Appointmentjson != null) {
              handleAppointmentAPI();
            }
            if (scheduledAppointmentjson != null) {
              handleScheduleAppointmentAPI();
            }
           
          }
          if (demo === "Continue" || demo === "Cancel" || demo === "Upload") {
            SetShowFileUpload(false);
          }
          if (demo.includes('file_name')){
            await uploadFile(4);
          }
          if (demo.includes('image_name')){
            await uploadFile(9);
          }
          if (demo.includes('video_name')){
            await uploadFile(10);
          }          
          if (assres.includes("email-data")) {
            Emailjson = extractEmailjson(assres);
          }
          if (assres.includes("existingfile-data")) {
            Filejson = extractFilejson(assres);
          }
          if (assres.includes("uploadfile-data")) {
            Filejson1 = extractFilejson(assres);
          }
          if (assres.includes("uploadMyDoc-data")) {
            UploadFilejson1 = extractFilejson(assres);
          }
          if (assres.includes("appointment-data")) {
            Appointmentjson = extractAppointmentjson(assres);
          }
          if (assres.includes("scheduleAppointment-data")) {
            scheduledAppointmentjson = extractScheduledAppointmentjson(assres);
          }

          if (assres.includes("Dropdown:")) {
            let dropdownopt = extractDropdownopt(assres);
            SetDropdownNames(dropdownopt);
          }
          if (assres.includes("Do you need any other help?")) {
                setIsButtonDisabled((prevState) => !prevState);
                // setIsButtonDisabled(false);
                specialistName = "";
                SetPdfUrl([])
                setClickedButton("");
                setmessagesa([]);
                SetbuttonNames([]);
                setContext(initialContext);
                setSelectedFile(null);
                setFilename(null);
                SetDropdownNames([]);
                setShowDateTimePicker(false);
                SetShowFileUpload(false);
                setThreadButtonClicked(false);
                Setisexpobutton(false);
                SetisDirectexpobutton(false);
                Setisfaqbutton(false);
                Setisappobutton(false);
                setIsGeneralSearch(false);                
          }

          SettextBeforeButtons(assres.split("Buttons:")[0]);
          let textBeforeBut = "";
          if (assres.toLowerCase().includes("dropdown:")) {
            textBeforeBut = assres.toLowerCase().split("dropdown:")[0];
          } else if (assres.includes("appointment-data")) {
            let test = assres.split("Buttons:")[0];
            const splitString = test.split("}");
            textBeforeBut = splitString[splitString.length - 1].trim();
          } else if (assres.includes("existingfile-data")) {
            let test = assres.split("Buttons:")[0];
            const splitString = test.split("}");
            textBeforeBut = splitString[splitString.length - 1].trim();
          } else if (
            assres.includes(
              "Here is the list of the patient's 5 most recent documents: "
            )
          ) {
            let test = assres.split("Buttons:")[0];
            const splitString = test.split("```");
            const cleanedString = splitString
              .map((str) => str.replace(/```html/g, "").replace(/```/g, ""))
              .join("");

            textBeforeBut = cleanedString.trim();
          } else if (assres.includes("email-data")) {
            let test = assres.split("Buttons:")[0];
            const splitString = test.split("}");
            textBeforeBut = splitString[splitString.length - 1].trim();
          } else if (assres.includes("uploadfile-data")) {
            let test = assres.split("Buttons:")[0];
            const splitString = test.split("}");
            textBeforeBut = splitString[splitString.length - 1].trim();
          } else if (assres.includes("uploadMyDoc-data")) {
            let test = assres.split("Buttons:")[0];
            const splitString = test.split("```");
            textBeforeBut = splitString[splitString.length - 1].trim();
          } else if (assres.includes("scheduleAppointment-data")) {
            let test = assres.split("Buttons:")[0];
            const splitString = test.split("}")
            // let splitString =  splitString1.split("}") ;
            textBeforeBut = splitString[splitString.length - 1].trim();
          } else if (assres.includes("Buttons:")) {
            textBeforeBut = assres.split("Buttons:")[0];
          } else {
            textBeforeBut = assres;
          }
          setText(textBeforeBut);
          let userMessage;
          let newContext;
          if (response !== "") {
            userMessage = { text: response, user: true };
            newContext = [...ncontext, { role: "assistant", content: assres }];
          } else {
            userMessage = { text: demo, user: true };
            newContext = [
              ...ncontext,
              // { role: "user", content: demo },
              { role: "assistant", content: assres },
            ];
          }
          const aiResponse = { text: textBeforeBut, user: false };
          if (demo.includes("New") || demo.includes("Exit")) {
            setmessagesa([userMessage, aiResponse]);
            // speak({ text: aiResponse.text, voice: voices[4] });
          } else if (demo === "") {
            setmessagesa([...messagesa, aiResponse]);
            // speak({ text: aiResponse.text, voice: voices[4] });
          } else {
            setmessagesa([...messagesa, userMessage, aiResponse]);
            // speak({ text: aiResponse.text, voice: voices[4] });
          }
          setContext(newContext);
          if (assres.includes("Buttons")) {
            var buttonN = extractButtonNames(assres);
            SetbuttonNames(buttonN);
          
          }
          setResponse("");
          if (!threadApiCalled) {
            const obj = { question: demo, email: loggedInUserEmail };
            const add_thread = await axios.post(
              `${ACS_API}send_thread`,
              obj
            );
            thread_id = add_thread.data.new_row.Id;
            setThreadApiCalled(true);
            const response = await axios.get(
              `${ACS_API}get_all_threads/${loggedInUserEmail}`
            );
            categorizeThreads(response.data);
          }
          if (!(demo === "Start New Conversation")) {
            send_msg_func(demo, textBeforeBut,thread_id);
          }
        }
      } catch (error) {
        console.error("Error fetching AI response", error);
      }
    } else {
      let userMessage = { text: "", user: true };
      let aiResponse = {
        text: "You have reached your daily question limit",
        user: false,
      };
      setmessagesa([userMessage, aiResponse]);
      // speak({ text: aiResponse.text , voice: voices[4]});
    }
  };

  useEffect(() => {
    // Scroll to the last message when messagesa change
    if (messagesa.length > 0 && lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messagesa]);

  const initialquestionCount = async () => {
    let bodyc = { user_email: loggedInUserEmail };
    const resdata = await axios.post(
      `${OPENAI_API_NEW}get_initial_count_limit`,
      bodyc
    );
    qcount = resdata.data.question_count;
    // qcount = 1
    limit = resdata.data.limit;
    // limit = 1000
    setCount(qcount);
  };
  const questionCount = async () => {
    let bodyc = { user_email: loggedInUserEmail };
    const resdata = await axios.post(
      `${OPENAI_API_NEW}ask`,
      bodyc
    );
    qcount = resdata.data.count;
    setCount(qcount);
  };

  const handleSendMessage = async () => {
    if (response.trim() !== "") {
      setIsMicrophoneOn(false)
      let prompt = response;
      demo = response;
      setResponse("");
      if (!isotherQue) {
        SetbuttonNames([]);
      }
      SetDropdownNames([]);
      setShowDateTimePicker(false);
      let nContext: { role: string; content: any }[] = [];
      if (!isfaqbutton && !isexpobutton && !isappobutton && ((prompt.toLowerCase().includes("expert opinion") && prompt.toLowerCase().includes("physician")) || 
                  prompt.toLowerCase().includes("expert opinion from physician") || 
                  prompt.toLowerCase().includes("send email")  || 
                  prompt.toLowerCase().includes("specialty") || 
                  prompt.toLowerCase().includes("subspecialty") ||
                  prompt.toLowerCase().includes("sub specialty"))
                ) {
       
        setClickedButton("Expert opinion");
        setIsButtonDisabled(false);
        if (!isDirectexpobutton) {
        SetisDirectexpobutton(true);
        nContext = [
          { role: "system", content: ExpertOpinionDirectQuestionCOntext },
          { role: "user", content: prompt },
        ];
        }
        else {
        nContext = [...context, { role: "user", content: prompt }];
        }
        Setisexpobutton(false);
        Setisfaqbutton(false);
        setIsGeneralSearch(false);
        Setisappobutton(false);
      }else if (!isfaqbutton && !isDirectexpobutton && prompt.toLowerCase().includes("expert opinion")) {
        setClickedButton("Expert opinion");
        setIsButtonDisabled(false);
        if (!isexpobutton) {
          Setisexpobutton(true);
          nContext = [
            { role: "system", content: ExpertOpinionCOntext },
            { role: "user", content: prompt },
          ];
        } else {
          nContext = [...context, { role: "user", content: prompt }];
        }
        SetisDirectexpobutton(false);
        Setisfaqbutton(false);
        setIsGeneralSearch(false);
        Setisappobutton(false);
      }else if (prompt.toLowerCase().includes("faq")) {
        setClickedButton("FAQ");
        setIsButtonDisabled(false);
        nContext = [
          { role: "system", content: FAQ_Document },
          { role: "user", content: prompt },
        ];
        Setisfaqbutton(true);
        // SetbuttonNames([]);
        setIsGeneralSearch(false);
        Setisappobutton(false);
        Setisexpobutton(false);
        SetisDirectexpobutton(false);
      }else if (prompt.toLowerCase().includes("appointment") && !isexpobutton) {
        setClickedButton("Appointment request");
        setIsButtonDisabled(false);
        if (!isappobutton) {
          Setisappobutton(true);
          nContext = [
            { role: "system", content: AppointmentContext },
            { role: "user", content: prompt },
          ];
        } else {
          nContext = [...context, { role: "user", content: prompt }];
        }
        // SetbuttonNames([]);
        // Setisappobutton(true)
        Setisfaqbutton(false);
        Setisexpobutton(false);
        SetisDirectexpobutton(false);
        setIsGeneralSearch(false);
      }else if (
        prompt.toLowerCase().includes("upload documents") ||
        prompt.toLowerCase().includes("upload my documents")
      ) {
        setClickedButton("Upload Documents");
        setIsButtonDisabled(false);
        nContext = [
          { role: "system", content: UploadMyDocumentsContext },
          { role: "user", content: prompt },
        ];
        // SetbuttonNames([]);
        Setisexpobutton(false);
        SetisDirectexpobutton(false);
        Setisappobutton(false);
        Setisfaqbutton(false);
        setIsGeneralSearch(false);
      } else if (
        prompt.toLowerCase().includes("Top 5 Documents") ||
        prompt.toLowerCase().includes("see my documents") ||
        prompt.toLowerCase().includes("my documents")
      ) {
        setClickedButton("Top 5 Documents");
        setIsButtonDisabled(false);
        // nContext = [
        //   { role: "system", content: ViewMyDocumentsContext },
        //   { role: "user", content: prompt },
        // ];
        SetbuttonNames(['Compare', 'Summarize','Other Question', 'Reset'])
        let aiResponse = { text: html_table, user: false };
        let userMessage = { text: prompt, user: true };
        setmessagesa([...messagesa, userMessage, aiResponse]);
      
        // SetbuttonNames([]);
        Setisexpobutton(false);
        SetisDirectexpobutton(false);
        Setisappobutton(false);
        Setisfaqbutton(false);
        setIsGeneralSearch(false);
      } else if (prompt.toLowerCase().includes("exit chat")) {
        nContext = [
          { role: "system", content: newChat },
          { role: "user", content: "New Chat" },
        ];
        // SetbuttonNames([]);
        Setisfaqbutton(false);
        Setisappobutton(false);
        Setisexpobutton(false);
        SetisDirectexpobutton(false);
        SetDropdownNames([]);
        setIsGeneralSearch(false);
        setClickedButton("");
      } else {
        nContext = [...context, { role: "user", content: prompt }];
      }
      if(
      !prompt.toLowerCase().includes("Top 5 Documents") &&
      !prompt.toLowerCase().includes("see my documents") &&
      !prompt.toLowerCase().includes("my documents")
      ){
      // const nContext = [...context, { role: "user", content: prompt }];
      setContext(nContext);
      const userMessage = { text: prompt, user: true };
      let aiResponse = { text: "Please wait...", user: false };
      // setmessagesa([...messagesa, userMessage, aiResponse]);
      setmessagesa((prevmessagesa) => [
        ...prevmessagesa,
        userMessage,
        aiResponse,
      ]);
      // speak({ text: aiResponse.text ,voice: voices[4]});

      if (isfaqbutton && !prompt.includes("thanks")) {
        try {
          const body = {
            question: response,
            loggedInUserRoleId: loggedInUserRoleId
          };
          try {
            const res = await axios.post(
              // "http://127.0.0.1:5000/faq_search",
              `${OPENAI_API_NEW}faq_search`,
              body
            );
            let ans = res.data.answer;
            setHelpDocument(ans);
            aiResponse = { text: ans, user: false };
            // setmessagesa([...messagesa, userMessage, aiResponse]);
            setmessagesa((prevmessagesa) => {
              // Remove the last "loading..." message and add the new answer.
              const updatedmessagesa = prevmessagesa.slice(0, -1);
              return [...updatedmessagesa, aiResponse];
            });
            // speak({ text: aiResponse.text,voice: voices[4] });
            send_msg_func(response, ans,thread_id);
            questionCount();
          } catch (error) {
            if (error.response && error.response.status === 500) {
              setmessagesa((prevmessagesa) => {
                aiResponse = {
                  text: "Data not available in FAQ, please ask another question",
                  user: false,
                };
                const updatedmessagesa = prevmessagesa.slice(0, -1);
                return [...updatedmessagesa, aiResponse];
              });
              // speak({ text: aiResponse.text,voice: voices[4] });
            }
          }
        } catch (err) {
          console.error("Error:", err);
        }
      } else if (isotherQue && !prompt.includes("thanks")) {
        // const r1 = await report(prompt, pdfUrl);
        let r1;
        if (pdfUrl.length > 0) {
          r1 = await report(prompt, pdfUrl);
        }
        if (imageUrl.length > 0) {
          r1 = await report(prompt, imageUrl);
        }
        if(videoUrl.length > 0){
          r1 = await report(prompt, videoUrl);
        }        
        aiResponse = { text: r1, user: false };
        let userMessage = { text: prompt, user: true };
        setmessagesa([...messagesa, userMessage, aiResponse]);
        // speak({ text: aiResponse.text, voice: voices[4] });
        send_msg_func(demo, r1, thread_id);
        questionCount();
      } else if (
        isGeneralSearch &&
        !prompt.includes("Hi") &&
        !prompt.includes("exit chat") &&
        prompt.includes("?")
      ) {
        SetbuttonNames([]);
        try {
          const body = {
            prompt: prompt,
            loggedInUserId: loggedInUserId,
            baseURL: baseURL,
            user_id: loggedInUserId
          };
          const res = await axios.post(
            `${OPENAI_API_NEW}gen_search`,
            // "http://127.0.0.1:5000/gen_search",
            body
          );
          // const { data } = res.data.answer; // Use res.data directly
          let ans =
            res.data.answer +
            "<br> You  are in General search mode, if you want to exit type 'exit chat'";
          // setHelpDocument(ans);
          // aiResponse = { text: ans, user: false };
          // setmessagesa([...messagesa, userMessage, aiResponse]);
          setmessagesa((prevmessagesa) => {
            // Remove the last "loading..." message and add the new answer.
            const updatedmessagesa = prevmessagesa.slice(0, -1);
            return [...updatedmessagesa, { text: ans, user: false }];
          });

          // speak({ text: ans, voice: voices[4] });
          questionCount();
        } catch (err) {
          console.error("Error:", err);
        }
      } else {
        // setResponse("");
        Setisfaqbutton(false);
        setLoadingResponse(true); // Show loading window
        try {
          await getCompletionFrommessagesa(nContext); // Wait for completion
        } catch (error) {
          console.error("Error sending message", error);
        }
      }
    }
    }
  };

  function handleExitChat(event) {
    event.preventDefault();
    if (!isButtonDisabled) {
      // Show the popup
      SweetAlertHelper.showConfirm(
        "Would you like to exit from the current chat context and initiate a new conversation?",
        () => {
          specialistName = "";
          setSelectedFile(null);
          setFilename(null);
          SetPdfUrl([])
          setIsButtonDisabled((prevState) => !prevState);
          setClickedButton("");
          setmessagesa([]);
          SetbuttonNames([]);
          SetDropdownNames([]);
          setShowDateTimePicker(false);
          SetShowFileUpload(false);
          setThreadButtonClicked(false);
          Setisexpobutton(false);
          SetisDirectexpobutton(false);
          Setisfaqbutton(false);
          Setisappobutton(false);
          setIsGeneralSearch(false);
          SetisOtherQue(false);
          // resetAllData()

          demo = "Start New Conversation";
          let nContext = [
            { role: "system", content: newChat },
            { role: "user", content: "New Chat" },
          ];
          setContext(initialContext);
          setThreadApiCalled(false);

          // Create messages for user action and AI response
          const userMessage = {
            text: "Start New Conversation",
            user: true,
          };
          const aiResponse = {
            text: "please wait...",
            user: false,
          };
          setmessagesa([userMessage, aiResponse]);
          // speak({ text: aiResponse.text, voice: voices[4] });
          getCompletionFrommessagesa(nContext); // Reset clicked button state
        }
      );
    }
  }

  useEffect(() => {
  }, [setClickedButton]); // This useEffect runs whenever isButtonsDisabled changes

  function handleStaticButton(buttonName) {
    setActiveClicked(true);
    setClickedButton(buttonName);
    setIsButtonDisabled(false);
    let nContext;
    demo = buttonName;
    if (buttonName === "Expert opinion") {
      nContext = [
        { role: "system", content: ExpertOpinionCOntext },
        { role: "user", content: "Expert opinion" },
      ];
      Setisexpobutton(true);
      SetisDirectexpobutton(false);
      Setisappobutton(false);
      Setisfaqbutton(false);
    } else if (buttonName === "Upload Documents") {
      nContext = [
        { role: "system", content: UploadMyDocumentsContext },
        { role: "user", content: " Upload Documents" },
      ];
      Setisexpobutton(false);
      SetisDirectexpobutton(false);
      Setisfaqbutton(false);
      Setisappobutton(false);
    }else if (buttonName === "Top 5 Documents") {
      // nContext = [
      //   { role: "system", content: ViewMyDocumentsContext },
      //   { role: "user", content: " Top 5 Documents" },
      // ];
      SetbuttonNames(['Compare', 'Summarize', 'Other Question', 'Reset'])
      let aiResponse = { text: html_table, user: false };
      let userMessage = { text: buttonName, user: true };
      setmessagesa([...messagesa, userMessage, aiResponse]);
                        // speak({ text: aiResponse.text,voice: voices[4] });
      // send_msg_func(demo, html_table,thread_id);
      // SetbuttonNames(['Compare', 'Summarize', 'Anatomical Abnormalities', 'Other Question', 'Reset'])
      Setisexpobutton(false);
      SetisDirectexpobutton(false);
      Setisfaqbutton(false);
      Setisappobutton(false);
    } else if (buttonName === "FAQ") {
      nContext = [
        { role: "system", content: FAQ_Document },
        { role: "user", content: "FAQ" },
      ];
      Setisexpobutton(false);
      SetisDirectexpobutton(false);
      Setisfaqbutton(true);
      Setisappobutton(false); // Assuming this is a function to set a state or context for FAQ
    }else if (buttonName === "Appointment request") {
      nContext = [
        { role: "system", content: AppointmentContext },
        { role: "user", content: "Appointment request" },
      ];
      Setisexpobutton(false);
      SetisDirectexpobutton(false);
      Setisappobutton(true);
      Setisfaqbutton(false);
    }   
    if (buttonName !== "Top 5 Documents"){
    setContext(nContext);
    const userMessage = {
      text: buttonName,
      user: true,
    };
    const aiResponse = {
      text: "please wait...",
      user: false,
    };
    setmessagesa([...messagesa, userMessage, aiResponse]);
    // speak({ text: aiResponse.text, voice: voices[4] });
    getCompletionFrommessagesa(nContext);
    SetbuttonNames([]);
    }
  }

  useEffect(() => {
    // Scroll to the bottom when the component is initially rendered
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }, []);

  useEffect(() => {
    // Scroll to the last message when messages change
    if (messagesa.length > 0 && lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messagesa]);
 

  return (
    <div>{isLoading && <div className="page-loader"><img src="../../assets/images/ajax-loader.gif" /></div>}
    <div className="chat-container" ref={chatContainerRef}>
      <div
        className="nav-bar"
        style={{ display: isSidebarOpen ? "block" : "none" }}
      >
        <div>
          <h5 style={{ marginLeft: "11px" }}>
            {" "}
            <strong style={{ color: "blcak" }}>History</strong>{" "}
          </h5>
        </div>

        <button
          className={`exist-button ${isButtonDisabled ? "disabled-class" : "enabled-class"
            }`}
          style={{
            marginLeft: "11px",
            marginRight: "11px",
            width: "296px",
            padding: "7px",
          }}
          // className="send-button"
          onClick={handleExitChat}
          disabled={count >= limit && isButtonDisabled}
          title="Start new Conversation"
        >
          Start new Conversation
        </button>

        <ul className="parent-nav-list">
          {todayThread.length > 0 && (
            <li>
              <strong style={{ color: "blcak" }}>Today</strong>
              {todayThread.map((thread, index) => (
                <ul className="nav-list" key={index}>
                  <button
                    className={`thread_btn ${activeThread === index ? "active-button" : ""
                      }`}
                    onClick={() => loadThread(thread, index)}
                  >
                    {hasMessages(thread.name) && (
                      <FontAwesomeIcon
                        icon={faMessage}
                        className="message-icon"
                      />
                    )}

                    {thread.name}
                  </button>
                </ul>
              ))}
            </li>
          )}
           {last7DaysThreads.length > 0 && (
            <li>
              <strong style={{ color: "black" }}>Last 7 Days</strong>
              {last7DaysThreads.map((thread, index) => (
                <ul className="nav-list" key={index}>
                  <button
                    className="thread_btn"
                    onClick={() => loadThread(thread, index)}
                  >
                    {hasMessages(thread.name) && (
                      <FontAwesomeIcon
                        icon={faMessage}
                        className="message-icon"
                      />
                    )}
                    {thread.name}
                  </button>
                </ul>
              ))}
            </li>
          )}
          {olderThread.length > 0 && (
            <li>
              <strong style={{ color: "black" }}>Older Chats</strong>
              {olderThread.map((thread, index) => (
                <ul className="nav-list" key={index}>
                  <button
                    className="thread_btn"
                    onClick={() => loadThread(thread, index)}
                  >
                    {hasMessages(thread.name) && (
                      <FontAwesomeIcon
                        icon={faMessage}
                        className="message-icon"
                      />
                    )}
                    {thread.name}
                  </button>
                </ul>
              ))}
            </li>
          )}
        </ul>
      </div>
      <div className="chat-col lg-10">
        <div className="count-container bold-text">
          {count} of {limit}
        </div>
        {count >= limit && (
          <div className="count-container bold-text" style={{ color: "red" }}>
            You have reached your daily question limit
          </div>
        )}
        <div className="message-container">
          {messagesa.map((message, index) => (
            <div
              key={index}
              style={{
                alignSelf: message.user ? "flex-end" : "flex-start",
                backgroundColor: message.user ? "#e1ffc7" : "#f0f0f0",
                borderRadius: "10px",
                padding: "8px 12px",
                marginBottom: "5px",
                maxWidth: "80%",
              }}
            >
              {message.user ? (
                message.text
              ) : (
                <>
                  {typeof message.text === "object" ? (
                    JSON.stringify(message.text) // Convert the object to a string
                  ) : (
                    <>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <FontAwesomeIcon
                          icon={faClipboard}
                          onClick={() =>
                            handleCopyToClipboard(message.text, index)
                          }
                          style={{ marginLeft: "5px", cursor: "pointer" }}
                          color={copiedIndex === index ? "green" : "#1c84c6"}
                          title={
                            copiedIndex === index ? "Copied!" : "Copy Text"
                          }
                        />
                        <FontAwesomeIcon
                          key={index}
                          icon={audioIndex === index && speaking && !isPaused ? faPause : faVolumeUp}
                          onClick={handleVolumeIconClick(message.text, index)}
                          style={{ marginLeft: '5px', cursor: 'pointer', color: '#1c84c6' }}
                          title={audioIndex === index && speaking && !isPaused ? "Pause" : "Listen"}
                        />
                      </div>
                      {message.text.includes("<table") ? (
                        <div
                          dangerouslySetInnerHTML={{ __html: message.text }}
                        />
                      ) : (
                        message.text.split("\n").map((line, index) => (
                          <React.Fragment key={index}>
                            <div dangerouslySetInnerHTML={{ __html: line }} />
                          </React.Fragment>
                        ))
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          ))}
          {showDateTimePicker && (
            <>
              <DatePicker
                selected={selectedDateTime}
                onChange={(date) => updateUI(date)}
                showTimeSelect
                timeFormat="HH:mm:ss"
                minDate={new Date()} 
                dateFormat="yyyy-MM-dd HH:mm:ss"
                placeholderText="Choose date and time"
              />
            </>
          )}
          {showFileUpload && (
            <div>
              <input type="file" onChange={handleFileSelect} />
              {selectedFile && <p>Selected File: {filename}</p>}
            </div>
          )}
          {dropdownNames.length > 0 && (
            <div>
              <select onChange={handleDropdownChange}>
                <option value="">Select an option</option>
                {dropdownNames.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          )}

          {(textBeforeButtons !== ""|| html_table !== "")  && (
            <div>
              <div style={{ display: "flex", gap: "10px" }}>
                {buttonNames.map((buttonName, index) => (
                  <button
                    className="send-button"
                    key={index}
                    onClick={async () => {
                      demo = buttonName;
                      setButtonClicked(buttonName);
                      let nContext;
                      if (buttonName === "Expert opinion") {
                        nContext = [
                          { role: "system", content: ExpertOpinionCOntext },
                          { role: "user", content: buttonName },
                        ];
                      }
                      else if (buttonName === "Appointment request") {
                        nContext = [
                          { role: "system", content: AppointmentContext },
                          { role: "user", content: buttonName },
                        ];
                      } else if (buttonName === "Upload Documents") {
                        nContext = [
                          { role: "system", content: UploadMyDocumentsContext },
                          { role: "user", content: buttonName },
                        ];
                      }else if (buttonName === "Top 5 Documents") {
                        nContext = [
                          { role: "system", content: ViewMyDocumentsContext },
                          { role: "user", content: buttonName },
                        ];
                      } else if (buttonName === "FAQ") {
                        nContext = [
                          { role: "system", content: FAQ_Document },
                          { role: "user", content: buttonName },
                        ];
                        Setisfaqbutton(true);
                      }else if (buttonName.includes("New")) {
                        nContext = [
                          { role: "system", content: newChat },
                          { role: "user", content: buttonName },
                        ];
                      } else if (buttonName.includes("Compare")) {
                        setIsLoading(true);
                        let aiResponse = {
                          text: "Please wait, I am processing reports...",
                          user: false,
                        };
                        let userMessage = { text: buttonName, user: true };
                        setmessagesa([...messagesa, userMessage, aiResponse]);
                        // speak({ text: aiResponse.text });
                        let r1;
                        if (pdfUrl.length === 2) {
                          r1 = await report("Compare the reports", pdfUrl);
                        }else{
                          r1 = "Please select two pdf reports to compare. \n"
                        }
                        if (imageUrl.length > 0) {
                          // r1 = await report("For the the given image if it is MRI/CT scan or  X-ray, provide an analysis from radiologist perspective and give a analysis if any diagnosis is required or not, Display the information as HTML paragraph only using <b>, only <h6> and <ul> for bullet points", imageUrl);
                          r1 = "Please select two pdf reports to compare, I am unable to compare images. \n"
                        }
                        if (videoUrl.length > 0) {
                          // r1 = await report("For the the given image if it is MRI/CT scan or  X-ray, provide an analysis from radiologist perspective and give a analysis if any diagnosis is required or not, Display the information as HTML paragraph only using <b>, only <h6> and <ul> for bullet points", imageUrl);
                          r1 = "Please select two pdf reports to compare, I am unable to compare videos. \n"
                        }
                        let aire =
                          r1 +
                          "\n\nIf you have any question on this report please enter the question or else click on Reset button to choose other reports.";
                        aiResponse = {
                          text: aire,
                          user: false,
                        };
                        userMessage = { text: buttonName, user: true };
                        setmessagesa([...messagesa, userMessage, aiResponse]);
                        setIsLoading(false);
                        // speak({ text: aiResponse.text, voice: voices[4] });
                        // SetPdfUrl([])
                        SetisOtherQue(true);
                        SetisVideoQue(false);
                        send_msg_func(demo, aire,thread_id);
                        questionCount();
                      } else if (buttonName.includes("Summarize")) {
                       
                        setIsLoading(true);
                        console.log("isloading", isLoading)
                        let aiResponse = {
                          text: "Please wait, I am processing reports...",
                          user: false,
                        };
                        let userMessage = { text: buttonName, user: true };
                        setmessagesa([...messagesa, userMessage, aiResponse]);
                        // speak({ text: aiResponse.text,voice: voices[4] });
                        // const r1 = await report("Summarize the report", pdfUrl);
                        let r1;
                        if (pdfUrl.length > 0) {
                          if (pdfUrl.length !== 1){
                            r1 = "Please select only one document to summarize!"
                          }else{
                            r1 = await report("Summarize the report", pdfUrl);
                            SetisOtherQue(true);
                          }
                        }
                        if (imageUrl.length > 0) {
                          if (imageUrl.length !== 1){
                            r1 = "Please select only one document to summarize!"
                          }else{
                            r1 = await report("For the the given image if it is MRI/CT scan or  X-ray, provide an analysis from radiologist perspective and give a analysis if any diagnosis is required or not, Display the information as HTML paragraph only using <b>, only <h6> and <ul> for bullet points", imageUrl);
                            SetisOtherQue(true);
                          }
                        }
                        if(videoUrl.length > 0){
                          console.log("video url selected ");
                          r1 = await report("if it is MRI/CT scan or X-ray, provide an analysis from radiologist perspective and give a analysis if any diagnosis is required or not, Display the information as HTML paragraph only using <b>, only <h6> and <ul> for bullet points", videoUrl);
                          console.log("r1 of video", r1)
                          SetisOtherQue(true);
                        }
                        let aire =
                          r1 +
                          "\n\nIf you have any question on this report please enter the question or else click on Reset button to choose other reports.";
                        aiResponse = { text: aire, user: false };
                        userMessage = { text: buttonName, user: true };
                        setmessagesa([...messagesa, userMessage, aiResponse]);
                        setIsLoading(false);                        
                        // speak({ text: aiResponse.text, voice: voices[4] });
                        // SetPdfUrl([])
                        // SetisOtherQue(true);
                        send_msg_func(demo, aire, thread_id);
                        questionCount();
                      } else if (buttonName.includes("Other Question")) {
                        let aiResponse = {
                          text: "Please ask your question regarding this report",
                          user: false,
                        };
                        let userMessage = { text: buttonName, user: true };
                        setmessagesa([...messagesa, userMessage, aiResponse]);
                        // speak({ text: aiResponse.text, voice: voices[4] });
                        SetisOtherQue(true);
                        SetisVideoQue(false);
                        questionCount();
                      } else if (buttonName.includes("Reset")) {
                        let aiResponse = { text: checkboxtable, user: false };
                        let userMessage = { text: buttonName, user: true };
                        setmessagesa([...messagesa, userMessage, aiResponse]);
                        // speak({ text: aiResponse.text,voice: voices[4] });
                        send_msg_func(demo, checkboxtable,thread_id);
                        SetPdfUrl([]);
                        SetImageUrl([]);
                        setVideoUrl([]);
                      } 
                      else if (buttonName === "Upload") {
                        let aiResponse = {
                          text: "Please wait...",
                          user: false,
                        };
                        let userMessage = { text: buttonName, user: true };
                        setmessagesa([...messagesa, userMessage, aiResponse]);
                        // speak({ text: aiResponse.text ,voice: voices[4]});
                        nContext = [
                          ...context,
                          { role: "user", content: buttonName },
                        ];
                        let uploaded_url = await uploadFile(9);
                        setUploadedUrl(uploaded_url);
                      }
                      else if (buttonName === "Yes, analyze") {
                        setIsLoading(true);
                        let aiResponse = {
                          text: "Please wait, I am processing document...",
                          user: false,
                        };
                        SetbuttonNames([]);
                        let userMessage = { text: buttonName, user: true };
                        setmessagesa([...messagesa, userMessage, aiResponse]);
                        // speak({ text: aiResponse.text ,voice: voices[4]});
                        
                        // let res_analyse = await uploadAndAnalyzeImage(uploadedUrl);
                        let res_analyse = await report("Provide an detailed summary and give a analysis if any diagnosis is required or not, Display the information as HTML paragraph only using <b>, only <h6> and <ul> for bullet points ", uploadedUrl);
                        res_analyse += "\nDo you need any other help?";          
                        aiResponse = {
                         text: res_analyse,
                         user: false,
                        };
                        userMessage = { text: demo, user: true };
                        setmessagesa([...messagesa, userMessage, aiResponse]);
                        setIsLoading(false);
                        // speak({ text: aiResponse.text ,voice: voices[4]}); 
                        send_msg_func(demo, res_analyse, thread_id);                        
                        setIsButtonDisabled((prevState) => !prevState);
                        setClickedButton("");                       
                        setThreadButtonClicked(false);
                        Setisexpobutton(false);
                        SetisDirectexpobutton(false);
                        Setisfaqbutton(false);
                        Setisappobutton(false);
                        setIsGeneralSearch(false);
                        setContext(initialContext);
                      }
                      else if (buttonName === "Yes, upload") {
                        nContext = [
                          ...context,
                          { role: "user", content: buttonName },
                        ];
                        let res_hipaa = await uploadFile(8);                        
                      }
                      
                      else {
                        nContext = [
                          ...context,
                          { role: "user", content: buttonName },
                        ];
                      }

                      if (
                        buttonName !== "Compare" &&
                        buttonName !== "Summarize" &&
                        buttonName !== "Other Question" &&
                        buttonName !== "Reset" &&
                        buttonName !== "Yes, analyze"
                      ) {
                        setContext(nContext);
                        const userMessage = {
                          text: buttonName,
                          user: true,
                        };
                        const aiResponse = {
                          text: "please wait...",
                          user: false,
                        };
                        if (buttonName.includes("New")) {
                          setmessagesa([userMessage, aiResponse]);
                          // speak({ text: aiResponse.text, voice: voices[4] });
                        } else {
                          setmessagesa([...messagesa, userMessage, aiResponse]);
                          // speak({ text: aiResponse.text, voice: voices[4] });
                        }

                        getCompletionFrommessagesa(nContext);
                        SetbuttonNames([]);
                      }
                    }}
                  >
                    {buttonName}
                  </button>
                ))}
              </div>
            </div>
          )}
          <div ref={lastMessageRef}></div>

          <button onClick={toggleSidebar} className="toggle-button">
            <FontAwesomeIcon
              icon={isSidebarOpen ? faChevronLeft : faChevronRight}
            />
            <span className="tooltip-text">
              {isSidebarOpen ? "Close Sidebar" : "Open Sidebar"}
            </span>
          </button>
        </div>
        <div
          className="buttoArray"
          style={{ display: `${threadButtonClicked ? "none" : ""}` }}
        >
          <button
            className= {`send-button ${activeclicked && clickedButton === "Expert opinion" ? "green-class" : '' }`}
            onClick={() => handleStaticButton("Expert opinion")}
            disabled={
              count >= limit ||
              (clickedButton !== "" && clickedButton !== "Expert opinion")
            }
          title= "Ask Expert opinion"
          >
            Ask Expert opinion
          </button>

          <button
            className= {`send-button ${activeclicked && clickedButton === "Upload Documents" ? "green-class" : '' }`}
            onClick={() => handleStaticButton("Upload Documents")}
            disabled={
              count >= limit ||
              (clickedButton !== "" && clickedButton !== "Upload Documents")
            }
            title="Upload Documents"
          >
           
            Upload Documents
          </button>
          <button
            className= {`send-button ${activeclicked && clickedButton === "Top 5 Documents" ? "green-class" : '' }`}
            onClick={() => handleStaticButton("Top 5 Documents")}
            disabled={
              count >= limit ||
              (clickedButton !== "" && clickedButton !== "Top 5 Documents")
            }
            title="Top 5 Documents"
          >
            Top 5 Documents
          </button>

          <button
            className= {`send-button ${activeclicked && clickedButton === "FAQ" ? "green-class" : '' }`}
            onClick={() => handleStaticButton("FAQ")}
            disabled={
              count >= limit ||
              (clickedButton !== "" && clickedButton !== "FAQ")
            }
            title="FAQ"
          >
            FAQ
          </button>
          {/* <button
            className= {`send-button ${activeclicked && clickedButton === "Appointment request" ? "green-class" : '' }`}
            onClick={() => handleStaticButton("Appointment request")}
            disabled={
              count >= limit ||
              (clickedButton !== "" && clickedButton !== "Appointment request")
            }
            title="Appointment request"
          >
            Appointment request
          </button> */}
          

          <button
            className={`exist-button ${isButtonDisabled ? "disabled-class" : "enabled-class"
              }`}
            // className="send-button"
            onClick={handleExitChat}
            disabled={count >= limit && isButtonDisabled}
            title="Start new Conversation"
          >
            {/* <span className="tooltip-text-btn">Start new Conversation</span> */}
            Start new Conversation
          </button>
        </div>

        <div className="chat-input-container">
          <div className="input-with-mic">
            <input
              type="text"
              placeholder="Type your message..."
              value={response}
              onChange={(e) => setResponse(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage();
                }
              }}
              className="chat-input"
              disabled={count >= limit || threadButtonClicked}
            />
            <button
              onClick={startSpeechRecognition}
              className="mic-button"
              title="Mic"
            >
              {isMicrophoneOn ? (
                <FontAwesomeIcon icon={faMicrophone} style={{ fontSize: '1.5em', color: '#1c84c6' }} />
              ) : (
                <FontAwesomeIcon icon={faMicrophoneSlash} style={{ fontSize: '1.5em', color: '#1c84c6' }} />
              )}

            </button>
          </div>
          <button
            onClick={handleSendMessage}
            className="send-button"
            disabled={count >= limit || threadButtonClicked}
            title="Send"
          >
            Send
          </button>

          <button
            className="refresh"
            type="submit"
            onClick={resetAllData}
            disabled={count >= limit}
          title="Reset"
          >
            <FiRefreshCw
              size={20}
              style={{ marginRight: "5px", color: "#1c84c6" }}
            />
          </button>
        </div>
        <div className="ssttag">
          <img
            src="../../assets/images/SST Logo1.jpg"
            alt="SSTAI"
            className="logo"
          ></img>
          <span style={{ opacity: 1 }}>SSTAI IP</span>
        </div>
      </div>
    </div>
    </div>
  );
};

export default AHSChatBot;

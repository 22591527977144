import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpRequest, HttpHeaders, HttpEvent, HttpErrorResponse, HttpEventType} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {map, retry, catchError} from 'rxjs/operators';
import {environment} from './../../environments/environment';
import {ApiHelper} from './../_helpers/api.helper';
import {CommonHelper} from './../_helpers/common.helper';
import {ConstantHelper} from './../_helpers/constant.helper';
import {AppConfig} from './../../app/app.config';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  baseUrl01 = this.appConfig.getLambdaUrl01();
  baseUrl02 = this.appConfig.getLambdaUrl02();
  baseUrl03 = this.appConfig.getLambdaUrl03();
  nameArr: any;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(
    private http: HttpClient,
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
  ) {
  }

  findHippaByStateCode(stateDoce) {
    const finalData = {stateCode: stateDoce};
    return this.http.post(this.baseUrl03 + ApiHelper.name_getStateHippaApi,
      finalData,
      this.httpOptions).pipe(
      retry(1),
      catchError(this.errorHandl)
    );
  }

  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  getUS_FromatName(nameString) {
    if (nameString) {
      let tempFN = nameString.replace(/undefined /g, '');
      let tempLS = nameString.replace(/undefined /g, '');
      const lastIndex = tempFN.lastIndexOf(' ');
      tempFN = tempFN.substring(0, lastIndex);
      const n = tempLS.replace(/[\[\]?.,\/#!$%\^&\*;:{}=\\|_~()]/g, '').split(' ');
      tempLS = n[n.length - 1];
      
       // Capitalize the first letter of each part of the name
      tempFN = tempFN.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
      tempLS = tempLS.charAt(0).toUpperCase() + tempLS.slice(1).toLowerCase();
      
      const finalName = tempLS + ', ' + tempFN;
      return finalName.replace(/^\s+|\s+$/g, '');
    }
  }

  getRefromatName(nameString) {
    if (nameString) {
      if (nameString.lastIndexOf(',') === -1) {
        return nameString.replace(/^\s+|\s+$/g, '');
      } else {
        const nameArr = nameString.split(',');
        const finalName = nameArr[1] + ' ' + nameArr[0];
        return finalName.replace(/^\s+|\s+$/g, '');
      }
    } else {
      return '';
    }

  }

  autoFormatSSN(string) {
    let val = string.replace(/\D/g, '');
    val = val.replace(/^(\d{3})/, '$1-');
    val = val.replace(/-(\d{2})/, '-$1-');
    val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
    return val;
  }

  getFirstName(nameString) {
    if (nameString) {
      let tempFN = nameString.replace(/undefined /g, '');
      let tempLS = nameString.replace(/undefined /g, '');
      const lastIndex = tempFN.lastIndexOf(' ');
      tempFN = tempFN.substring(0, lastIndex);
      const n = tempLS.replace(/[\[\]?.,\/#!$%\^&\*;:{}=\\|_~()]/g, '').split(' ');
      tempLS = n[n.length - 1];
      return tempFN.replace(/^\s+|\s+$/g, '');

    }

  }

  getLastName(nameString) {
    if (nameString) {
      let tempFN = nameString.replace(/undefined /g, '');
      let tempLS = nameString.replace(/undefined /g, '');
      const lastIndex = tempFN.lastIndexOf(' ');
      tempFN = tempFN.substring(0, lastIndex);
      const n = tempLS.replace(/[\[\]?.,\/#!$%\^&\*;:{}=\\|_~()]/g, '').split(' ');
      tempLS = n[n.length - 1];
      return tempLS.replace(/^\s+|\s+$/g, '');
    }
  }

  dynamicSort(property) {
    let sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function(a, b) {
      if (sortOrder === -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    };
  }


  checkWorkingEmail(email) {
    if (email === 'support@actualhs.com' || email === 'physician@actualhs.com') {
      return '';
    } else {
      return email.substring(0, 4) + '****@***.***';
    }
  }

  childSiteUrl(companySlug) {
    if (environment.environmentName === ConstantHelper.ENV_NAME.LOCAL) {
      return 'http://localhost:4300';
    } else if (environment.environmentName === ConstantHelper.ENV_NAME.PRODUCTION) {
      return 'https://' + 'app' + companySlug + '.' + ConstantHelper.MAIN_SITE;
      // return 'http://localhost:4300';
    } else {
      return 'https://' + environment.environmentName + companySlug + '.' + ConstantHelper.MAIN_SITE;
    }
  }

   capitalizeFirstLetter(text: string): string {
    if (!text) return text; // Return the original text if it's empty or undefined
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  capitalizeEachFirstLetter(text: string): string {
    if (!text) return text; // Return the original text if it's empty or undefined
    return text
      .split(/[\s,]+/) // Split the string into an array of words using space or comma as the separator
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
      .join(' '); // Join the array back into a string with spaces in between
  }
  
  
  
}

import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';

import { AppConfig } from './../../../../app/app.config';
import { Message } from './../../../_locale/messages';
import { SweetAlertHelper } from './../../../_helpers/sweet.alert.helper';
import { CommonHelper } from './../../../_helpers/common.helper';
import { ApiHelper } from './../../../_helpers/api.helper';
import { DEMOGRAPHIC_FIELD_PRIORITY } from './../../../_helpers/enum.helper';
import { ArrayHelper } from './../../../_helpers/array.helper';
import { UserService } from './../../../_services/user.service';

@Component({
  selector: 'app-patient-demographics',
  templateUrl: './patient-demographics.component.html',
  styleUrls: ['./patient-demographics.component.scss'],
  providers: [AppConfig, CommonHelper],
})
export class PatientDemographicsComponent implements OnInit {
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  loggedInUserId: string;
  userId: string;
  guardianDetails: string;
  demographicId: string;
  resultJsonObj: any = {};
  resultJsonObjCity: any = {};
  resultJsonObjPryPhyCity: any = {};
  resultJsonObjOrdPhyCity: any = {};
  existingDemographicData = false;
  newDemographicData = false;
  editDdemographicsformDataObj: any = {};
  calculatedAge: number;
  loggedUserEmail: any;
  loggedUserMobile: any;
  childRoute: string;
  newAge: any;
  ahsDisclaimerPdf: SafeResourceUrl;
  ahsHippaPdf: SafeResourceUrl;
  showAddressSuggestionsModal = false;
  arrayGenders: any = ArrayHelper.GENDER_ARRAY;
  arrayMonths: any = ArrayHelper.MONTH_ARRAY;
  arrayDays: any = ArrayHelper.DAY_ARRAY;
  arrayYears: any = ArrayHelper.YEAR_ARRAY;
  arrayStates: any = ArrayHelper.STATE_ARRAY;
  statesArrayDB: any = [];
  addressLength = false;
  noAddressLength = false;
  addressList: any;
  cityLength = false;
  noCityLength = false;
  citiesList: any = [];
  pryPhyCityLength = false;
  noPryPhyCityLength = false;
  pryPhyCitiesList: any = [];
  ordPhyCityLength = false;
  noOrdPhyCityLength = false;
  ordPhyCitiesList: any = [];
  orderStateSelectDisabledOption = true;
  orderCitySelectDisabledOption = true;
  orderSelectDisabledOption = true;
  priStateSelectDisabledOption = true;
  priCitySelectDisabledOption = true;
  priSelectDisabledOption = true;
  stateSelectDisabledOption = true;
  citySelectDisabledOption = true;
  priPhysicianLength = false;
  noPriPhysicianLength = false;
  priPhysiciansList: any = [];
  ordPhysicianLength = false;
  noOrdPhysicianLength = false;
  ordPhysiciansList: any = [];
  searchStateList: any = ArrayHelper.STATE_ARRAY;
  showSearchPhysicianModal = false;
  showDependantDetailsModal = false;
  physicianSearchObj: any = {};
  guradianSearchObj: any = {};
  selectedPhysician: any = {};
  selectedParent: any = {};
  doctorTableArr: any = [];
  guardianTableArr: any = [];
  doctorTableArrTemp: any = [];
  guardianTableArrTemp: any = [];
  isPrimary: any;
  showLoader = false;
  onBehalfOfAlertDemographic = 'Y';
  isMinor = 0;
  isDependant = 0;
  lastDependantStatus;
  linkedUserIds: string[] = [];
  isChecked = false;
  message = Message;
  valueChanged = 0;
  guardianSearchCounter = 0;
  parentId = '';
  linkedGuardians: number[] = [];
  guardianId: any;
  physicianCount = 1;
  dayNotGreaterThanToday: boolean = false; //parameter set for day
  monthNotGreaterThanToday: boolean = false; //parameter set for month
  yearNotGreaterThanToday: boolean = false; //parameter set for year
  bmi: any;
  reviewProfile: boolean = true;
  changeSecQue: boolean = false;
  changePass: boolean = false;
  // valuesChanged: boolean = false; //if value changed for height and weight
  activeTab: string = 'profile';

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public sweetAlertHelper: SweetAlertHelper,
    public userService: UserService
  ) {
    this.ahsDisclaimerPdf = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.appConfig.getDisclaimerPdf()
    );
    this.ahsHippaPdf = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.appConfig.getHippaPdf()
    );
    this.loggedInUserId = localStorage.getItem('delegateId')
      ? localStorage.getItem('delegateId')
      : this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.demographicId =
      this.activatedRoute.snapshot.paramMap.get('demographicId');
    this.editDdemographicsformDataObj.dobMonth = '';
    this.editDdemographicsformDataObj.dobDay = '';
    this.editDdemographicsformDataObj.dobYear = '';
    this.editDdemographicsformDataObj.gender = '';
    this.editDdemographicsformDataObj.isDependant = 0;
    this.editDdemographicsformDataObj.state = '';
    this.editDdemographicsformDataObj.city = '';
    this.editDdemographicsformDataObj.primaryPhysicianState = '';
    this.editDdemographicsformDataObj.primaryPhysicianCity = '';
    this.editDdemographicsformDataObj.primaryPhysician = '';
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
  }

  // tslint:disable-next-line:use-lifecycle-interface
  async ngAfterViewInit() {
    var item = localStorage.getItem('FullName');
    if (
      this.onBehalfOfAlertDemographic === 'Y' &&
      localStorage.getItem('isDelegating') === '1'
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.demographics.ngAfterViewInit + ' ' + item
      );
      // localStorage.setItem('onBehalfOfAlertDemographic', 'N');
      if (alertStatus) { // Assuming `isConfirmed` is true when "OK" is clicked
        localStorage.setItem('onBehalfOfAlertDemographic', 'N');
        window.location.reload();
      }
    }
  
  }

  ngOnInit() {
    this.findUserDetails(this.loggedInUserId);
    this.fnFindDemographic();
    this.fnFindPhysicianCities();
    this.onBehalfOfAlertDemographic = localStorage.getItem(
      'onBehalfOfAlertDemographic'
    )
      ? localStorage.getItem('onBehalfOfAlertDemographic')
      : 'Y';
    window.scrollTo(0, 0);
    // this.editDdemographicsformDataObj.heightInFeet = this.editDdemographicsformDataObj.heightInFeet,
    this.editDdemographicsformDataObj.bmi = this.BMI_calculator();
    this.bmi = this.BMI_calculator();

  }


  BMI_calculator(){
    const totalHeightInches = this.editDdemographicsformDataObj.heightInFeet * 12 + '.' + (this.editDdemographicsformDataObj.heightInInch ? this.editDdemographicsformDataObj.heightInInch : 0);
    let BMI = (this.editDdemographicsformDataObj.weight/(+totalHeightInches*+totalHeightInches)) * 703
    return parseFloat(BMI.toFixed(2));
  }

  async findUserDetails(userId) {
    const finalData = {
      company_slug: null,
      id: userId,
    };
    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_getUserDetail_SLS05Api,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status == 1) {
            if (apiResponse.commandResult.data.userInfo.isProfileCompleted) {
              this.existingDemographicData = true;
            } else {
              this.existingDemographicData = false;
            }
            this.isMinor = apiResponse.commandResult.data.userInfo.isMinor;
            localStorage.setItem('isMinor', String(this.isMinor));
            this.lastDependantStatus =
              apiResponse.commandResult.data.userInfo.IsDependant;
            this.editDdemographicsformDataObj.isDependant =
              apiResponse.commandResult.data.userInfo.IsDependant;

            this.editDdemographicsformDataObj.fName = this.userService.capitalizeFirstLetter(
              apiResponse.commandResult.data.userInfo.FirstName)  ;

            this.editDdemographicsformDataObj.email =
              apiResponse.commandResult.data.userInfo.Email;

            this.editDdemographicsformDataObj.cellPhone =
              apiResponse.commandResult.data.userInfo.Mobile;

            this.editDdemographicsformDataObj.dobDay =
              apiResponse.commandResult.data.userInfo.DOB_Date;
            this.editDdemographicsformDataObj.dobMonth =
              apiResponse.commandResult.data.userInfo.DOB_Month;
            this.editDdemographicsformDataObj.dobYear =
              apiResponse.commandResult.data.userInfo.DOB_Year;
            this.editDdemographicsformDataObj.age =
              apiResponse.commandResult.data.userInfo.AgeOnRegistration;

            this.editDdemographicsformDataObj.primaryGuardianEmail =
              apiResponse.commandResult.data.guardianInfo.Email;

            this.editDdemographicsformDataObj.primaryGuardianName =
              apiResponse.commandResult.data.guardianInfo.FullName;
            this.guardianId =
              apiResponse.commandResult.data.guardianInfo.ParentId;
            if (
              apiResponse.commandResult.data.userInfo.MiddleName !== 'NA' &&
              apiResponse.commandResult.data.userInfo.MiddleName !== 'N/A'
            ) {
              this.editDdemographicsformDataObj.mName = this.userService.capitalizeFirstLetter(
                apiResponse.commandResult.data.userInfo.MiddleName);
            } else {
              this.editDdemographicsformDataObj.mName = '';
            }
            if (
              apiResponse.commandResult.data.userInfo.ParentFirstName !==
              'NA' &&
              apiResponse.commandResult.data.userInfo.ParentFirstName !== 'N/A'
            ) {
              this.editDdemographicsformDataObj.parentFirstName =
                apiResponse.commandResult.data.userInfo.ParentFirstName;
            } else {
              this.editDdemographicsformDataObj.parentFirstName = '';
            }
            if (
              apiResponse.commandResult.data.userInfo.ParentLastName !== 'NA' &&
              apiResponse.commandResult.data.userInfo.ParentLastName !== 'N/A'
            ) {
              this.editDdemographicsformDataObj.parentLastName =
                apiResponse.commandResult.data.userInfo.ParentLastName;
            } else {
              this.editDdemographicsformDataObj.parentLastName = '';
            }
            this.editDdemographicsformDataObj.lName = this.userService.capitalizeFirstLetter(apiResponse.commandResult.data.userInfo.LastName);
            this.editDdemographicsformDataObj.heightInFeet = apiResponse.commandResult.data.userInfo.form1heightInFeet;
            this.editDdemographicsformDataObj.heightInInch = apiResponse.commandResult.data.userInfo.form1heightInInch;
            this.editDdemographicsformDataObj.weight = apiResponse.commandResult.data.userInfo.form1weight;
            this.editDdemographicsformDataObj.bmi = apiResponse.commandResult.data.userInfo.bmi;

          }
          console.log('iyiyiiiuiu', this.editDdemographicsformDataObj);
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindDemographic() {
    const finalData = {
      id: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
        ApiHelper.name_findUserDemographicGeneralApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          console.log('apiResponse', apiResponse.commandResult);
          if (apiResponse.commandResult.data.userInfo.DemographicId > 0) {
            const form1DOB = apiResponse.commandResult.data.userInfo.form1DOB
              ? apiResponse.commandResult.data.userInfo.form1DOB.split('-')
              : 0;
            this.editDdemographicsformDataObj.gender =
              apiResponse.commandResult.data.userInfo.form1Gender;
            if (
              apiResponse.commandResult.data.userInfo.form1ResidenceNumber ==
              'NA' ||
              apiResponse.commandResult.data.userInfo.form1ResidenceNumber ==
              '-'
            ) {
              this.editDdemographicsformDataObj.residence = '';
            } else {
              this.editDdemographicsformDataObj.residence =
                apiResponse.commandResult.data.userInfo.form1ResidenceNumber;
            }
            this.editDdemographicsformDataObj.street =
              apiResponse.commandResult.data.userInfo.form1StreetName;
            this.editDdemographicsformDataObj.city =
              apiResponse.commandResult.data.userInfo.form1City;
            this.editDdemographicsformDataObj.state =
              apiResponse.commandResult.data.userInfo.form1State;
            this.editDdemographicsformDataObj.zip =
              apiResponse.commandResult.data.userInfo.form1ZIP;
            if (
              apiResponse.commandResult.data.userInfo.form1CellularPhoneAlt ==
              'NA' ||
              apiResponse.commandResult.data.userInfo.form1CellularPhoneAlt ==
              '-'
            ) {
              this.editDdemographicsformDataObj.altPhone = '';
            } else {
              this.editDdemographicsformDataObj.altPhone =
                apiResponse.commandResult.data.userInfo.form1CellularPhoneAlt;
            }
            this.editDdemographicsformDataObj.demographicId =
              apiResponse.commandResult.data.userInfo.DemographicId;
            this.editDdemographicsformDataObj.primaryPhysician =
              this.userService.getUS_FromatName(
                apiResponse.commandResult.data.userInfo.form1PrimaryPhysician
              );
            this.editDdemographicsformDataObj.primaryPhysicianState =
              apiResponse.commandResult.data.userInfo.form1PrimaryPhysicianState;
            this.editDdemographicsformDataObj.primaryPhysicianStateName =
              this.arrayStates.find(
                (e) =>
                  e.StateCode ==
                  apiResponse.commandResult.data.userInfo
                    .form1PrimaryPhysicianState
              ).State;
            this.editDdemographicsformDataObj.primaryPhysicianCity =
              apiResponse.commandResult.data.userInfo.form1PrimaryPhysicianCity;
          } else {
            this.newDemographicData = true;
            this.editDdemographicsformDataObj.demographicId = 0;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindCitiesList(stateCode) {
    const finalDataCity = { stateCode };
    this.http
      .post(
        this.appConfig.getLambdaUrl01() +
        ApiHelper.name_getCitiesByStateCodeApi,
        finalDataCity,
        this.httpOptions
      )
      .subscribe(
        (apiResponseCity) => {
          this.resultJsonObjCity = apiResponseCity;
          if (this.resultJsonObjCity.commandResult.status == 1) {
            if (this.resultJsonObjCity.commandResult.data.cityList.length > 0) {
              this.cityLength = true;
              this.citiesList =
                this.resultJsonObjCity.commandResult.data.cityList;
            } else {
              this.noCityLength = true;
            }
          } else {
            this.noCityLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindPrimaryPhysicianCitiesList(stateCode) {
    const finalDataPryPhyCity = { stateCode };
    this.http
      .post(
        this.appConfig.getLambdaUrl01() +
        ApiHelper.name_getCitiesByStateCodeApi,
        finalDataPryPhyCity,
        this.httpOptions
      )
      .subscribe(
        (apiResponsePryPhyCity) => {
          this.resultJsonObjPryPhyCity = apiResponsePryPhyCity;
          if (this.resultJsonObjPryPhyCity.commandResult.status == 1) {
            if (
              this.resultJsonObjPryPhyCity.commandResult.data.cityList.length >
              0
            ) {
              this.pryPhyCityLength = true;
              this.pryPhyCitiesList =
                this.resultJsonObjPryPhyCity.commandResult.data.cityList;
            } else {
              this.noPryPhyCityLength = true;
            }
          } else {
            this.noPryPhyCityLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindOrderingPhysicianCitiesList(stateCode) {
    const finalDataOrdPhyCity = { stateCode };
    this.http
      .post(
        this.appConfig.getLambdaUrl01() +
        ApiHelper.name_getCitiesByStateCodeApi,
        finalDataOrdPhyCity,
        this.httpOptions
      )
      .subscribe(
        (apiResponseOrdPhyCity) => {
          this.resultJsonObjOrdPhyCity = apiResponseOrdPhyCity;
          if (this.resultJsonObjOrdPhyCity.commandResult.status == 1) {
            if (
              this.resultJsonObjOrdPhyCity.commandResult.data.cityList.length >
              0
            ) {
              this.ordPhyCityLength = true;
              this.ordPhyCitiesList =
                this.resultJsonObjOrdPhyCity.commandResult.data.cityList;
            } else {
              this.noOrdPhyCityLength = true;
            }
          } else {
            this.noOrdPhyCityLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindPrimaryPhysicianList(cityName) {
    if (this.existingDemographicData) {
      const finalDataPriPhysician = { cityName };
      this.http
        .post(
          this.appConfig.getLambdaUrl03() +
          ApiHelper.name_getPhysiciansByCityCodeApi,
          finalDataPriPhysician,
          this.httpOptions
        )
        .subscribe(
          (apiResponsePhysician: any) => {
            if (apiResponsePhysician.commandResult.status == 1) {
              if (
                apiResponsePhysician.commandResult.data.physiciansList.length >
                0
              ) {
                this.priPhysicianLength = true;
                this.priSelectDisabledOption = false;
                this.priPhysiciansList = this.fnChangePhysicianNameFormat(
                  apiResponsePhysician.commandResult.data.physiciansList
                );
              } else {
                this.noPriPhysicianLength = true;
              }
            } else {
              this.noPriPhysicianLength = true;
            }
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  fnFindOrderingPhysicianList(cityName) {
    if (this.existingDemographicData) {
      const finalDataPhysician = { cityName };
      this.http
        .post(
          this.appConfig.getLambdaUrl03() +
          ApiHelper.name_getPhysiciansByCityCodeApi,
          finalDataPhysician,
          this.httpOptions
        )
        .subscribe(
          (apiResponsePhysician: any) => {
            if (apiResponsePhysician.commandResult.status == 1) {
              if (
                apiResponsePhysician.commandResult.data.physiciansList.length >
                0
              ) {
                this.ordPhysicianLength = true;
                this.orderSelectDisabledOption = false;
                this.ordPhysiciansList = this.fnChangePhysicianNameFormat(
                  apiResponsePhysician.commandResult.data.physiciansList
                );
              } else {
                this.noOrdPhysicianLength = true;
              }
            } else {
              this.noOrdPhysicianLength = true;
            }
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  fnFindPhysicianCities() {
    const finalData = {
      id: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findPhysicianCitiesApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.statesArrayDB = apiResponse.commandResult.data.statesList;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  verifyAddress() {
    this.addressList = {};
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = {
      headers: httpHeaders,
    };

    const finalData = {
      streeAddress:
        this.editDdemographicsformDataObj.residence +
        ', ' +
        this.editDdemographicsformDataObj.street +
        ', ' +
        this.editDdemographicsformDataObj.city +
        ', ' +
        this.editDdemographicsformDataObj.state +
        ', ' +
        this.editDdemographicsformDataObj.zip,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl02() + ApiHelper.name_verifyAddressApi,
        finalData,
        options
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.status == 1) {
            if (this.resultJsonObj.commandResult.data.Addresses.length > 0) {
              this.addressLength = true;
              this.addressList =
                this.resultJsonObj.commandResult.data.Addresses;
            }
          }
          this.showAddressSuggestionsModal = true;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  closeAddressSuggestionsModal() {
    this.showAddressSuggestionsModal = false;
  }

  onItemChange(address) {
    if (address != 'curentAdd') {
      const splitted = address.split(',');
      this.editDdemographicsformDataObj.street = splitted[0];
      const stateName = splitted[1]
        .trim()
        .substring(splitted[1].trim().lastIndexOf(' ') + 1);
      const cityName = splitted[1]
        .trim()
        .substring(0, splitted[1].trim().lastIndexOf(' '));
      this.editDdemographicsformDataObj.city = cityName;
      this.editDdemographicsformDataObj.state = stateName;
      this.showAddressSuggestionsModal = false;
    }
  }

  findCitiesByStateCode(stateCode) {
    this.stateSelectDisabledOption = false;
    this.citiesList = [];
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = {
      headers: httpHeaders,
    };
    const finalData = { stateCode };
    return this.http
      .post(
        this.appConfig.getLambdaUrl01() +
        ApiHelper.name_getCitiesByStateCodeApi,
        finalData,
        options
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          this.citySelectDisabledOption = true;
          this.editDdemographicsformDataObj.zip = null;
          if (this.resultJsonObj.commandResult.status === 1) {
            if (this.resultJsonObj.commandResult.data.cityList.length > 0) {
              this.cityLength = true;
              this.citiesList = this.resultJsonObj.commandResult.data.cityList;
            } else {
              this.noCityLength = true;
            }
          } else {
            this.noCityLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findPryPhyCitiesByStateCode(stateCode) {
    this.priStateSelectDisabledOption = false;
    this.pryPhyCitiesList = [];
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = {
      headers: httpHeaders,
    };
    const finalData = { stateCode };
    return this.http
      .post(
        this.appConfig.getLambdaUrl01() +
        ApiHelper.name_getCitiesByStateCodeApi,
        finalData,
        options
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          this.editDdemographicsformDataObj.primaryPhysicianCity = '';
          this.editDdemographicsformDataObj.primaryPhysician = '';
          this.priCitySelectDisabledOption = false;
          if (this.resultJsonObj.commandResult.status === 1) {
            if (this.resultJsonObj.commandResult.data.cityList.length > 0) {
              this.pryPhyCityLength = true;
              this.pryPhyCitiesList =
                this.resultJsonObj.commandResult.data.cityList;
            } else {
              this.noPryPhyCityLength = true;
            }
          } else {
            this.noPryPhyCityLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findOrdPhyCitiesByStateCode(stateCode) {
    this.orderStateSelectDisabledOption = false;
    this.ordPhyCitiesList = [];
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = {
      headers: httpHeaders,
    };
    const finalData = { stateCode };
    return this.http
      .post(
        this.appConfig.getLambdaUrl01() +
        ApiHelper.name_getCitiesByStateCodeApi,
        finalData,
        options
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          this.orderCitySelectDisabledOption = false;
          this.editDdemographicsformDataObj.orderingPhysicianCity = '';
          this.editDdemographicsformDataObj.orderingPhysician = '';
          if (this.resultJsonObj.commandResult.status === 1) {
            if (this.resultJsonObj.commandResult.data.cityList.length > 0) {
              this.ordPhyCityLength = true;
              this.ordPhyCitiesList =
                this.resultJsonObj.commandResult.data.cityList;
            } else {
              this.noOrdPhyCityLength = true;
            }
          } else {
            this.noOrdPhyCityLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async onReset() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.demographics.onReset
    );
    if (alertStatus) {
      this.router.navigate([
        '/dashboard/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
        '/1',
      ]);
    }
  }

  hideDisabledOrderCity() {
    this.orderCitySelectDisabledOption = false;
  }

  hideDisabledOrder() {
    this.orderSelectDisabledOption = false;
  }

  hideDisabledPriCity() {
    this.priCitySelectDisabledOption = false;
  }

  hideDisabledPri() {
    this.priSelectDisabledOption = false;
  }

  hideDisabledCity() {
    this.citySelectDisabledOption = false;
  }

  findPryPhysiciansByCity(cityName) {
    this.priCitySelectDisabledOption = false;
    const finalDataPhysician = { cityName };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
        ApiHelper.name_getPhysiciansByCityCodeApi,
        finalDataPhysician,
        this.httpOptions
      )
      .subscribe(
        async (apiResponsePhysician: any) => {
          if (apiResponsePhysician.commandResult.status === 1) {
            this.priSelectDisabledOption = false;
            if (
              apiResponsePhysician.commandResult.data.physiciansList.length > 0
            ) {
              this.priPhysicianLength = true;
              this.priPhysiciansList = this.fnChangePhysicianNameFormat(
                apiResponsePhysician.commandResult.data.physiciansList
              );
            } else {
              await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.demographics.findPryPhysiciansByCity
              );
              this.noPriPhysicianLength = true;
            }
          } else {
            await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.demographics.findPryPhysiciansByCity
            );
            this.noPriPhysicianLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findOdrPhysiciansByCity(cityName) {
    this.orderCitySelectDisabledOption = false;
    const finalDataOrdPhysician = { cityName };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
        ApiHelper.name_getPhysiciansByCityCodeApi,
        finalDataOrdPhysician,
        this.httpOptions
      )
      .subscribe(
        async (apiResponsePhysician: any) => {
          if (apiResponsePhysician.commandResult.status === 1) {
            this.orderSelectDisabledOption = false;
            if (
              apiResponsePhysician.commandResult.data.physiciansList.length > 0
            ) {
              this.ordPhysicianLength = true;
              this.ordPhysiciansList = this.fnChangePhysicianNameFormat(
                apiResponsePhysician.commandResult.data.physiciansList
              );
            } else {
              await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.demographics.findPryPhysiciansByCity
              );
              this.noOrdPhysicianLength = true;
            }
          } else {
            await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.demographics.findPryPhysiciansByCity
            );
            this.noOrdPhysicianLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  validateNumber(event) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if (
      !(
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        excludedKeys.includes(keyCode)
      )
    ) {
      event.preventDefault();
    }
  }

  async calculateAge(value) {
    if (value.dobDay > 0 && value.dobMonth > 0 && value.dobYear > 0) {
      const selectedDate = new Date(
        parseInt(value.dobYear),
        parseInt(value.dobMonth) - 1,
        parseInt(value.dobDay)
      );

      const today = new Date();

      // this.dayNotGreaterThanToday = selectedDate.getDate() > today.getDate();
      this.dayNotGreaterThanToday = selectedDate > today;
      this.monthNotGreaterThanToday = selectedDate.getMonth() > today.getMonth() && selectedDate.getFullYear() >= today.getFullYear();
      this.yearNotGreaterThanToday = selectedDate.getFullYear() > today.getFullYear();
      if (this.yearNotGreaterThanToday) {
        // Display error message for year
        this.monthNotGreaterThanToday = false;
        this.dayNotGreaterThanToday = false;
      } else if (this.monthNotGreaterThanToday) {
        // Display error message for month only if the year is the same or less than today
        this.dayNotGreaterThanToday = false;
      } else if (this.dayNotGreaterThanToday && selectedDate.getMonth() === today.getMonth() && selectedDate.getFullYear() === today.getFullYear()) {
        // Display error message for day only if the month and year are the same as today
        this.monthNotGreaterThanToday = false;
      } else {
        // No error, calculate age
        const calculatedAge = this.commonHelper.calculateAge(
          value.dobYear,
          value.dobMonth,
          value.dobDay
        );
        this.editDdemographicsformDataObj.age = calculatedAge;
        this.newAge = calculatedAge;
      }
    } else {
      this.editDdemographicsformDataObj.age = this.editDdemographicsformDataObj.age;
    }
  }


  async goToDashboad() {
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.ushfComp.onReset
    // );
    // if (alertStatus) {
      this.router.navigate([
        '/dashboard/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId'),
      ]);
    // }
  }

  fnChangePhysicianNameFormat(physicians) {
    const formattedNameArray: any = [];
    physicians.forEach((e) => {
      e.PhysicianName = this.userService.getUS_FromatName(e.PhysicianName);
      formattedNameArray.push(e);
    });
    return formattedNameArray.sort(
      this.userService.dynamicSort('PhysicianName')
    );
  }

  openSearchPhysicianModal(isPrimary) {
    this.isPrimary = isPrimary;
    this.showSearchPhysicianModal = true;
    this.physicianSearchObj.physicianSearchLastName = '';
    this.physicianSearchObj.physicianSearchFirstName = '';
    this.physicianSearchObj.physicianSearchState = '';
    this.physicianSearchObj.physicianSearchNPI = '';
    this.doctorTableArr = [];
  }

  openDependantDetailsModal(isPrimary) {
    this.isPrimary = isPrimary;
    this.showDependantDetailsModal = true;
    this.guradianSearchObj.firstName = '';
    this.guradianSearchObj.lastName = '';
    this.guradianSearchObj.email = '';
    this.guardianTableArr = [];
  }

  resetSearchPhysicianModal() {
    this.isPrimary = '';
    this.physicianSearchObj.physicianSearchLastName = '';
    this.physicianSearchObj.physicianSearchFirstName = '';
    this.physicianSearchObj.physicianSearchState = '';
    this.physicianSearchObj.physicianSearchNPI = '';
    this.doctorTableArr = [];
    this.physicianCount = 1;
  }

  resetDependantDetailsModal() {
    this.isPrimary = '';
    this.guradianSearchObj.firstName = '';
    this.guradianSearchObj.lastName = '';
    this.guradianSearchObj.email = '';
    this.guardianTableArr = [];
  }

  closeSearchPhysicianModal() {
    this.isPrimary = '';
    this.showSearchPhysicianModal = false;
    this.physicianSearchObj.physicianSearchLastName = '';
    this.physicianSearchObj.physicianSearchFirstName = '';
    this.physicianSearchObj.physicianSearchState = '';
    this.physicianSearchObj.physicianSearchNPI = '';
    this.doctorTableArr = [];
  }

  closeGuardianSearchModal() {
    this.isPrimary = '';
    this.showDependantDetailsModal = false;
    this.guradianSearchObj.firstName = '';
    this.guradianSearchObj.lastName = '';
    this.guradianSearchObj.email = '';
    this.guardianTableArr = [];
    if (this.editDdemographicsformDataObj.isDependant == 0) {
      this.editDdemographicsformDataObj.isDependant = 1;
    } else {
      this.editDdemographicsformDataObj.isDependant = 0;
    }
  }

  async searchGuardian() {
    console.log('searchGuardian called');
    if (
      !this.guradianSearchObj.email &&
      !this.guradianSearchObj.firstName &&
      !this.guradianSearchObj.lastName
    ) {
      await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.demographics.searchGuardianDetails
      );
      return;
    } else if (
      this.guradianSearchObj.lastName &&
      !this.guradianSearchObj.firstName &&
      !this.guradianSearchObj.email
    ) {
      await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.demographics.searchGuardianFirstName
      );
      return;
    } else if (
      this.guradianSearchObj.firstName &&
      !this.guradianSearchObj.lastName &&
      !this.guradianSearchObj.email
    ) {
      await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.demographics.searchGuardianLastName
      );
      return;
    } else if (
      this.guradianSearchObj.firstName &&
      this.guradianSearchObj.lastName &&
      !this.guradianSearchObj.email
    ) {
      await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.demographics.searchGuardianEmail
      );
      return;
    } else if (
      this.guradianSearchObj.email &&
      !this.guradianSearchObj.firstName &&
      !this.guradianSearchObj.lastName
    ) {
      await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.demographics.searchGuardianfnamelname
      );
      return;
    } else {
      if (!this.commonHelper.isValidEmail(this.guradianSearchObj.email)) {
        console.log('invalid');
        await this.sweetAlertHelper.alertPopUp(this.message.validEmail);
        return;
      } else {
        console.log('valid');
      }

      const loggedUserEmail = localStorage.getItem('loggedInUserEmail');
      const isSearchingSelf =
        this.guradianSearchObj.email &&
        this.guradianSearchObj.email.toLowerCase() ===
        loggedUserEmail.toLowerCase();
      this.guardianTableArr = [];
      this.showLoader = true;
      const finalData = {
        userId: this.loggedInUserId,
        firstName: this.guradianSearchObj.firstName,
        lastName: this.guradianSearchObj.lastName,
        email: this.guradianSearchObj.email,
      };
      this.http
        .post(
          this.appConfig.getLambdaUrl05() + ApiHelper.searchParents,
          finalData,
          this.httpOptions
        )
        .subscribe(
          async (apiResponse: any) => {
            if (isSearchingSelf) {
              this.showLoader = false;
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.demographics.selfSearchAsGuradian
              );
              if (alertStatus) {
                this.resetDependantDetailsModal();
              }
              return;
            }
            this.guardianTableArr = apiResponse.commandResult.data.minors;
            this.showLoader = false;
            if (this.guardianTableArr.length == 0) {
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.demographics.SearchGuardianNoRecords
              );
              if (alertStatus) {
                this.resetDependantDetailsModal();
              }
              this.guardianSearchCounter++;
            }
            if (this.guardianSearchCounter == 3) {
              console.log(
                'alertStstus:',
                this.message.alertMessages.demographics.searchCounter
              );
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.demographics.searchCounter
              );
              if (alertStatus) {
                this.router.navigate([
                  '/dashboard/' +
                  this.activatedRoute.snapshot.paramMap.get('loggedInUserId'),
                ]);
              }
            }
            for (const guardian of this.guardianTableArr) {
              // const isDependant = localStorage.getItem('isDependant');
              const isDependant = this.editDdemographicsformDataObj.isDependant;
              console.log(
                'isDependant================================',
                isDependant
              );
              console.log(
                'isDependant================================',
                this.guardianId
              );
              console.log(
                'isDependant================================',
                guardian.RecordId
              );
              // if (isDependant == '1' && this.guardianId !== guardian.RecordId) {
              //   await this.sweetAlertHelper.alertPopUp(this.message.alertMessages.demographics.searchValidGuardian);
              //   return this.resetDependantDetailsModal();
              // } else {
              //   console.log(this.guardianId, guardian.RecordId);
              // }
            }
            this.editDdemographicsformDataObj.primaryGuardianEmail =
              apiResponse.commandResult.data.minors.Email;
            this.editDdemographicsformDataObj.primaryGuardianName =
              apiResponse.commandResult.data.minors.FullName;
            this.showLoader = false;
          },
          (err) => {
          }
        );
    }
  }

  async searchPhysicians() {
    if (this.physicianSearchObj.physicianSearchNPI) {
      if (this.physicianSearchObj.physicianSearchNPI.length !== 10 || !/^\d+$/.test(this.physicianSearchObj.physicianSearchNPI)) {
        // Display an error message for invalid NPI length
        const alertStatus = await this.sweetAlertHelper.alertPopUp(
          this.message.alertMessages.demographics.invalidNPI
        );
        return;
      }
    }
    if (
      !this.physicianSearchObj.physicianSearchNPI &&
      !this.physicianSearchObj.physicianSearchFirstName
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.demographics.searchPhysicians
      );

      // alert(
      //   "Please enter first name. You may search physician informantion by NPI number."
      // );
      return;
    } else if (
      !this.physicianSearchObj.physicianSearchNPI &&
      !this.physicianSearchObj.physicianSearchLastName
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.demographics.searchPhysician2
      );

      // alert(
      //   "Please enter last name. You may search physician informantion by NPI number."
      // );
      return;
    } else if (
      !this.physicianSearchObj.physicianSearchNPI &&
      !this.physicianSearchObj.physicianSearchState
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.demographics.searchPhysicians1
      );

      // alert("Please select state.");
      return;
    } else {
      this.doctorTableArr = [];
      this.showLoader = true;
      const finalData = {
        loggedInUserId: this.loggedInUserId,
        physicianSearchFirstName:
          this.physicianSearchObj.physicianSearchFirstName,
        physicianSearchLastName:
          this.physicianSearchObj.physicianSearchLastName,
        physicianSearchState: this.physicianSearchObj.physicianSearchState,
        physicianSearchNPI: this.physicianSearchObj.physicianSearchNPI,
      };
      this.http
        .post(
          // this.appConfig.getPhpUrl() + ApiHelper.php_searchNPIRegistryApi,
          this.appConfig.getLambdaUrl06() + ApiHelper.searchNPIRegistry,
          finalData,
          this.httpOptions
        )
        // tslint:disable-next-line: deprecation
        .subscribe(
          (apiResponse: any) => {
            if (apiResponse.commandResult.data && apiResponse.commandResult.data.physiciansList.length > 0) {  //npi registry problem resolved
              this.doctorTableArr = apiResponse.commandResult.data.physiciansList;

              this.physicianCount = apiResponse.commandResult.data.physiciansCout;
              this.showLoader = false;
            } else {
              // Records not found or no data received - handle accordingly
              this.doctorTableArr = [];

              this.physicianCount = 0;

              // Display a message to the user indicating no records were found
              // alert('No records found for the entered NPI.');
              const alertStatus = this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.demographics.notValidNpi
              );
              this.showLoader = false;
              if (alertStatus) {
                return false;
              }
            }
            this.showLoader = false;
          },
          (err) => {
            console.error(err);
            this.showLoader = false;
          }
        );
    }
  }

  selectGuardian(userId) {
    this.parentId = userId;
    this.selectedParent = this.guardianTableArr.find(
      (e) => e.RecordId === userId
    );
    this.showDependantDetailsModal = false;
    this.valueChanged = 2;
    this.editDdemographicsformDataObj.primaryGuardianEmail =
      this.selectedParent.Email;
    this.editDdemographicsformDataObj.primaryGuardianName =
      this.selectedParent.FullName;
    this.editDdemographicsformDataObj.primaryGuardianId =
      this.selectedParent.RecordId;
    if (this.isLinked(userId)) {
      this.unlinkGuardian(userId);
    } else {
      this.linkGuardian(userId);
    }
  }

  isLinked(userId: number): boolean {
    return this.linkedGuardians.includes(userId);
  }

  linkGuardian(userId: number): void {
    this.linkedGuardians.push(userId);
  }

  unlinkGuardian(userId: number): void {
    const index = this.linkedGuardians.indexOf(userId);
    if (index !== -1) {
      this.linkedGuardians.splice(index, 1);
    }
  }

  selectDoctor(physicianId) {
    this.selectedPhysician = this.doctorTableArr.find(
      (e) => e.RecordId === physicianId
    );
    this.showSearchPhysicianModal = false;
    this.valueChanged = 2;
    if (this.isPrimary === 1) {
      this.editDdemographicsformDataObj.primaryPhysicianState =
        this.selectedPhysician.PhysicianStateCode;
      this.editDdemographicsformDataObj.primaryPhysicianStateName =
        this.selectedPhysician.PhysicianStateName;
      this.editDdemographicsformDataObj.primaryPhysicianCity =
        this.selectedPhysician.PhysicianCity;
      this.editDdemographicsformDataObj.primaryPhysician =
        this.selectedPhysician.PhysicianName;
    } else {
      this.editDdemographicsformDataObj.orderingPhysicianState =
        this.selectedPhysician.PhysicianStateCode;
      this.editDdemographicsformDataObj.orderingPhysicianStateName =
        this.selectedPhysician.PhysicianStateName;
      this.editDdemographicsformDataObj.orderingPhysicianCity =
        this.selectedPhysician.PhysicianCity;
      this.editDdemographicsformDataObj.orderingPhysician =
        this.selectedPhysician.PhysicianName;
    }
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }

  setStateCode(stateCode) {
    this.physicianSearchObj.physicianSearchState = stateCode;
  }

  updateValueChanged(value) {
    console.log('Start value', this.valueChanged);
    if (this.valueChanged != 2) {
      this.valueChanged = value;
    }
    console.log('Changed value', this.valueChanged);
  }

  async onSubmit(f) {
    const selectedDate = new Date(
      parseInt(this.editDdemographicsformDataObj.dobYear),
      parseInt(this.editDdemographicsformDataObj.dobMonth) - 1,
      parseInt(this.editDdemographicsformDataObj.dobDay)
    );
    console.log('this.valueChanged', this.valueChanged)
    const today = new Date();
    if (selectedDate > today) {
      Swal.fire({
        text: 'The selected Date of Birth cannot be greater than today.',

      }).then((result) => {
        if (result.value) {
          return false;
        }
      });
      return; // Prevent further execution of form submission
    }

    if (this.valueChanged === 0) {
      /*---------if there is no change on the form but clicked accept -----------*/
      Swal.fire({
        title: 'PLEASE CONFIRM',
        text: 'Click \'OK\' to confirm you\'ve reviewed your demographic profile and your intent not to make any changes.',
        showCancelButton: true,
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.value) {
          this.router.navigate([
            '/dashboard/' +
            this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
            '/1',
          ]);
        }
      });
    } else {
      console.log('dirty form');
      if (this.valueChanged === 2) {
        /*---------if there are changes on the form for high filed-----------*/
        this.updateHighFields();
      } else {
        /*---------if there are changes on the form for low filed-----------*/
        this.updateLowFields();
      }
    }
  }

  async updateHighFields() {
    this.bmi = this.BMI_calculator();
    const finalData = {
      fName: this.editDdemographicsformDataObj.fName,
      mName: this.editDdemographicsformDataObj.mName,
      lName: this.editDdemographicsformDataObj.lName,
      parentFirstName: this.editDdemographicsformDataObj.parentFirstName
        ? this.editDdemographicsformDataObj.parentFirstName
        : 'NA',
      parentLastName: this.editDdemographicsformDataObj.parentLastName
        ? this.editDdemographicsformDataObj.parentLastName
        : 'NA',
      dobMonth: this.editDdemographicsformDataObj.dobMonth,
      dobDay: this.editDdemographicsformDataObj.dobDay,
      dobYear: this.editDdemographicsformDataObj.dobYear,
      age: this.newAge ? this.newAge : this.editDdemographicsformDataObj.age,
      gender: this.editDdemographicsformDataObj.gender,
      isDependant: this.editDdemographicsformDataObj.isDependant
        ? this.editDdemographicsformDataObj.isDependant
        : this.isDependant,
      lastDependantStatus: this.lastDependantStatus,
      residence: this.editDdemographicsformDataObj.residence
        ? this.editDdemographicsformDataObj.residence
        : 'NA',
      street: this.editDdemographicsformDataObj.street,
      city: this.editDdemographicsformDataObj.city,
      state: this.editDdemographicsformDataObj.state,
      zip: this.editDdemographicsformDataObj.zip,
      email: this.editDdemographicsformDataObj.email,
      cellPhone: this.editDdemographicsformDataObj.cellPhone,
      altPhone: this.editDdemographicsformDataObj.altPhone,
      loggedInUserId: this.loggedInUserId,
      demographicId: this.editDdemographicsformDataObj.demographicId,
      primaryPhysician: this.userService.getRefromatName(
        this.editDdemographicsformDataObj.primaryPhysician
      ),
      primaryPhysicianState:
        this.editDdemographicsformDataObj.primaryPhysicianState,
      primaryPhysicianCity:
        this.editDdemographicsformDataObj.primaryPhysicianCity,
      primaryGuardianName:
        this.editDdemographicsformDataObj.primaryGuardianName,
      primaryGuardianEmail:
        this.editDdemographicsformDataObj.primaryGuardianEmail,
      primaryGuardianId: this.editDdemographicsformDataObj.primaryGuardianId,
      parentId: this.parentId,
      heightInFeet: this.editDdemographicsformDataObj.heightInFeet? this.editDdemographicsformDataObj.heightInFeet : 0 ,
      heightInInch: this.editDdemographicsformDataObj.heightInInch? this.editDdemographicsformDataObj.heightInInch : 0,
      weight: this.editDdemographicsformDataObj.weight? this.editDdemographicsformDataObj.weight : 0,
      bmi: this.bmi? this.bmi : 0,

    };
    let apiUrl = '';
    if (
      this.editDdemographicsformDataObj.demographicId > 0 ||
      this.editDdemographicsformDataObj.demographicId === null
    ) {
      apiUrl = ApiHelper.php_updatePatientDemographicTempApi;
    } else {
      apiUrl = ApiHelper.php_saveDemographicFormApiGeneralApi;
    }
    this.showLoader = true;

    if (this.valueChanged === 2) {
      this.showLoader = false;
      Swal.fire({
        title: 'PLEASE CONFIRM',
        text: 'Are you sure you want to update this profile? Doing so will require you to resign the AHS HIPAA Form, waiver, and disclaimer.',
        showCancelButton: true,
        confirmButtonText: 'OK',
      }).then((result) => {
        this.showLoader = false;
        if (result.value) {
          return this.http
            .post(
              this.appConfig.getPhpUrl() + apiUrl,
              finalData,
              this.httpOptions
            )
            .subscribe(
              async (apiResponse) => {
                this.resultJsonObj = apiResponse;
                if (this.resultJsonObj.commandResult.status === 1) {
                  this.demographicId =
                    this.resultJsonObj.commandResult.data.demographicInfo.Id;
                  this.router.navigate([
                    '/profile/hipaa/' +
                    this.activatedRoute.snapshot.paramMap.get(
                      'loggedInUserId'
                    ) +
                    '/' +
                    this.demographicId,
                  ]);
                } else {
                  this.showLoader = false;
                  await this.sweetAlertHelper.alertPopUp(
                    this.message.alertMessages.demographics.onSubmit
                  );
                }
              },
              (err) => {
              }
            );
        } else {
          window.location.reload();
        }
      });
    } else {
      this.showLoader = false;
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.demographicsForm.onsubmit
      );
      if (alertStatus) {
        if (this.existingDemographicData) {
          this.router.navigate([
            '/dashboard/' +
            this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
            '/1',
          ]);
        } else {
          this.router.navigate([
            '/hippa-acceptance/' +
            this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
            '/' +
            this.demographicId,
          ]);
        }
      }
    }
  }

  async updateLowFields() {
    this.bmi = this.BMI_calculator();
    const finalData = { 
      street: this.editDdemographicsformDataObj.street,
      city: this.editDdemographicsformDataObj.city,
      state: this.editDdemographicsformDataObj.state,
      zip: this.editDdemographicsformDataObj.zip,
      cellPhone: this.editDdemographicsformDataObj.cellPhone,
      altPhone: this.editDdemographicsformDataObj.altPhone,
      primaryPhysician: this.userService.getRefromatName(
        this.editDdemographicsformDataObj.primaryPhysician
      ),
      primaryPhysicianState:
        this.editDdemographicsformDataObj.primaryPhysicianState,
      primaryPhysicianCity:
        this.editDdemographicsformDataObj.primaryPhysicianCity,
      loggedInUserId: this.loggedInUserId,
      demographicId: this.editDdemographicsformDataObj.demographicId,
      heightInFeet: this.editDdemographicsformDataObj.heightInFeet,
      heightInInch: this.editDdemographicsformDataObj.heightInInch,
      weight: this.editDdemographicsformDataObj.weight,
      bmi: this.bmi,

    };
    Swal.fire({
      title: 'PLEASE CONFIRM',
      text: 'Are you sure you want to update this profile?',
      showCancelButton: true,
      confirmButtonText: 'OK',
    }).then((result) => {
      if (result.value) {
        this.showLoader = true;
        return this.http
          .post(
            this.appConfig.getPhpUrl() +
            ApiHelper.php_updateLowDemographicDataApi,
            finalData,
            this.httpOptions
          )
          .subscribe(
            async (apiResponse) => {
              this.resultJsonObj = apiResponse;
              if (this.resultJsonObj.commandResult.status === 1) {
                this.demographicId =
                  this.resultJsonObj.commandResult.data.demographicInfo.Id;
                this.router.navigate([
                  '/dashboard/' +
                  this.activatedRoute.snapshot.paramMap.get(
                    'loggedInUserId'
                  ) +
                  '/1',
                ]);
              } else {
                this.showLoader = false;
                await this.sweetAlertHelper.alertPopUp(
                  this.message.alertMessages.demographics.onSubmit
                );
              }
            },
            (err) => {
            }
          );
      } else {
        window.location.reload();
      }
    });
  }

  clearSearchPhysicianInputs(flag) {
    if (flag) {
      this.physicianSearchObj.physicianSearchFirstName = '';
      this.physicianSearchObj.physicianSearchLastName = '';
      this.physicianSearchObj.physicianSearchState = '';
    } else {
      this.physicianSearchObj.physicianSearchNPI = '';
    }
  }

  async openProfile() {
    this.changeSecQue = false;
    this.changePass = false;
    this.reviewProfile = true;
    return
    this.router.navigate(["/" + this.childRoute + "/demographics/" + this.loggedInUserId + "/" + this.demographicId]);
  }
  async openSecurityQuestions() {
    // security-questions/:loggedInUserId/:demographicId/
    this.changeSecQue = true;
    this.changePass = false;
    this.reviewProfile = false;
    this.activeTab = 'security';
    return
    this.router.navigate(["/" + this.childRoute + "/security-questions/" + this.loggedInUserId + "/" + this.demographicId]);

  }

  async changePassword() {
    // security-questions/:loggedInUserId/:demographicId/
    this.changeSecQue = false;
    this.changePass = true;
    this.reviewProfile = false;
    this.activeTab = 'password';
    return
    this.router.navigate(["/"+ "/change-password/" + this.loggedInUserId]);

  }
}

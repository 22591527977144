import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { SweetAlertHelper } from "../../_helpers/sweet.alert.helper";
import { Message } from "../../_locale/messages";

@Component({
  selector: "app-parent-minor-relation",
  templateUrl: "./parent-minor-relation.component.html",
  styleUrls: ["./parent-minor-relation.component.scss"],
})
export class ParentMinorRelationComponent implements OnInit {
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  loggedInUserId: string;
  loggedInUserRoleId: any;
  selectedMinorId: any;
  selectedMinorOtp: any;
  minors: any = [];
  showLoader = false;
  showSearchMinorModal = false;
  showhMinorLoginModal = false;
  minorSearchObj: any = {};
  minorTableArr: any = [];

  formErrorMessage = false;
  accountErrorMessage = false;
  wrongMinorErrorMessage = false;
  fieldTextType: boolean;
  loginObj: any = {};
  showLoginForm = true;
  showVerifyLoginOtpForm = false;
  verifyLoginFormErrorMessage = false;
  message = Message;
  searchUserCount = 1;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    public sweetAlertHelper: SweetAlertHelper,
    private router: Router,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.loggedInUserRoleId = localStorage.getItem("loggedInUserRoleId");
    this.findParentMinorRelation();
    window.scroll(0, 0);
  }

  findParentMinorRelation() {
    const finalData = {
      parentId: this.loggedInUserId,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.getMinorsByParent,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          this.minors = res.commandResult.data.minors
            ? res.commandResult.data.minors
            : [];
          this.showLoader = false;
        },
        (err) => {
          this.showLoader = false;
          console.error(err);
        }
      );
  }

  openSearchMinorModal() {
    this.showSearchMinorModal = true;
    this.minorSearchObj.minorSearchText = "";
    this.minorTableArr = [];
  }

  resetSearchMinorModal() {
    this.minorSearchObj.minorSearchText = "";
    this.minorTableArr = [];
    this.searchUserCount = 1;
  }

  closeSearcMinorModal() {
    this.showSearchMinorModal = false;
    this.searchUserCount = 1;
  }

  async searchMinors() {
    if (
      !this.minorSearchObj.minorSearchText &&
      !this.minorSearchObj.minorSearchText
    ) {
      // alert("Please enter search text to continue with minors search.");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.parentMinorComp.searchMinors
      );
      return;
    } else {
      this.minorTableArr = [];
      const finalData = {
        userId: this.loggedInUserId,
        minorSearchText: this.minorSearchObj.minorSearchText,
      };
      this.showLoader = true;
      this.http
        .post(
          this.appConfig.getLambdaUrl05() + ApiHelper.searchMinors,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (res: any) => {
            console.log("Results: ", res);
            this.minorTableArr = res.commandResult.data.minors;
            this.searchUserCount = res.commandResult.data.minors.length;
            this.showLoader = false;
          },
          (err) => {
            this.showLoader = false;
            console.error(err);
          }
        );
    }
  }

  async isMinorAlert() {
    // alert("Parent-Minor relationship already exists");
    const alertStatus = await this.sweetAlertHelper.alertPopUp(
      this.message.alertMessages.parentMinorComp.isMinorAlert
    );
  }

  async selectMinor(minorObj) {
    if (minorObj.isProfileCompleted == 0) {
      // alert(
      //   "Hippa form is not completed for this minor.Complete Hippa form before you proceed."
      // );
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.parentMinorComp.selectMinor
      );
      return false;
    }

    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you sure to create Parent-Minor relastionship?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.parentMinorComp.selectMinor
    );
    if (alertStatus) {
      this.selectedMinorId = minorObj.MinorId;
      this.showSearchMinorModal = false;
      this.showhMinorLoginModal = true;
      // this.router.navigate(['/parent-minor-login/' + this.loggedInUserId + '/' + minorId]);
    }
  }

  async removeMinor(minorId, relationId) {
    // if (confirm("Are you sure you want to remove?"))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.parentMinorComp.removeMinor
    );
    if (alertStatus) {
      this.minorTableArr = [];
      const finalData = {
        prarentId: this.loggedInUserId,
        minorId,
        relationId,
      };
      // this.showLoader = true;
      this.http
        .post(
          // this.appConfig.getPhpUrl() + ApiHelper.php_removeMinorApi,
          this.appConfig.getLambdaUrl06() + ApiHelper.removeMinor,
          finalData,
          this.httpOptions
        )
        .subscribe(
          async (res: any) => {
            // alert("Parent-Minor relationship removed successfully.");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.parentMinorComp.removeMinor
            );
            if (alertStatus) window.location.reload();
            this.showLoader = false;
          },
          (err) => {
            this.showLoader = false;
            console.error(err);
          }
        );
    }
  }

  async goToDashboad() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM:   By clicking ‘OK’: You will be returned to the ‘Home Page’."
    //   )
    // )
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.ushfComp.onReset
    // );
    // if (alertStatus) {
      this.router.navigate(["/dashboard/" + this.loggedInUserId]);
    // }
  }

  onLoginSubmit1() {}

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  closeMinorLoginModal() {
    this.showhMinorLoginModal = false;
    this.showSearchMinorModal = true;
    this.showLoginForm = true;
    this.showVerifyLoginOtpForm = false;
    this.wrongMinorErrorMessage = false;
    this.loginObj.email = "";
    this.loginObj.password = "";
    this.loginObj.otp = "";
    this.formErrorMessage = false;
    this.verifyLoginFormErrorMessage = false;
  }

  async onLoginSubmit() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Click ‘OK’ to finalize the demographic changes you have made."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.parentMinorComp.onLoginSubmit
    );
    if (alertStatus) {
      const finalData = {
        email: this.loginObj.email,
        password: this.loginObj.password,
      };
      this.showLoader = true;
      return this.http
        .post(
          this.appConfig.getLambdaUrl02() + ApiHelper.name_superAdminLoginApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (res: any) => {
            this.showLoader = false;
            if (res.commandResult.status === 1) {
              if (res.commandResult.data.userInfo.IsActive == 1) {
                if (
                  res.commandResult.data.userInfo.Id != this.selectedMinorId
                ) {
                  this.wrongMinorErrorMessage = true;
                  return false;
                } else {
                  this.selectedMinorId = res.commandResult.data.userInfo.Id;
                  this.selectedMinorOtp = res.commandResult.data.userInfo.Otp;
                  this.showLoginForm = false;
                  this.showVerifyLoginOtpForm = true;
                }
              } else {
                this.accountErrorMessage = true;
              }

              //
            } else {
              this.formErrorMessage = true;
            }
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  onVerifyOtpSubmit() {
    this.createParentMinorRelation(
      this.selectedMinorId.toString(),
      this.loggedInUserId,
      this.loginObj.otp.toString().trim()
    );
  }

  createParentMinorRelation(minorId, parentId, otp) {
    const finalData = {
      minorId,
      parentId,
      otp,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getPhpUrl() +
          ApiHelper.php_createParentMinorRelationshipApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (res: any) => {
          if (res.commandResult.status == 1) {
            // alert("Parent-Minor relationship created succesfully.");
            this.showLoader = false;
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.parentMinorComp
                .createParentMinorRelation
            );
            if (alertStatus) window.location.reload();
          } else {
            this.verifyLoginFormErrorMessage = true;
          }
          this.showLoader = false;
        },
        (err) => {
          this.showLoader = false;
          console.error(err);
        }
      );
  }

  onMinorSearchInputChange(): void {
    // Check if the input is empty and update the error message accordingly
    if (this.minorSearchObj.minorSearchText.trim() === "") {
      this.searchUserCount = 1;
    }
  }
}

import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Router, ActivatedRoute} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {SignaturePad} from 'angular2-signaturepad';
import {ApiHelper} from '../../_helpers/api.helper';
import {CommonHelper} from '../../_helpers/common.helper';
import {AppConfig} from '../../app.config';
import {UserService} from '../../_services/user.service';
import * as moment from 'moment';
import {SweetAlertHelper} from '../../_helpers/sweet.alert.helper';
import {Message} from '../../_locale/messages';
import { navigateToRoute } from "./../../_helpers/emailDoctorExpertOpinionRouteBack.helper";

@Component({
  selector: 'app-la',
  templateUrl: './la.component.html',
  styleUrls: ['./la.component.scss'],
  providers: [AppConfig, CommonHelper],
})
export class LaComponent implements OnInit {
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  hippaJson: any = {};
  loggedInUserId: string;
  userSignatures: any = [];
  userSignatureSelected = false;
  userFullSignature = '';
  userInitialSignature = '';
  userChoosedSignature = '';
  userFullSignatureExit = false;
  userInitialSignatureExis = false;
  showPdfModal = false;
  // city = "";
  signatureType: any;
  showLoader = false;

  ssnPattern = '[0-9]{4}$';
  sendDocRoute = sessionStorage.getItem('sendDocRoute');
  resultJsonObj: any = {};
  clean = '0';
  @ViewChild(SignaturePad, {static: false}) signaturePad: SignaturePad;
  @ViewChild('canvasArea', {static: true}) canvasArea: ElementRef;
  canvasElm: any;
  public signaturePadOptions: Object = {
    minWidth: 1,
    canvasWidth:
      window.innerWidth < 1024 ? window.innerWidth : window.innerWidth / 2,
    canvasHeight: 450,
  };

  currentDate = new Date();
  dateFormat = 'MM-dd-yyyy';

  userWitnessSignatureSelected = false;
  userWitnessChoosedSignature = '';

  userAgencyRepresentSelected = false;
  userAgencyChoosedSignature = '';

  signatureFroWhom: any;
  isEmail: any;
  isPracticePhy: any;
  physicianId: any;
  requestId: any;
  tempId: any;
  userOptionId: any;
  emailLogId: any;
  message = Message;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public sweetAlertHelper: SweetAlertHelper,
    public userService: UserService
  ) {
    // alert(localStorage.getItem('delegateId'));
    this.loggedInUserId = localStorage.getItem('delegateId')
      ? localStorage.getItem('delegateId')
      : this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.isEmail = this.activatedRoute.snapshot.paramMap.get('isEmail')
      ? this.activatedRoute.snapshot.paramMap.get('isEmail')
      : 0;
    this.isPracticePhy =
      this.activatedRoute.snapshot.paramMap.get('isPracticePhy');
    this.physicianId = this.activatedRoute.snapshot.paramMap.get('physicianId')
      ? this.activatedRoute.snapshot.paramMap.get('physicianId')
      : 0;
    this.requestId = this.activatedRoute.snapshot.paramMap.get('requestId')
      ? this.activatedRoute.snapshot.paramMap.get('requestId')
      : 0;

    this.tempId = this.activatedRoute.snapshot.paramMap.get('tempId')
      ? this.activatedRoute.snapshot.paramMap.get('tempId')
      : 0;
    this.userOptionId = this.activatedRoute.snapshot.paramMap.get(
      'userOptionId'
    )
      ? this.activatedRoute.snapshot.paramMap.get('userOptionId')
      : 0;
    this.emailLogId = this.activatedRoute.snapshot.paramMap.get('emailLogId')
      ? this.activatedRoute.snapshot.paramMap.get('emailLogId')
      : 0;

    this.hippaJson.healthcare_provider = this.userService.getUS_FromatName(
      localStorage.getItem('providerName')
    );
    this.hippaJson.healthcare_provider_email =
      localStorage.getItem('providerEmail');
    this.hippaJson.healthcare_provider_npi = localStorage.getItem(
      'providerPhysicianNipNo'
    );
    this.hippaJson.street_address = localStorage.getItem(
      'providerOfficeAddress'
    );
    // this.hippaJson.city_zip = "Solapur";
    // this.city = localStorage.getItem("providerCity");
    // this.hippaJson.city_zip = this.city;
    this.hippaJson.city_statet_zip = localStorage.getItem('providerCity')
      ? localStorage.getItem('providerCity')
      : '' + ', ' + localStorage.getItem('PhysicianStateName')
        ? localStorage.getItem('PhysicianStateName')
        : '' + ', ' + localStorage.getItem('providerZipCode')
          ? localStorage.getItem('providerZipCode')
          : '';
    this.hippaJson.city_zip = localStorage.getItem('providerCity');
    this.hippaJson.providerCity = localStorage.getItem('providerCity');
    this.hippaJson.providerPhone = localStorage.getItem('providerPhone');
    this.hippaJson.patientPhone = localStorage.getItem('patientPhone');
    this.hippaJson.providerPhysicianNipNo = localStorage.getItem(
      'providerPhysicianNipNo'
    );
    this.hippaJson.city_statet_zip = localStorage.getItem('selectedCityName')
      ? localStorage.getItem('selectedCityName')
      : '' + ', ' + localStorage.getItem('selectedHippaState')
        ? localStorage.getItem('selectedHippaState')
        : '' + ', ' + localStorage.getItem('providerZipCode')
          ? localStorage.getItem('providerZipCode')
          : '';

    this.hippaJson.healthcare_provider_city = localStorage.getItem('selectedCityName');
    console.log(this.hippaJson.healthcare_provider_city);
    this.hippaJson.healthcare_provider_state = localStorage.getItem('selectedHippaState');
    console.log(this.hippaJson.healthcare_provider_state);
    this.hippaJson.healthcare_provider_zip = localStorage.getItem('providerZipCode');
    console.log(this.hippaJson.healthcare_provider_zip);
    this.hippaJson.patient_name = this.userService.getUS_FromatName(localStorage.getItem('loggedInUserName'));
    this.hippaJson.telephone_no = localStorage.getItem('providerPhone');

    this.hippaJson.city_statet_zip = this.hippaJson.healthcare_provider_city + ' ' + this.hippaJson.healthcare_provider_state + ' ' + this.hippaJson.healthcare_provider_zip;
    console.log(this.hippaJson.city_statet_zip);
    this.fnFindSignature(this.loggedInUserId);
    this.fnFindDemographic(this.loggedInUserId);
    this.findUserDetails(this.loggedInUserId);

    this.hippaJson.patient_signature_date = this.currentDate;
    this.hippaJson.witness_signature_date = this.currentDate;
    this.hippaJson.pre_to = this.currentDate;
    this.hippaJson.post_to = this.currentDate;
  }

  ngOnInit() {
    // console.log(localStorage);
    this.hippaJson.checkbox_01 = false;
    this.hippaJson.checkbox_02 = false;
    this.hippaJson.checkbox_03 = false;
    this.hippaJson.checkbox_04 = false;
    this.hippaJson.checkbox_05 = false;
    this.hippaJson.checkbox_06 = false;
    this.hippaJson.checkbox_07 = false;
    this.hippaJson.checkbox_08 = false;
    this.hippaJson.checkbox_09 = false;
    this.hippaJson.checkbox_10 = false;
    this.hippaJson.checkbox_11 = false;
    this.hippaJson.checkbox_12 = false;
    this.hippaJson.checkbox_13 = false;
    this.hippaJson.checkbox_14 = false;
    this.hippaJson.checkbox_15 = false;
    this.hippaJson.checkbox_16 = false;
    this.hippaJson.checkbox_17 = false;
    this.hippaJson.checkbox_18 = false;
    this.hippaJson.checkbox_19 = false;
    this.hippaJson.checkbox_20 = false;
    this.hippaJson.checkbox_21 = false;
    this.hippaJson.checkbox_22 = false;
    this.hippaJson.checkbox_23 = false;
    this.hippaJson.checkbox_24 = false;
    this.hippaJson.checkbox_25 = false;
    this.hippaJson.checkbox_26 = false;
    this.hippaJson.checkbox_27 = false;
    this.hippaJson.checkbox_28 = false;
    this.hippaJson.checkbox_29 = false;
    this.hippaJson.patient_signature = '';
    this.hippaJson.witness_signature = '';
  }

  findUserDetails(userId) {
    const finalData = {
      company_slug: null,
      id: userId,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status == 1) {
            this.hippaJson.patient_name =
              apiResponse.commandResult.data.userInfo.LastName +
              ', ' +
              apiResponse.commandResult.data.userInfo.FirstName;
            this.hippaJson.mailing_address =
              apiResponse.commandResult.data.userInfo.Email;
            // this.editDdemographicsformDataObj.fName = this.userService.getFirstName(
            //   apiResponse.commandResult.data.userInfo.FullName
            // );
            // this.editDdemographicsformDataObj.lName = this.userService.getLastName(
            //   apiResponse.commandResult.data.userInfo.FullName
            // );
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindSignature(user_id) {
    const reqData = {
      user_id,
    };
    return this.http
      .post(
        // this.appConfig.getPhpUrl() + ApiHelper.php_findUserSignatureApi,
        this.appConfig.getLambdaUrl06() + ApiHelper.findUserSignature,
        reqData,
        this.httpOptions
      )
      .subscribe(
        (response: any) => {
          if (response.commandResult.success == 1) {
            this.userSignatures = response.commandResult.data.userSignatures;
            this.userFullSignature =
              response.commandResult.data.userSignatures.full_signature_url;
            this.userInitialSignature =
              response.commandResult.data.userSignatures.initial_signature_url;

            if (response.commandResult.data.userSignatures.full_signature_url) {
              this.userFullSignatureExit = true;
            }

            if (
              response.commandResult.data.userSignatures.initial_signature_url
            ) {
              this.userInitialSignatureExis = true;
            }
          } else {
          }

          // console.log(this.userSignatures);
        },
        (err) => {
        }
      );
  }

  async onSubmit() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(this.message.confirmTitle, this.message.confirmMessages.njComp.onSubmit);
    if (alertStatus) {
      const reqData = {
        healthcare_provider: this.hippaJson.healthcare_provider,
        date: this.hippaJson.date,
        ssn_no: this.hippaJson.ssn_no,
        healthcare_provider_email: this.hippaJson.healthcare_provider_email,
        healthcare_provider_npi: this.hippaJson.healthcare_provider_npi,
        street_address: this.hippaJson.street_address,
        other_specify: this.hippaJson.other_specify,
        other: this.hippaJson.other,
        agency_signature_date: this.hippaJson.agency_signature_date,
        agency_representative: this.hippaJson.agency_representative,
        other_info: this.hippaJson.other_info,
        expire_on: this.hippaJson.expire_on,
        period_begining: this.hippaJson.period_begining,
        ending: this.hippaJson.ending,
        request_date: this.hippaJson.request_date,
        city_zip: this.hippaJson.city_zip,
        mailing_address: this.hippaJson.mailing_address,
        name_patient: this.hippaJson.healthcare_provider,
        patient_name: this.hippaJson.patient_name,
        mailaddress: this.hippaJson.mailaddress,
        city_statet_zip: this.hippaJson.city_statet_zip,
        relation: this.hippaJson.relation,
        tele_no: this.hippaJson.tele_no,
        mail_address: this.hippaJson.mail_address,
        citystatetzip: this.hippaJson.citystatetzip,
        relationship: this.hippaJson.relationship,
        telephone_no: this.hippaJson.telephone_no,
        dob: moment(new Date(this.hippaJson.dob.toString())).format('MM-DD-YYYY'),
        social_security_no: this.hippaJson.social_security_no,
        purposes: this.hippaJson.purposes,
        representative_name: this.hippaJson.representative_name,
        representative_capacity: this.hippaJson.representative_capacity,
        representative_street_address:
        this.hippaJson.representative_street_address,
        representative_city_zip: this.hippaJson.representative_city_zip,
        patient_signature: this.userChoosedSignature,
        patient_signature_date: moment(
          new Date(this.hippaJson.patient_signature_date.toString())
        ).format('MM-DD-YYYY'),
        name_relation: this.hippaJson.name_relation,
        witness_signature: this.userWitnessChoosedSignature,

        witness_signature_date: moment(
          new Date(this.hippaJson.witness_signature_date.toString())
        ).format('MM-DD-YYYY'),
        pre_to: moment(new Date(this.hippaJson.pre_to.toString())).format(
          'MM-DD-YYYY'
        ),
        post_to: moment(new Date(this.hippaJson.post_to.toString())).format(
          'MM-DD-YYYY'
        ),
        checkbox_01: this.hippaJson.checkbox_01,
        checkbox_02: this.hippaJson.checkbox_02,
        checkbox_03: this.hippaJson.checkbox_03,
        checkbox_04: this.hippaJson.checkbox_04,
        checkbox_05: this.hippaJson.checkbox_05,
        checkbox_06: this.hippaJson.checkbox_06,
        checkbox_07: this.hippaJson.checkbox_07,
        checkbox_08: this.hippaJson.checkbox_08,
        checkbox_09: this.hippaJson.checkbox_09,
        checkbox_10: this.hippaJson.checkbox_10,
        checkbox_11: this.hippaJson.checkbox_11,
        checkbox_12: this.hippaJson.checkbox_12,
        checkbox_13: this.hippaJson.checkbox_13,
        checkbox_14: this.hippaJson.checkbox_14,
        checkbox_15: this.hippaJson.checkbox_15,
        checkbox_16: this.hippaJson.checkbox_16,
        checkbox_17: this.hippaJson.checkbox_17,
        checkbox_18: this.hippaJson.checkbox_18,
        checkbox_19: this.hippaJson.checkbox_19,
        checkbox_20: this.hippaJson.checkbox_20,
        checkbox_21: this.hippaJson.checkbox_21,
        checkbox_22: this.hippaJson.checkbox_22,
        checkbox_23: this.hippaJson.checkbox_23,
        checkbox_24: this.hippaJson.checkbox_24,
        checkbox_25: this.hippaJson.checkbox_25,
        checkbox_26: this.hippaJson.checkbox_26,
        checkbox_27: this.hippaJson.checkbox_27,
        checkbox_28: this.hippaJson.checkbox_28,
        checkbox_29: this.hippaJson.checkbox_29,

        loggedInUserId: this.loggedInUserId,
        isEmail: this.isEmail,
        requestId: this.requestId,
        phyAltEmail: sessionStorage.getItem('phyAltEmail')
      };
      this.showLoader= true;
      return this.http
        .post(
          this.appConfig.getLambdaUrl06() + ApiHelper.generateHipaaLA,
          reqData,
          this.httpOptions
        )
        .subscribe(
          (response: any) => {
            if (this.isEmail == 1) {
              this.router.navigate(['/send-doc/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId') + '/1/' + this.isPracticePhy + '/' + this.physicianId + '/' + response.commandResult.message]);
            } else if (this.isEmail == 3) {
              this.router.navigate(['/expert-physician-opinion/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId') + '/' + this.userOptionId + '/1/' + this.tempId + '/' + response.commandResult.message]);
            } else if (this.isEmail == 4) {
              this.router.navigate(['/resend-docs/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId') + '/' + this.emailLogId + '/1/' + response.commandResult.message + '/' + this.isPracticePhy + '/' + this.physicianId]);
            } else {
              if (window.open(response.commandResult.data, '_blank')) {
                this.router.navigate(['/dashboard/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId')]);
              }
            }
            this.showLoader= false;
          },


          (err) => {
          }
        );
    }
  }

  async onReset() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.ushfComp.onReset
    );
    if (alertStatus) {
      this.router.navigate([
        '/dashboard/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId'),
      ]);
    }
  }

  async goBack() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.goBack
    );
    if (alertStatus) {
      if (this.sendDocRoute) {
        const loggedInUserId =
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
          const route = navigateToRoute(
          this.isEmail,
          loggedInUserId,
          this.isPracticePhy,
          this.physicianId,
          this.userOptionId,
          this.tempId,
          this.emailLogId
        );
        console.log("route ", route);
        this.router.navigate([route]);
        sessionStorage.removeItem("sendDocRoute");
      } else {
        this.router.navigate([
          '/ushf/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId')
        ]);
      }
    }
  }

  closePdfModal() {
    this.showPdfModal = false;
    this.hippaJson.patient_signature = '';
  }

  openSignatureCanvas(selectedValue, signatureFroWhom) {
    this.signatureFroWhom = signatureFroWhom;
    // console.log(selectedValue);
    this.signatureType = selectedValue;
    if (
      selectedValue == 'AFS' ||
      selectedValue == 'AIS' ||
      selectedValue == 'AWTSG' ||
      selectedValue == 'AAGC'
    ) {
      this.showPdfModal = true;
    }
    if (selectedValue == 'FS' || selectedValue == 'IS') {
      this.userSignatureSelected = true;
      if (selectedValue == 'FS') {
        this.userChoosedSignature = this.userFullSignature;
      }
      if (selectedValue == 'IS') {
        this.userChoosedSignature = this.userInitialSignature;
      }
    }
  }

  ngAfterViewInit() {
    // this.signaturePad is now available
    this.signaturePad.set('minWidth', 1); // set szimek/signature_pad options at runtime
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    // console.log(this.signaturePad.toDataURL());
  }

  async saveCanvas() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: This will replace your existing signature. Do you wish to change?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.saveCanvas
    );
    if (alertStatus) {
      const reqData = {
        loggedInUserId: this.loggedInUserId,
        signatureBase64: this.signaturePad.toDataURL(),
        signatureType: this.signatureType,
      };
      return this.http
        .post(
          this.appConfig.getLambdaUrl05() +
          ApiHelper.name_saveSignatureImageApi,
          reqData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse: any) => {
            if (apiResponse.commandResult.status == 1) {
              this.userSignatureSelected = true;
              this.userChoosedSignature =
                apiResponse.commandResult.data.fileInfo.signatureUrl;
              // console.log(this.userChoosedSignature);
              this.fnFindSignature(this.loggedInUserId);
              this.showPdfModal = false;
              // this.hippaJson.patient_signature = '';
            }
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  async clearCanvas() {
    // if (
    //   confirm("PLEASE CONFIRM: Are you sure you want to clear you signature.")
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.clearCanvas
    );
    if (alertStatus) {
      this.signaturePad.clear();
    }
  }

  drawStart() {
  }

  fnFindDemographic(user_id) {
    const finalData = {
      id: user_id,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
        ApiHelper.name_findUserDemographicGeneralApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (response: any) => {
          // alert(response.commandResult.data.userInfo.form1City);
          this.hippaJson.dob = new Date(
            response.commandResult.data.userInfo.form1DOB
          );
          this.hippaJson.representative_city_zip =
            response.commandResult.data.userInfo.form1City;

          this.hippaJson.patient_city =
            response.commandResult.data.userInfo.form1City;
          this.hippaJson.patient_zip =
            response.commandResult.data.userInfo.form1ZIP;
          this.hippaJson.patient_state =
            response.commandResult.data.userInfo.form1State;
          this.hippaJson.city_zip =
            this.hippaJson.patient_city +
            ',' +
            this.hippaJson.patient_state +
            ',' +
            this.hippaJson.patient_zip;

          // this.hippaJson.city_zip = response.commandResult.data.userInfo.form1PrimaryPhysicianCity + ', ' + response.commandResult.data.userInfo.form1State + ', ' + response.commandResult.data.userInfo.form1ZIP;

          // this.hippaJson.street_address = response.commandResult.data.userInfo.form1StreetName;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  autoFormatSSN() {
    // alert('Amit');
    const val = this.hippaJson.ssn_no.replace(/\D/g, '');
    // val = val.replace(/^(\d{3})/, "$1-");
    // val = val.replace(/-(\d{2})/, "-$1-");
    // val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
    this.hippaJson.ssn_no = val;
  }

  saveParentCanvas(signFor) {
    const reqData = {
      loggedInUserId: this.loggedInUserId,
      signatureBase64: this.signaturePad.toDataURL(),
      signatureType: this.signatureType,
      signFor,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl05() +
        ApiHelper.name_saveParentSignatureImageApi,
        reqData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status == 1) {
            if (signFor == 'WTSG') {
              this.userWitnessSignatureSelected = true;
              this.userWitnessChoosedSignature =
                apiResponse.commandResult.data.fileInfo.signatureUrl;
            }
            if (signFor == 'AGC') {
              this.userAgencyRepresentSelected = true;
              this.userAgencyChoosedSignature =
                apiResponse.commandResult.data.fileInfo.signatureUrl;
            }
            this.showPdfModal = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }
}

import { DualListComponent } from 'angular-dual-listbox';
import { Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../_services/user.service';

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { ApiHelper } from './../../_helpers/api.helper';
import { CommonHelper } from './../../_helpers/common.helper';
import { ConstantHelper } from './../../_helpers/constant.helper';
import { AppConfig } from './../../../app/app.config';
import { UploadService } from '../../_services/upload.service';
import { SweetAlertHelper } from '../../_helpers/sweet.alert.helper';
import { Message } from '../../_locale/messages';

@Component({
  selector: 'app-choose-doctors',
  templateUrl: './choose-doctors.component.html',
  styleUrls: ['./choose-doctors.component.scss'],
})
export class ChooseDoctorsComponent implements OnInit {
  loggedInUserId: string;
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  createNewGroup = false;
  documentsLength = false;
  documentsList: any;
  documentsLengthRight = false;
  documentsListRight: any = [];
  groupsLength = false;
  groupsList: any;
  selectedDocGroupId: any = 0;
  groupName = '';

  filterString = '';
  searchedPhysician = '';
  searchedPhysicianArr: any[] = [];
  showLoader = false;
  noPhysicianFound = false;
  existingUserIds: any = [];
  confirmedIds: any = [];
  companiesList: any = [];
  physicianPracticeSlug = '';
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  message = Message;
  allPhysiciansList: any = [];
  selectedPhysician = '';
  isSearch = true;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef,
    private uploadService: UploadService,
    public sweetAlertHelper: SweetAlertHelper,
    public userService: UserService
  ) {
  }

  tab = 1;
  keepSorted = true;
  key: string;
  display: any;
  filter = false;
  source: Array<any>;
  confirmed: Array<any>;
  filterSet: Array<any>;

  userAdd = '';
  disabled = false;
  groupType = '';
  sourceLeft = true;
  format = {
    add: 'Choose Physician',
    remove: 'Remove Physician',
    all: 'Select all',
    none: 'Unselect all',
    direction: 'left-to-right',
    draggable: true,
    locale: undefined,
  };

  selectedDoc: any;

  private sourceTube: Array<string>;
  private sourceStations: Array<any>;
  private sourceChessmen: Array<any>;

  private confirmedTube: Array<string>;
  private confirmedStations: Array<any>;
  private confirmedChessmen: Array<any>;

  private chessmen = [];

  arrayType = [
    { name: 'Chessmen', detail: '(object array)', value: 'chess' },
    // { name: 'Rio Grande', detail: '(object array)', value: 'station' },
    // { name: 'Underground', detail: '(string array)', value: 'tube' }
  ];

  type = this.arrayType[0].value;

  private stations: Array<any> = [
    { key: 1, station: 'Antonito', state: 'CO' },
    { key: 2, station: 'Big Horn', state: 'NM' },
    { key: 3, station: 'Sublette', state: 'NM' },
    { key: 4, station: 'Toltec', state: 'NM' },
    { key: 5, station: 'Osier', state: 'CO' },
    { key: 6, station: 'Chama', state: 'NM' },
    { key: 7, station: 'Monero', state: 'NM' },
    { key: 8, station: 'Lumberton', state: 'NM' },
    { key: 9, station: 'Duice', state: 'NM' },
    { key: 10, station: 'Navajo', state: 'NM' },
    { key: 11, station: 'Juanita', state: 'CO' },
    { key: 12, station: 'Pagosa Jct', state: 'CO' },
    { key: 13, station: 'Carracha', state: 'CO' },
    { key: 14, station: 'Arboles', state: 'CO' },
    { key: 15, station: 'Solidad', state: 'CO' },
    { key: 16, station: 'Tiffany', state: 'CO' },
    { key: 17, station: 'La Boca', state: 'CO' },
    { key: 18, station: 'Ignacio', state: 'CO' },
    { key: 19, station: 'Oxford', state: 'CO' },
    { key: 20, station: 'Florida', state: 'CO' },
    { key: 21, station: 'Bocea', state: 'CO' },
    { key: 22, station: 'Carbon Jct', state: 'CO' },
    { key: 23, station: 'Durango', state: 'CO' },
    { key: 24, station: 'Home Ranch', state: 'CO' },
    { key: 25, station: 'Trimble Springs', state: 'CO' },
    { key: 26, station: 'Hermosa', state: 'CO' },
    { key: 27, station: 'Rockwood', state: 'CO' },
    { key: 28, station: 'Tacoma', state: 'CO' },
    { key: 29, station: 'Needleton', state: 'CO' },
    { key: 30, station: 'Elk Park', state: 'CO' },
    { key: 31, station: 'Silverton', state: 'CO' },
    { key: 32, station: 'Eureka', state: 'CO' },
  ];

  private tube: Array<string> = [
    'Harrow & Wealdstone',
    'Kenton',
    'South Kenton',
    'North Wembley',
    'Wembley Central',
    'Stonebridge Park',
    'Harlesden',
    'Willesden Junction',
    'Kensal Green',
    'Queen\'s Park',
    'Kilburn Park',
    'Maida Vale',
    'Warwick Avenue',
    'Paddington',
    'Edgware Road',
    'Marylebone',
    'Baker Street',
    'Regent\'s Park',
    'Oxford Circus',
    'Piccadilly Circus',
    'Charing Cross',
    'Embankment',
    'Waterloo',
    'Lambeth North',
    'Elephant & Castle',
  ];

  somedata: any = [
    { RecordId: 1, DocumentName: 'Pawn' },
    { RecordId: 2, DocumentName: 'Rook' },
    { RecordId: 3, DocumentName: 'Knight' },
    { RecordId: 4, DocumentName: 'Bishop' },
    { RecordId: 5, DocumentName: 'Queen' },
    { RecordId: 6, DocumentName: 'King' },
  ];
  onBehalfOfAlertChooseDoctors = 'Y';

  async ngAfterViewInit() {
    var item = localStorage.getItem('FullName');
    if (
      this.onBehalfOfAlertChooseDoctors === 'Y' &&
      localStorage.getItem('isDelegating') === '1'
    ) {
      // alert(
      //   "Dear User, you are doing this activity on behalf of " +
      //     localStorage.getItem("FullName")
      // );
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.demographics.ngAfterViewInit + ' ' + item
      );
      localStorage.setItem('onBehalfOfAlertChooseDoctors', 'N');
    }
  }

  ngOnInit() {
    this.loggedInUserId = localStorage.getItem('delegateId')
      ? localStorage.getItem('delegateId')
      : this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.findUserDoctors();
    this.findCompanies();
    this.onBehalfOfAlertChooseDoctors = localStorage.getItem(
      'onBehalfOfAlertChooseDoctors'
    )
      ? localStorage.getItem('onBehalfOfAlertChooseDoctors')
      : 'Y';
    window.scroll(0, 0)
  }

  private stationLabel(item: any) {
    return item.station + ', ' + item.state;
  }

  private useStations() {
    this.key = 'key';
    this.display = this.stationLabel;
    this.keepSorted = true;
    this.source = this.sourceStations;
    this.filterSet = this.sourceStations;
    this.confirmed = this.confirmedStations;
  }

  private useChessmen() {
    this.key = 'physician_id';
    this.display = 'physician_full_name';
    this.keepSorted = false;
    this.source = this.sourceChessmen;
    this.filterSet = this.sourceChessmen;
    this.confirmed = this.confirmedChessmen;
  }

  private useTube() {
    this.key = undefined;
    this.display = undefined;
    this.keepSorted = false;
    this.source = this.sourceTube;
    this.filterSet = this.sourceTube;
    this.confirmed = this.confirmedTube;
  }

  swapSource() {
    switch (this.type) {
      case this.arrayType[0].value:
        this.useChessmen();
        break;
      case this.arrayType[1].value:
        this.useStations();

        break;
      case this.arrayType[2].value:
        this.useTube();
        break;
    }
  }

  doReset() {
    this.sourceChessmen = JSON.parse(JSON.stringify(this.chessmen));

    // console.log('sourceChessmen=>', this.sourceChessmen)
    this.sourceStations = JSON.parse(JSON.stringify(this.stations));
    this.sourceTube = JSON.parse(JSON.stringify(this.tube));

    // console.log('sourceTube=>', this.sourceTube);

    this.confirmedChessmen = new Array<any>();
    this.confirmedStations = new Array<any>();
    this.confirmedTube = new Array<string>();
    this.confirmedStations.push(this.stations[31]);
    this.confirmedTube.push(this.tube[13]);
    this.confirmedTube.push(this.tube[23]);
    switch (this.type) {
      case this.arrayType[0].value:
        this.useChessmen();
        break;
      case this.arrayType[1].value:
        this.useStations();
        break;
      case this.arrayType[2].value:
        this.useTube();
        break;
    }
  }

  doReset1() {
    this.sourceChessmen = JSON.parse(JSON.stringify(this.chessmen));
    this.sourceStations = JSON.parse(JSON.stringify(this.stations));
    this.sourceTube = JSON.parse(JSON.stringify(this.tube));
    this.confirmedChessmen = new Array<any>();
    this.confirmedStations = new Array<any>();
    this.confirmedTube = new Array<string>();
    this.confirmedStations.push(this.stations[31]);
    this.confirmedTube.push(this.tube[13]);
    this.confirmedTube.push(this.tube[23]);

    switch (this.type) {
      case this.arrayType[0].value:
        this.useChessmen();
        break;
      case this.arrayType[1].value:
        this.useStations();
        break;
      case this.arrayType[2].value:
        this.useTube();
        break;
    }
    this.confirmed = this.documentsListRight;
  }

  doDelete() {
    if (this.source.length > 0) {
      this.source.splice(0, 1);
    }
  }

  doCreate() {
    if (typeof this.source[0] === 'object') {
      const o = {};
      o[this.key] = this.source.length + 1;
      o[this.display] = this.userAdd;
      this.source.push(o);
    } else {
      this.source.push(this.userAdd);
    }
    this.userAdd = '';
  }

  doAdd() {
    for (let i = 0, len = this.source.length; i < len; i += 1) {
      const o = this.source[i];
      const found = this.confirmed.find((e: any) => e === o);
      if (!found) {
        this.confirmed.push(o);
        break;
      }
    }
  }

  doRemove() {
    if (this.confirmed.length > 0) {
      this.confirmed.splice(0, 1);
    }
  }

  doFilter() {
    this.filter = !this.filter;
  }

  filterBtn() {
    return this.filter ? 'Hide Filter' : 'Show Filter';
  }

  doDisable() {
    this.disabled = !this.disabled;
  }

  disableBtn() {
    return this.disabled ? 'Enable' : 'Disabled';
  }

  swapDirection() {
    this.sourceLeft = !this.sourceLeft;
    this.format.direction = this.sourceLeft
      ? DualListComponent.LTR
      : DualListComponent.RTL;
  }

  async goToDashboard() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM:   By clicking ‘Cancel’: Returning to the ‘Home Page’ is cancelled where adding a new doctor to your Approved Physician List is available."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.alertPopUp(this.message.alertMessages.chooseDocComp.CancelChanges)
    if (alertStatus) {
      // this.router.navigate(['/dashboard/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId') + '/1',]);
      window.location.reload();
    }
  }

  findCompanies1() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
        ApiHelper.name_findAllRegisteredPhysiciansApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.data.companiesList.length > 0) {
            this.documentsLength = true;
            this.documentsList = apiResponse.commandResult.data.companiesList;
            this.chessmen = this.documentsList;
            this.doReset();
            // this.findUserDoctors1();
          } else {
            this.documentsLength = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findUserDoctors1() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
        ApiHelper.name_findUserRegisteredPhysiciansApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.data.companiesList.length > 0) {
            this.documentsLengthRight = true;
            this.documentsListRight =
              apiResponse.commandResult.data.companiesList;
            this.confirmed = this.documentsListRight;
            this.chessmen = this.documentsListRight;

            // console.log('confirmed doctors', this.confirmed);
          } else {
            this.confirmed = [];
            this.documentsLengthRight = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findUserDoctors() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.name_findUserDoctorsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.data.companiesList.length > 0) {
            this.documentsLength = true;
            this.documentsList = apiResponse.commandResult.data.companiesList;
            if (this.documentsList.length > 0) {
              for (let i = 0; i <= this.documentsList.length - 1; i++) {
                this.existingUserIds.push(this.documentsList[i].physician_user_id);
              }
            }
            this.chessmen = this.documentsList;
            this.doReset();
          } else {
            this.documentsLength = false;
          }
          this.findUserDoctors2s();
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findUserDoctors2s() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.name_findUserDoctorsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.data.companiesList.length > 0) {
            this.documentsLengthRight = true;
            this.documentsListRight =
              apiResponse.commandResult.data.companiesList;
            this.confirmed = this.documentsListRight;
            // this.doReset();
          } else {
            this.documentsListRight = [];
            this.documentsLengthRight = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async saveDocGroup() {
    if (this.confirmed.length > 0) {
      for (let i = 0; i <= this.confirmed.length - 1; i++) {
        this.confirmedIds.push(this.confirmed[i].physician_user_id);
      }
    }
    if (this.existingUserIds.length > 0 && this.confirmedIds.sort().join(",") === this.existingUserIds.sort().join(",")) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.chooseDocComp.noDoctorAdded
      );
      return false;
    }
    if (this.confirmed.length <= 0) {
      // alert("Please select at least one doctor.");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.chooseDocComp.saveDocGroup
      );
      return false;
    } else {
      // if (
      //   confirm(
      //     "PLEASE CONFIRM: Please select any of your treating doctors or practices that are listened in our system to approve. Clicking ‘OK’ confirms you have already signed a HIPAA Form for the selected doctor or practice.  If not, click ‘Cancel’"
      //   )
      // )
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.chooseDocComp.saveDocGroup
      );
      if (alertStatus) {
        const finalData = {
          userId: this.loggedInUserId,
          docsArray: this.confirmed,
          isDelegating: localStorage.getItem('isDelegating'),
          delegateId: localStorage.getItem('delegateId'),
          parentId: localStorage.getItem('parentId'),
        };
        this.showLoader = true;
        this.http
          .post(
            // this.appConfig.getPhpUrl() + ApiHelper.php_saveUserDoctorsApi,
            this.appConfig.getLambdaUrl06() + ApiHelper.saveUserDoctors,
            finalData,
            this.httpOptions
          )
          .subscribe(
            async (apiResponse: any) => {
              this.showLoader = false;
              // console.log(apiResponse);
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.chooseDocComp.saveDocGroup1
              );
              // alert("Doctors saved");
              if (alertStatus) {
                this.router.navigate([
                  '/dashboard/' +
                  this.activatedRoute.snapshot.paramMap.get('loggedInUserId'),
                ]);
              }
            },
            (err) => {
              // console.log(err);
            }
          );
      }
    }
  }

  async goToDashboad() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM:   By clicking ‘OK’: You are returning to the ‘Home Page’ where adding a new doctor to your Approved Physician List is available."
    //   )
    // )
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.ushfComp.onReset
    // );
    // if (alertStatus) {
      this.router.navigate([
        '/dashboard/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId'),
      ]);
    // }
  }

  filterDoctorList(filterKey) {
    // console.log(this.filterSet);
    const matches = this.filterSet.filter((s) =>
      s.physician.toLowerCase().includes(filterKey.toLowerCase())
    );
    this.source = matches;
  }


  async searchDoctors() {
    if (this.physicianPracticeSlug === undefined || !this.physicianPracticeSlug) {
      await this.sweetAlertHelper.alertPopUp('Practice is required.');
      return false;
    } else if (this.filterString === undefined || this.filterString.length <= 0) {
      await this.sweetAlertHelper.alertPopUp('Physician Name is required.');
      return false;
    } else {
      const finalData = {
        loggedInUserId: this.loggedInUserId,
        filterString: this.filterString,
        companySlug: this.physicianPracticeSlug,
        isSearch: false
      };
      this.showLoader = true;
      this.http
        .post(
          this.appConfig.getLambdaUrl05() + ApiHelper.searchPracticePhysicians,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse: any) => {
            this.showLoader = false;
            if (apiResponse.commandResult.data.physicians.length > 0) {
              this.searchedPhysicianArr =
                apiResponse.commandResult.data.physicians;
              this.noPhysicianFound = false;
            } else {
              this.noPhysicianFound = true;
            }
          },
          (err) => {
            // console.log(err);
          }
        );

    }
  }

  async getAllDoctors() {
    this.allPhysiciansList = [];
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      filterString: this.filterString,
      companySlug: this.physicianPracticeSlug,
      isSearch: true
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.searchPracticePhysicians,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.showLoader = false;
          if (apiResponse.commandResult.data.physicians.length > 0) {
            this.allPhysiciansList = apiResponse.commandResult.data.physicians;
            this.noPhysicianFound = false;
          } else {
            this.noPhysicianFound = true;
          }
        },
        (err) => {
        }
      );
  }


  addPractice(physicianId) {
    const physicianObj = this.searchedPhysicianArr.find(
      (e) => e.physician_id == physicianId
    );
    console.log('Physician Object2:', physicianObj);

    // Check for a matching physician_id in the confirmed array
    const isPhysicianInConfirmed = this.confirmed.some(
    (item) => item.physician_id == physicianObj.physician_id
    );

    if (isPhysicianInConfirmed) {
      // Show pop-up and refresh the page
      this.sweetAlertHelper.alertPopUp("Physician already exist's in your Doctor List")
        .then(() => {
           this.searchedPhysician = '';
        });
    }else {

      if (
        physicianObj.company_url == 'other' &&
        physicianObj.patient_count >= 5
      ) {
        this.informDoctor(physicianObj.physician_id);
      } else {
        this.chessmen.push(physicianObj);
        this.doReset1();
      }
    }
  }

  addPhysicianToList(physicianId) {
    const physicianObj = this.allPhysiciansList.find(
      (e) => e.physician_id == physicianId
    );
  
    // Ensure physicianObj is found
    if (!physicianObj) {
      console.error('Physician not found with ID:', physicianId);
      return;
    }
  
    // Manually check if this.confirmed is defined
    let isPhysicianInConfirmed = false;
    if (this.confirmed && this.confirmed.length > 0) {
      isPhysicianInConfirmed = this.confirmed.some(
        (item) => item.physician_id == physicianObj.physician_id
      );
    }
  
    if (isPhysicianInConfirmed) {
      // Show pop-up and refresh the page
      this.sweetAlertHelper.alertPopUp("Physician already exist's in your Doctor List")
        .then(() => {
          // Refresh the page
          this.selectedPhysician = '';
        });
    } else {
      if (
        physicianObj.company_url == 'other' &&
        physicianObj.patient_count >= 5
      ) {
        this.informDoctor(physicianObj.physician_id);
      } else {
        this.chessmen.push(physicianObj);
        this.doReset1();
      }
    }
  }
  

  informDoctor(physicianId) {
    const finalData = {
      physicianId,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.informPhysician,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (res: any) => {
          this.showLoader = false;
          const alertStatus = await this.sweetAlertHelper.alertPopUp(
            this.message.alertMessages.chooseDocComp.informDoctor
          );
          // alert("Physician's Patient quota exceeded.");
        },
        (err) => {
          this.showLoader = false;
          // console.log(err);
        }
      );
  }


  findCompanies() {
    const finalData = {
      loggedInUserId: '',
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.name_findCompaniesListApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.companiesList = apiResponse.commandResult.data.companiesList;
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }
  onPracticeSelectionChange() {
    this.searchedPhysicianArr = []; // Clear the physician list
    this.filterString = ''; // Clear the input value
    this.isSearch = true;
  }
  onPhysicianSelectionChange() {
    this.searchedPhysicianArr = [];
  }

  clearSearch() {
    this.filterString = ''; // Clear the search input value
    this.noPhysicianFound = false; // Hide the message when the search is cleared
  }

  clearfilterString() {
    this.filterString = '';
    this.noPhysicianFound = false;
    // clear if selected through Registered Doctor List  
    if (!this.selectedPhysician.length || this.filterString == '') {
      // Clear only when there's no search input and no searched results
      // Clear the selected physician(s)
      this.searchedPhysicianArr = []; // Clear the displayed options
    }

  }


}

import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UserService } from "../../_services/user.service";
import { DoctorMailService } from "../../_services/doctor-mail.service";
import { SweetAlertHelper } from "../../_helpers/sweet.alert.helper";
import { Message } from "../../_locale/messages";

@Component({
  selector: "app-expert-physician-opinion",
  templateUrl: "./expert-physician-opinion.component.html",
  styleUrls: ["./expert-physician-opinion.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class ExpertPhysicianOpinionComponent implements OnInit {
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };

  @ViewChild("fileUploadedHippa", { static: false })
  fileUploadedHippa: ElementRef;

  companySlug: string;

  loggedInUserId: string;
  userOptionId: string;

  hasPhysicianEmail = 1;
  maxChars = 300;
  documentFolderType = "A";
  showNewFile = 0;

  hippaAvailable = false;
  hasHippaFile = false;
  isPracticePhy = false;

  fileDesTxt: any = "";
  hippaFileDesTxt: any = "";
  selectedEmail: any;
  practiceSlug: any = "";
  physicianType = "";
  physicianId: any = "";
  company_slug: string = "";
  stateName = "";
  cityName = "";
  stateCode = "";
  attachmentDocment: any = "";
  selectedNPI: any;
  selectedPhyName: any;
  selectedDocumentId: any;
  selectedPhysician: any = {};
  attachmentDoc: any = {};
  docSelected: any = {};
  statesArre: any = [];
  citiesList: any = [];
  physiciansList: any = [];
  practices: any = [];
  specialityArr: any = [];
  practicingCenterArr: any = [];
  documentsList: any = [];
  hippaDocumentsList: any = [];
  subSpecialtyArr: any = [];
  consultantArr: any = [];
  selectedDocsId: any = [];
  physiciansArr: any = [];
  patientName: any = "";
  fileToUploadHippa: File = null;
  fSizeHippa: any;
  showComfirmationModal = false;
  message = Message;

  constructor(
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    public userService: UserService,
    private doctorMailService: DoctorMailService
  ) {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.userOptionId =
      this.activatedRoute.snapshot.paramMap.get("userOptionId");
    this.findAllParticipatingCenters();
    this.findAllStates();
    this.fnFindAllDocuments(this.loggedInUserId);
    this.fnFindHippaDocs(this.loggedInUserId, 0);
    this.findUserDetails(this.loggedInUserId);
  }

  ngOnInit() {}

  findAllStates() {
    const finalCityData = {
      id: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findPhysicianCitiesApi,
        finalCityData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.statesArre = apiResponse.commandResult.data.statesList;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findAllParticipatingCenters() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.name_findPractices,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          if (apiResponse.commandResult.data.companiesList.length > 0) {
            this.practices = apiResponse.commandResult.data.companiesList;
            // console.log('practices', this.practices);
          } else {
            // alert("No Center of Excellence found!");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.sendDocComp.findAllParticipatingCenters
            );
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindAllDocuments(userId) {
    const finalData = {
      loggedInUserId: userId,
      company_slug: null,
    };

    // console.log(finalData);
    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findAllDocumentsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          if (res.commandResult.data.documentList.length > 0) {
            this.documentsList = res.commandResult.data.documentList;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindHippaDocs(userId, addToDocList) {
    const finalData = {
      loggedInUserId: userId,
      company_slug: null,
      doc_group_id: "HD",
      default_group_id: 17,
    };

    // console.log(finalData);

    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findDocsBySubGroupApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          if (res.commandResult.data.documentList.length > 0) {
            if (addToDocList == 1) {
              this.documentsList = res.commandResult.data.documentList;
            }
            this.hippaDocumentsList = res.commandResult.data.documentList;
          } else {
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findUserDetails(userId) {
    const finalData = {
      id: userId,
    };
    // console.log(finalData);

    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.name_findUserDetailsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.patientName = apiResponse.commandResult.data.userInfo.FullName;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findPhysicianByCompanySlug(companySlug) {
    this.companySlug = companySlug;
    const finalData = {
      company_slug: companySlug,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl04() +
          ApiHelper.name_findPhysiciansByCompanySlugApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          // console.log(apiResponse);
          if (apiResponse.commandResult.data.companiesList.length > 0) {
            this.consultantArr = this.fnChangePhysicianNameFormat(
              apiResponse.commandResult.data.companiesList
            );
            // console.log('Physicians', this.consultantArr);
          } else {
            // alert("No Specialties found!");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.expertPhysicianComp.findSpecialty
            );
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnChangePhysicianNameFormat(physicians) {
    let formattedNameArray: any = [];
    physicians.forEach((e) => {
      e.physician_name = this.userService.getUS_FromatName(e.physician_name);
      formattedNameArray.push(e);
    });
    return formattedNameArray.sort(
      this.userService.dynamicSort("physician_name")
    );
  }

  fnChangePhysicianNameFormat1(physicians) {
    let formattedNameArray: any = [];
    physicians.forEach((e) => {
      e.PhysicianName = this.userService.getUS_FromatName(e.PhysicianName);
      formattedNameArray.push(e);
    });
    return formattedNameArray.sort(
      this.userService.dynamicSort("PhysicianName")
    );
  }

  findCitiesByStateCode(stateCode) {
    this.cityName = "";
    this.physicianId = "";
    this.selectedPhysician = {};
    this.stateCode = stateCode;
    this.citiesList = [];
    const finalData = { stateCode };
    return this.http
      .post(
        this.appConfig.getLambdaUrl01() +
          ApiHelper.name_getCitiesByStateCodeApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          // console.log(apiResponse);
          if (apiResponse.commandResult.status == 1) {
            if (apiResponse.commandResult.data.cityList.length > 0) {
              this.citiesList = apiResponse.commandResult.data.cityList;
            } else {
              // alert("no cities found");
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.expertPhysicianOPinionComp
                  .findCitiesByStateCode
              );
            }
          } else {
            // alert("no cities found");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.expertPhysicianOPinionComp
                .findCitiesByStateCode
            );
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findPhysicianByStateCode(cityName) {
    this.physicianId = "";
    this.selectedPhysician = {};
    const finalDataPhysician = { cityName };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
          ApiHelper.name_getPhysiciansByCityCodeApi,
        finalDataPhysician,
        this.httpOptions
      )
      .subscribe(
        async (apiResponsePhysician: any) => {
          // console.log(apiResponsePhysician.commandResult.data.physiciansList);
          if (apiResponsePhysician.commandResult.status == 1) {
            // console.log(apiResponsePhysician.commandResult.data.physiciansList);
            if (
              apiResponsePhysician.commandResult.data.physiciansList.length > 0
            ) {
              this.physiciansList = this.fnChangePhysicianNameFormat1(
                apiResponsePhysician.commandResult.data.physiciansList
              );
            } else {
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.demographics.findPryPhysiciansByCity
              );
              // alert("No Physicians Found");
            }
          } else {
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.demographics.findPryPhysiciansByCity
            );
            //alert("No Physicians Found");
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findPhysicianById(physicianId) {
    // console.log(physicianId);

    const finalDataPhysician = { physicianId: physicianId };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.name_findPhysicianByIdApi,
        finalDataPhysician,
        this.httpOptions
      )
      .subscribe(
        (apiResponsePhysician: any) => {
          this.isPracticePhy = true;
          this.physicianType = "1";
          // console.log('apiResponsePhysician', apiResponsePhysician);
          this.stateName =
            apiResponsePhysician.commandResult.data.userInfo.PhysicianStateName;
          this.selectedEmail =
            apiResponsePhysician.commandResult.data.userInfo.PhysicianEmail;

          this.stateCode =
            apiResponsePhysician.commandResult.data.userInfo.PhysicianState;
          this.cityName =
            apiResponsePhysician.commandResult.data.userInfo.PhysicianCity;
          this.selectedPhysician =
            apiResponsePhysician.commandResult.data.userInfo;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  updatePhysicianInfo(physicianId) {
    this.physicianId = physicianId;
    this.selectedPhysician = this.physiciansList.find(
      (e) => e.RecordId == physicianId
    );
    // console.log(this.selectedPhysician);

    const finalData = {
      physicianNPI: this.selectedPhysician.PhysicianNipNo,
    };
    this.http
      .post(
        // this.appConfig.getPhpUrl() + ApiHelper.php_findPhysicianEmailApi,
        this.appConfig.getLambdaUrl06() + ApiHelper.findPhysicianEmail,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log(apiResponse);
          this.physicianType = "2";
          this.practiceSlug = ConstantHelper.COMPANY_SLUG;
          this.selectedEmail = apiResponse.commandResult.data.physicianEmail;

          this.hasPhysicianEmail =
            apiResponse.commandResult.data.hasPhysicianEmail;
          if (apiResponse.commandResult.data.hasPhysicianEmail == 1) {
            this.selectedPhysician.PhysicianEmail =
              apiResponse.commandResult.data.physicianEmail;
          } else {
            this.selectedPhysician.PhysicianEmail =
              apiResponse.commandResult.data.physicianEmail;
            this.showComfirmationModal = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  selectDocGroup(docSet) {
    if (docSet == "A") {
      this.fnFindAllDocuments(this.loggedInUserId);
    } else if (docSet == "U") {
      this.fnFindUploadedDocs(this.loggedInUserId);
    } else if (docSet == "DS") {
      this.fnFindDoctorAcknowledgments(this.loggedInUserId);
    } else {
      this.fnFindHippaDocs(this.loggedInUserId, 1);
    }
  }

  fnFindUploadedDocs(userId) {
    const finalData = {
      loggedInUserId: userId,
      company_slug: null,
      docType: ConstantHelper.DOC_TYPE_USER_UPLOADED,
    };
    // console.log(finalData);

    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
          ApiHelper.name_getUploadedDocumentsList,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          if (res.commandResult.data.documentList.length > 0) {
            this.documentsList = res.commandResult.data.documentList;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindDoctorAcknowledgments(userId) {
    const finalData = {
      loggedInUserId: userId,
      company_slug: null,
      docType: 7,
    };
    // console.log(finalData);

    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
          ApiHelper.name_getAcknowledgmentDocumentsList,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          if (res.commandResult.data.documentList.length > 0) {
            this.documentsList = res.commandResult.data.documentList;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }
  onFileSelectedHippa(event) {
    this.showNewFile = 1;
    this.attachmentDocment = "";
    this.attachmentDoc = {};

    this.hippaAvailable = false;

    if (this.fileUploadedHippa.nativeElement.value != "") {
      this.fileToUploadHippa = event.target.files.item(0) as File;
      this.fSizeHippa = Math.round(this.fileToUploadHippa.size / 1024);
    } else {
    }

    if (
      this.hasHippaFile &&
      this.fileUploadedHippa.nativeElement.value == "" &&
      this.selectedDocumentId == ""
    ) {
      this.hippaAvailable = true;
    }
  }

  setAttachment(DocumentId) {
    this.hippaAvailable = false;
    this.fileUploadedHippa.nativeElement.value = "";
    this.selectedDocumentId = DocumentId;
    this.showNewFile = 2;
    const docObj = this.hippaDocumentsList.find(
      (e) => e.RecordId == this.selectedDocumentId
    );
    this.attachmentDoc = JSON.stringify(docObj);
    // console.log(this.attachmentDoc);

    if (
      this.hasHippaFile &&
      this.fileUploadedHippa.nativeElement.value == "" &&
      this.selectedDocumentId == ""
    ) {
      this.hippaAvailable = true;
    }
  }

  getCheckedBoxValue() {
    const allCheck: any = document.getElementsByClassName("docChecks");
    for (let index = 0; index < allCheck.length; index++) {
      const element = allCheck[index];
      if (element.checked) {
        // console.log(element.value);
        this.selectedDocsId.push(element.value);
      }
    }
  }

  validateFile(name: String) {
    const ext = name.substring(name.lastIndexOf(".") + 1);
    if (
      ext.toLowerCase() == "png" ||
      ext.toLowerCase() == "jpg" ||
      ext.toLowerCase() == "jpeg" ||
      ext.toLowerCase() == "pdf"
    ) {
      return true;
    } else {
      return false;
    }
  }

  clearSelectedDocs() {
    this.selectedDocsId = [];
  }

  async onSubmit() {
    this.getCheckedBoxValue();
    if (!this.physicianId) {
      // tslint:disable-next-line: max-line-length
      // alert(
      //   "PLEASE CONFIRM:   By clicking ‘OK’: A physician must first be selected prior to emailing a request to share select files with an attached, permitting State HIPAA Form."
      // );
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.sendDocComp.onSubmit
      );
      return false;
    } else if (this.selectedDocsId.length <= 0) {
      // tslint:disable-next-line: max-line-length
      // alert(
      //   "PLEASE CONFIRM:   By clicking ‘OK’: To send an email first select the matching State HIPAA Form that lists the doctor’s name and at least one additional file to share."
      // );
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.sendDocComp.onSubmit1
      );
      return false;
    } else if (this.selectedDocsId.length > 5) {
      // alert("You can not select more then 5 docs");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.sendDocComp.onSubmit2
      );
      return false;
    } else if (
      this.fileUploadedHippa.nativeElement.value != "" &&
      !this.validateFile(this.fileToUploadHippa.name)
    ) {
      // alert("Selected HIPAA file format is not supported");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.sendDocComp.onSubmit3
      );
      return false;
    } else if (
      this.fileUploadedHippa.nativeElement.value != "" &&
      this.fSizeHippa > 61440
    ) {
      // alert("HIPAA file is too Big, please select a file less than 60mb");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.expertPhysicianComp.onsubmit2
      );
      return false;
    } else if (this.hasPhysicianEmail == 0) {
      // tslint:disable-next-line: max-line-length
      // alert(
      //   "PLEASE CONFIRM:   By clicking ‘OK’: You must first contact the doctor’s office by telephone to obtain an alternate email address when none is displayed from the NPI database.  First check the phone number with the doctor’s website. Enter an ‘Alternative email address’ to send."
      // );
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.expertPhysicianComp.onsubmit3
      );
      return false;
    } else {
      // tslint:disable-next-line: max-line-length
      // if (
      //   confirm(
      //     "PLEASE CONFIRM:   By clicking ‘OK’: You confirm selecting at least one health information file to share, and are clicking to send the email."
      //   )
      // )
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.expertPhysicianComp.onsubmit
      );
      {
        // tslint:disable-next-line: deprecation
        // tslint:disable-next-line: max-line-length
        this.doctorMailService
          .doctor2DoctorEmail06_Delete(
            this.loggedInUserId,
            this.practiceSlug,
            this.physicianType,
            this.stateCode,
            this.cityName,
            this.physicianId,
            this.fileDesTxt,
            11,
            this.userOptionId,
            this.fileToUploadHippa,
            this.selectedDocsId,
            this.hippaFileDesTxt,
            this.showNewFile,
            this.attachmentDoc,
            this.selectedEmail
          )
          .subscribe(
            async (data: any) => {
              if (data !== undefined) {
                // alert("Email successfully sent. ");
                const alertStatus = await this.sweetAlertHelper.alertPopUp(
                  this.message.alertMessages.sendDocComp.onSubmit5
                );
                if (alertStatus)
                  this.router.navigate(["/dashboard/" + this.loggedInUserId]);
              }
            },
            (error) => {
              // console.log(error);
            }
          );
      }
    }
  }

  async goToDashboard() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM:   By clicking ‘OK’ You will be returned to the ‘Home Page’."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.ushfComp.onReset
    );
    if (alertStatus) {
      this.router.navigate(["/dashboard/" + this.loggedInUserId]);
    }
  }

  async onReset() {
    // if (confirm('PLEASE CONFIRM:   By clicking ‘OK’: You are refreshing the screen that will clear already filled in Information.'))

    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.sendDocComp.onReset
    );
    {
      window.location.reload();
      // this.router.navigate(['/dashboard/' + this.loggedInUserId + '/1']);
    }
  }
}

import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { from } from "rxjs";
import { DatePipe } from "@angular/common";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";
import { SignaturePad } from "angular2-signaturepad";
import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";
import { UserService } from "./../../_services/user.service";
import * as moment from "moment";
import { SweetAlertHelper } from "../../_helpers/sweet.alert.helper";
import { Message } from "../../_locale/messages";
import { navigateToRoute } from "./../../_helpers/emailDoctorExpertOpinionRouteBack.helper";

@Component({
  selector: "app-az",
  templateUrl: "./az.component.html",
  styleUrls: ["./az.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class AzComponent implements OnInit {
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  hippaJson: any = {};
  loggedInUserId: string;
  userSignatures: any = [];
  userSignatureSelected = false;
  userFullSignature = "";
  userInitialSignature = "";
  userChoosedSignature = "";
  userFullSignatureExit = false;
  userInitialSignatureExis = false;
  showPdfModal = false;
  signatureType: any;
  showLoader = false ;
  ssnPattern = "^[0-9]{3}-?[0-9]{2}-?[0-9]{4}$";

  resultJsonObj: any = {};
  clean = "0";
  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;
  @ViewChild("canvasArea", { static: true }) canvasArea: ElementRef;
  canvasElm: any;
  public signaturePadOptions: Object = {
    minWidth: 1,
    canvasWidth:
      window.innerWidth < 1024 ? window.innerWidth : window.innerWidth / 2,
    canvasHeight: 450,
  };

  currentDate = new Date();
  dateFormat = "MM-dd-yyyy";

  userWitnessSignatureSelected = false;
  userWitnessChoosedSignature = "";

  signatureFroWhom: any;
  isEmail: any;
  isPracticePhy: any;
  physicianId: any;
  requestId: any;
  tempId: any;
  userOptionId: any;
  emailLogId: any;
  message = Message;
  sendDocRoute = sessionStorage.getItem("sendDocRoute");

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public sweetAlertHelper: SweetAlertHelper,
    public userService: UserService
  ) {
    this.loggedInUserId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.activatedRoute.snapshot.paramMap.get("loggedInUserId");

    this.isEmail = this.activatedRoute.snapshot.paramMap.get("isEmail")
      ? this.activatedRoute.snapshot.paramMap.get("isEmail")
      : 0;
    this.isPracticePhy =
      this.activatedRoute.snapshot.paramMap.get("isPracticePhy");
    this.physicianId = this.activatedRoute.snapshot.paramMap.get("physicianId")
      ? this.activatedRoute.snapshot.paramMap.get("physicianId")
      : 0;
    this.requestId = this.activatedRoute.snapshot.paramMap.get("requestId")
      ? this.activatedRoute.snapshot.paramMap.get("requestId")
      : 0;

    this.tempId = this.activatedRoute.snapshot.paramMap.get("tempId")
      ? this.activatedRoute.snapshot.paramMap.get("tempId")
      : 0;
    this.userOptionId = this.activatedRoute.snapshot.paramMap.get(
      "userOptionId"
    )
      ? this.activatedRoute.snapshot.paramMap.get("userOptionId")
      : 0;
    this.emailLogId = this.activatedRoute.snapshot.paramMap.get("emailLogId")
      ? this.activatedRoute.snapshot.paramMap.get("emailLogId")
      : 0;

    this.hippaJson.healthcare_provider = this.userService.getUS_FromatName(
      localStorage.getItem("providerName")
    );
    this.hippaJson.healthcare_provider_email =
      localStorage.getItem("providerEmail");
    this.hippaJson.healthcare_provider_npi = localStorage.getItem(
      "providerPhysicianNipNo"
    );
    this.hippaJson.street_address = localStorage.getItem(
      "providerOfficeAddress"
    );
    this.hippaJson.PhysicianCity = localStorage.getItem("selectedCityName");
    // console.log(this.hippaJson.PhysicianCity);
    this.hippaJson.PhysicianState = localStorage.getItem("selectedHippaState");
    // console.log(this.hippaJson.PhysicianState);
    this.hippaJson.zip_code = localStorage.getItem("providerZipCode");
    // console.log(this.hippaJson.zip_code);

    this.hippaJson.city_zip = localStorage.getItem("providerCity")
      ? localStorage.getItem("providerCity")
      : "" + ", " + localStorage.getItem("PhysicianStateName")
      ? localStorage.getItem("PhysicianStateName")
      : "" + ", " + localStorage.getItem("providerZipCode")
      ? localStorage.getItem("providerZipCode")
      : "";

    this.hippaJson.providerCity = localStorage.getItem("providerCity");
    this.hippaJson.provider_Phone = localStorage.getItem("providerPhone");
    this.hippaJson.providerPhysicianNipNo = localStorage.getItem(
      "providerPhysicianNipNo"
    );

    this.hippaJson.patient_name = this.userService.getUS_FromatName(
      localStorage.getItem("loggedInUserName")
    );
    this.fnFindSignature(this.loggedInUserId);
    this.fnFindDemographic(this.loggedInUserId);
    this.findUserDetails(this.loggedInUserId);
    this.hippaJson.patient_signature_date = this.currentDate;
    this.hippaJson.witness_signature_date = this.currentDate;
    this.hippaJson.pre_to = this.currentDate;
    this.hippaJson.post_to = this.currentDate;
  }

  ngOnInit() {
    // console.log(localStorage);
    this.hippaJson.checkbox_01 = false;
    this.hippaJson.checkbox_02 = false;
    this.hippaJson.checkbox_03 = false;
    this.hippaJson.checkbox_04 = false;
    this.hippaJson.checkbox_05 = false;
    this.hippaJson.checkbox_06 = false;
    this.hippaJson.checkbox_07 = false;
    this.hippaJson.patient_signature = "";
    this.hippaJson.witness_signature = "";
    this.hippaJson.city_zip =
      this.hippaJson.PhysicianCity +
      " ," +
      this.hippaJson.PhysicianState +
      "," +
      this.hippaJson.zip_code;
  }

 
  findUserDetails(userId) {
      const finalData = {
        company_slug: null,
        id: userId,
      };
      return this.http
        .post(
          this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse: any) => {
            if (apiResponse.commandResult.status == 1) {
              this.hippaJson.patient_name =
                apiResponse.commandResult.data.userInfo.LastName +
                ", " +
                apiResponse.commandResult.data.userInfo.FirstName;
              this.hippaJson.dob=apiResponse.commandResult.data.userInfo.DOB;
                this.hippaJson.patient_phone =
                apiResponse.commandResult.data.userInfo.Mobile;
              this.hippaJson.patient_email =
                apiResponse.commandResult.data.userInfo.Email;
  
              
            }
           
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  
  fnFindSignature(user_id) {
    const reqData = {
      user_id,
    };
    return this.http
      .post(
        // this.appConfig.getPhpUrl() + ApiHelper.php_findUserSignatureApi,
        this.appConfig.getLambdaUrl06() + ApiHelper.findUserSignature,
        reqData,
        this.httpOptions
      )
      .subscribe(
        (response: any) => {
          if (response.commandResult.success == 1) {
            this.userSignatures = response.commandResult.data.userSignatures;
            this.userFullSignature =
              response.commandResult.data.userSignatures.full_signature_url;
            this.userInitialSignature =
              response.commandResult.data.userSignatures.initial_signature_url;

            if (response.commandResult.data.userSignatures.full_signature_url) {
              this.userFullSignatureExit = true;
            }

            if (
              response.commandResult.data.userSignatures.initial_signature_url
            ) {
              this.userInitialSignatureExis = true;
            }
          } else {
          }

          // console.log(this.userSignatures);
        },
        (err) => {}
      );
  }

  async onSubmit() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM:   By clicking ‘OK’:The information you have entered will be used to electronically generate a completed State HIPAA Form."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.onSubmit
    );
    if (alertStatus) {
      const reqData = {
        healthcare_provider: this.hippaJson.healthcare_provider,
        healthcare_provider_email: this.hippaJson.healthcare_provider_email,
        healthcare_provider_npi: this.hippaJson.healthcare_provider_npi,
        patient_name: this.hippaJson.patient_name,
        patient_phone:this.hippaJson.patient_phone,
        patient_address:this.hippaJson.patient_address,
        patient_city_zip:this.hippaJson.patient_city_zip,
        patient_email:this.hippaJson.patient_email,
        social_security_no: this.hippaJson.social_security_no,
        dob: this.hippaJson.dob,        
        street_address: this.hippaJson.street_address,
        city_zip: this.hippaJson.city_zip,
        provider_Phone:this.hippaJson.provider_Phone,   
        other:this.hippaJson.other,
        other_purpose:this.hippaJson.other_purpose,
        other_verify:this.hippaJson.other_verify,        
        purposes: this.hippaJson.purposes,
        representative_name: this.hippaJson.representative_name,
        representative_capacity: this.hippaJson.representative_capacity,
        representative_street_address:
          this.hippaJson.representative_street_address,
        representative_city_zip: this.hippaJson.representative_city_zip,
        patient_signature: this.userChoosedSignature,
        patient_signature_date: moment(
          new Date(this.hippaJson.patient_signature_date.toString())
        ).format("MM-DD-YYYY"),
        name_relation: this.hippaJson.name_relation,
        witness_signature: this.userWitnessChoosedSignature,
        witness_signature_date: moment(
          new Date(this.hippaJson.witness_signature_date.toString())
        ).format("MM-DD-YYYY"),
        pre_to: moment(new Date(this.hippaJson.pre_to.toString())).format(
          "MM-DD-YYYY"
        ),
        post_to: moment(new Date(this.hippaJson.post_to.toString())).format(
          "MM-DD-YYYY"
        ),
        checkbox_01: this.hippaJson.checkbox_01,
        checkbox_02: this.hippaJson.checkbox_02,
        checkbox_03: this.hippaJson.checkbox_03,
        checkbox_04: this.hippaJson.checkbox_04,
        checkbox_05: this.hippaJson.checkbox_05,
        checkbox_06: this.hippaJson.checkbox_06,
        checkbox_07: this.hippaJson.checkbox_07,
        checkbox_08: this.hippaJson.checkbox_08,
        checkbox_09: this.hippaJson.checkbox_09,
        checkbox_10: this.hippaJson.checkbox_10,
        checkbox_11: this.hippaJson.checkbox_11,
        checkbox_12: this.hippaJson.checkbox_12,
        checkbox_13: this.hippaJson.checkbox_13,
        checkbox_14: this.hippaJson.checkbox_14,
        checkbox_15: this.hippaJson.checkbox_15,
        checkbox_16: this.hippaJson.checkbox_16,
        checkbox_17: this.hippaJson.checkbox_17,
        checkbox_18: this.hippaJson.checkbox_18,
        checkbox_19: this.hippaJson.checkbox_19,
        checkbox_20: this.hippaJson.checkbox_20,
        checkbox_21: this.hippaJson.checkbox_21,
        checkbox_22: this.hippaJson.checkbox_22,
        checkbox_23: this.hippaJson.checkbox_23,
        checkbox_24: this.hippaJson.checkbox_24,
        checkbox_25: this.hippaJson.checkbox_25,
        checkbox_26: this.hippaJson.checkbox_26,
        checkbox_27: this.hippaJson.checkbox_27,
        checkbox_28: this.hippaJson.checkbox_28,
        checkbox_29: this.hippaJson.checkbox_29,
        checkbox_30: this.hippaJson.checkbox_30,
        checkbox_31: this.hippaJson.checkbox_31,
        checkbox_32: this.hippaJson.checkbox_32,
        checkbox_33: this.hippaJson.checkbox_33,
        checkbox_34: this.hippaJson.checkbox_34,
        checkbox_35: this.hippaJson.checkbox_35,
        checkbox_36: this.hippaJson.checkbox_36,
        checkbox_37: this.hippaJson.checkbox_37,
        checkbox_38: this.hippaJson.checkbox_38,
        start_date:this.hippaJson.start_date,
        end_date:this.hippaJson.end_date,       
        loggedInUserId: this.loggedInUserId,
        isEmail: this.isEmail,
        requestId: this.requestId,
        phyAltEmail: sessionStorage.getItem('phyAltEmail')
      };
      // console.log(reqData);
      this.showLoader = true ;

      return this.http
        .post(
          this.appConfig.getLambdaUrl06() + ApiHelper.generateHipaaAZ,
          reqData,
          this.httpOptions
        )
        .subscribe(
          (response: any) => {
            if (this.isEmail == 1) {
              this.router.navigate([
                "/send-doc/" +
                  this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
                  "/1/" +
                  this.isPracticePhy +
                  "/" +
                  this.physicianId +
                  "/" +
                  response.commandResult.message,
              ]);
            } else if (this.isEmail == 3) {
              this.router.navigate([
                "/expert-physician-opinion/" +
                  this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
                  "/" +
                  this.userOptionId +
                  "/1/" +
                  this.tempId +
                  "/" +
                  response.commandResult.message,
              ]);
            } else if (this.isEmail == 4) {
              this.router.navigate([
                "/resend-docs/" +
                  this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
                  "/" +
                  this.emailLogId +
                  "/1/" +
                  response.commandResult.message +
                  "/" +
                  this.isPracticePhy +
                  "/" +
                  this.physicianId,
              ]);
            } else {
              if (window.open(response.commandResult.data, "_blank")) {
                this.router.navigate([
                  "/dashboard/" +
                    this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
                ]);
              }
            }
            this.showLoader = false ;

          },
          (err) => {}
        );
    }
  }

  async onReset() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Click ‘OK’ to confirm returning to the ‘Home Page’."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.ushfComp.onReset
    );
    if (alertStatus) {
      this.router.navigate([
        "/dashboard/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    }
  }

  async goBack() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to the USHF Page?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.goBack
    );
    if (alertStatus) {
      if(this.sendDocRoute){
        const loggedInUserId =
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
        const route = navigateToRoute(
          this.isEmail,
          loggedInUserId,
          this.isPracticePhy,
          this.physicianId,
          this.userOptionId,
          this.tempId,
          this.emailLogId
        );
        console.log("route ", route);
        this.router.navigate([route]);
        sessionStorage.removeItem("sendDocRoute");
      }else{
        this.router.navigate([
          '/ushf/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId')
      ]);
      }
    }
  }

  closePdfModal() {
    this.showPdfModal = false;
    this.hippaJson.patient_signature = "";
  }

  openSignatureCanvas(selectedValue, signatureFroWhom) {
    this.signatureFroWhom = signatureFroWhom;
    // console.log(selectedValue);
    this.signatureType = selectedValue;
    if (
      selectedValue == "AFS" ||
      selectedValue == "AIS" ||
      selectedValue == "AWTSG"
    ) {
      this.showPdfModal = true;
    }
    if (selectedValue == "FS" || selectedValue == "IS") {
      this.userSignatureSelected = true;
      if (selectedValue == "FS") {
        this.userChoosedSignature = this.userFullSignature;
      }
      if (selectedValue == "IS") {
        this.userChoosedSignature = this.userInitialSignature;
      }
    }
  }

  ngAfterViewInit() {
    // this.signaturePad is now available
    this.signaturePad.set("minWidth", 1); // set szimek/signature_pad options at runtime
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    // console.log(this.signaturePad.toDataURL());
  }

  async saveCanvas() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: This will replace your existing signature. Do you wish to change?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.saveCanvas
    );
    if (alertStatus) {
      const reqData = {
        loggedInUserId: this.loggedInUserId,
        signatureBase64: this.signaturePad.toDataURL(),
        signatureType: this.signatureType,
      };
      return this.http
        .post(
          this.appConfig.getLambdaUrl05() +
            ApiHelper.name_saveSignatureImageApi,
          reqData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse: any) => {
            if (apiResponse.commandResult.status == 1) {
              this.userSignatureSelected = true;
              this.userChoosedSignature =
                apiResponse.commandResult.data.fileInfo.signatureUrl;
              // console.log(this.userChoosedSignature);
              this.fnFindSignature(this.loggedInUserId);
              this.showPdfModal = false;
              // this.hippaJson.patient_signature = '';
            }
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  async clearCanvas() {
    // if (
    //   confirm("PLEASE CONFIRM: Are you sure you want to clear you signature.")
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.clearCanvas
    );
    if (alertStatus) {
      this.signaturePad.clear();
    }
  }

  drawStart() {}

  fnFindDemographic(user_id) {
    const finalData = {
      id: user_id,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
          ApiHelper.name_findUserDemographicGeneralApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (response: any) => {
          // this.hippaJson.dob = new Date(
          //   response.commandResult.data.userInfo.form1DOB
          // );

          // this.hippaJson.city_zip = response.commandResult.data.userInfo.form1PrimaryPhysicianCity + ', ' + response.commandResult.data.userInfo.form1State + ', ' + response.commandResult.data.userInfo.form1ZIP;

          this.hippaJson.patient_address = response.commandResult.data.userInfo.form1StreetName;
          
          this.hippaJson.patient_city =
            response.commandResult.data.userInfo.form1City;
          this.hippaJson.patient_zip =
            response.commandResult.data.userInfo.form1ZIP;
          this.hippaJson.patient_state =
            response.commandResult.data.userInfo.form1State;
          this.hippaJson.patient_city_zip= this.hippaJson.patient_city+','+ this.hippaJson.patient_state+','+ this.hippaJson.patient_zip;
        },

        (err) => {
          // console.log(err);
        }
      );
  }

  autoFormatSSN() {
    // alert('Amit');
    let val = this.hippaJson.social_security_no.replace(/\D/g, "");
    // val = val.replace(/^(\d{3})/, "$1-");
    // val = val.replace(/-(\d{2})/, "-$1-");
    // val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
    this.hippaJson.social_security_no = val;
  }

  saveParentCanvas(signFor) {
    const reqData = {
      loggedInUserId: this.loggedInUserId,
      signatureBase64: this.signaturePad.toDataURL(),
      signatureType: this.signatureType,
      signFor,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl05() +
          ApiHelper.name_saveParentSignatureImageApi,
        reqData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status == 1) {
            if (signFor == "WTSG") {
              this.userWitnessSignatureSelected = true;
              this.userWitnessChoosedSignature =
                apiResponse.commandResult.data.fileInfo.signatureUrl;
            }
            this.showPdfModal = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }
}
